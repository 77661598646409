type ElfCodeMapping = {
  [key: string]: {
    subdivision_label: string | null;
    subdivision_code: string | null;
    legal_forms: {
      label: string;
      code: string;
    }[];
  }[];
};

export const elfCodeMapping: ElfCodeMapping = {
  AD: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Limited Company',
          code: '8888',
        },
        {
          label: 'Public Limited Company',
          code: '8888',
        },
        {
          label: 'Collective Society',
          code: '8888',
        },
      ],
    },
  ],
  AI: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [],
    },
  ],
  MA: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [],
    },
  ],
  AE: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Joint Liability Company',
          code: 'F8VX',
        },
        {
          label: 'Public Joint Stock Company',
          code: 'JGNU',
        },
        {
          label: 'Simple Commandite Company',
          code: 'LOL8',
        },
        {
          label: 'Sole Proprietor',
          code: 'MV4S',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'RWVW',
        },
        {
          label: 'Free Zone Company (FZCO)',
          code: 'FZCO',
        },
        {
          label: 'Private Joint Stock Company',
          code: 'WR09',
        },
      ],
    },
  ],
  AG: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'International Business Corporation',
          code: 'CDOV',
        },
      ],
    },
  ],
  AR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sociedad Anonima (SA)',
          code: 'F0A6',
        },
        {
          label: 'Sociedad Cooperativa (S Coop)',
          code: 'HZ6C',
        },
        {
          label: 'Sociedad Anonima Unipersonal (SAU)',
          code: 'WUAZ',
        },
      ],
    },
  ],
  AT: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sonstiger Rechtsträger',
          code: '1NOX',
        },
        {
          label: 'Privatstiftung',
          code: '5WWO',
        },
        {
          label: 'Europäische Genossenschaft (SCE)',
          code: '69H1',
        },
        {
          label: 'Genossenschaft',
          code: '8XDW',
        },
        {
          label: 'Kommandit-Erwerbsgesellschaft (KEG)',
          code: 'AAL7',
        },
        {
          label: 'Gesellschaft mit beschränkter Haftung (GmbH)',
          code: 'AXSB',
        },
        {
          label: 'Versicherungsverein auf Gegenseitigkeit',
          code: 'CAQ1',
        },
        {
          label: 'Europäische wirtschaftliche Interessenvereinigung',
          code: 'DM88',
        },
        {
          label: 'Verein',
          code: 'DX6Z',
        },
        {
          label: 'Gemeinnützige Stiftung',
          code: 'E9OX',
        },
        {
          label: 'Einzelunternehmer (EU)',
          code: 'ECWU',
        },
        {
          label: 'Aktiengesellschaft (AG)',
          code: 'EQOV',
        },
        {
          label: 'Einzelkaufmann',
          code: 'G3R6',
        },
        {
          label: 'Offene Handelsgesellschaft (OHG)',
          code: 'GVPD',
        },
        {
          label: 'Körperschaft öffentlichen Rechts',
          code: 'JJYT',
        },
        {
          label: 'Sparkasse',
          code: 'JQOI',
        },
        {
          label: 'Offene Gesellschaft (OG)',
          code: 'JTAV',
        },
        {
          label: 'Offene Erwerbsgesellschaft (OEG)',
          code: 'JUHG',
        },
        {
          label: 'Erwerbs- und Wirtschaftsgenossenschaft',
          code: 'NIJH',
        },
        {
          label: 'Stille Gesellschaft (stG)',
          code: 'NNLI',
        },
        {
          label: 'Europäische Gesellschaft (SE)',
          code: 'O65B',
        },
        {
          label: 'Kommanditgesellschaft (KG)',
          code: 'ONF1',
        },
        {
          label: 'Gesellschaft des bürgerlichen Rechts (GesbR)',
          code: 'UI81',
        },
      ],
    },
  ],
  AU: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Co-operative (Coop)',
          code: '6W6X',
        },
        {
          label: 'Trust',
          code: '7TPC',
        },
        {
          label: 'Fund',
          code: 'ADXG',
        },
        {
          label: 'Incorporated association (inc)',
          code: 'BC38',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'J4JC',
        },
        {
          label: 'Public Company limited by guarantee (Ltd)',
          code: 'LZFR',
        },
        {
          label: 'No Liability Company (NL)',
          code: 'PQHL',
        },
        {
          label: 'Unlimited Proprietary Company (PTY)',
          code: 'Q82Q',
        },
        {
          label: 'Public Company limited by shares (Ltd)',
          code: 'R4KK',
        },
        {
          label: 'Limited Proprietary Company (Pty Ltd)',
          code: 'TXVC',
        },
        {
          label: 'Indigenous Corporation (RNTBC)',
          code: 'XHCV',
        },
      ],
    },
  ],
  AW: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Stichting',
          code: 'CRL9',
        },
        {
          label: 'Vennootschap onder firma (vof)',
          code: 'CYW2',
        },
        {
          label: 'Commanditaire vennootschap',
          code: 'GAJN',
        },
        {
          label: 'Besloten vennootschap (bv)',
          code: 'M27U',
        },
        {
          label: 'Vereniging',
          code: 'OLAI',
        },
        {
          label: 'Naamloze vennootschap (nv)',
          code: 'P65N',
        },
        {
          label: 'Cooperatieve vereniging',
          code: 'PCRJ',
        },
        {
          label: 'Eenmanszaak',
          code: 'PT6C',
        },
        {
          label: 'Aruba vrijgestelde vennootschap (avv)',
          code: 'REUP',
        },
        {
          label: 'Vennootschap buitenlands recht',
          code: 'T5RL',
        },
        {
          label: 'Maatschap',
          code: 'UV8T',
        },
        {
          label: 'Vennootschap met beperkte aansprakelijkheid (vba)',
          code: 'V905',
        },
        {
          label: 'Commanditaire vennootschap',
          code: 'YCV2',
        },
      ],
    },
  ],
  AZ: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Törəmə təsərrüfat cəmiyyəti',
          code: '52TH',
        },
        {
          label: 'Məhdud məsuliyyətli cəmiyyəti (MMC)',
          code: '6JHZ',
        },
        {
          label: 'Kooperativlər',
          code: 'DWEA',
        },
        {
          label: 'Əlavə məsuliyyətli cəmiyyət',
          code: 'HKL3',
        },
        {
          label: 'Açıq səhmdar cəmiyyəti (ASC)',
          code: 'K81Q',
        },
        {
          label: 'Qapalı səhmdar cəmiyyəti (QSC)',
          code: 'M4LU',
        },
        {
          label: 'Tam ortaqlıq',
          code: 'MEJT',
        },
        {
          label: 'Hüquqi şəxslərin ittifaqları',
          code: 'PITR',
        },
        {
          label: 'Asılı təsərrüfat cəmiyyəti',
          code: 'SLXJ',
        },
        {
          label: 'Komandit ortaqlıq',
          code: 'TD56',
        },
        {
          label: 'İctimai birliklər',
          code: 'U0SP',
        },
        {
          label: 'Fondlar',
          code: 'ZD74',
        },
      ],
    },
  ],
  BB: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Society with restricted liability (SRL)',
          code: '2T7B',
        },
        {
          label: 'Sole proprietorship',
          code: '5IL6',
        },
        {
          label: 'Partnership',
          code: '7FCS',
        },
        {
          label: 'Segregated cell company',
          code: 'EKJG',
        },
        {
          label: 'Company (corp)',
          code: 'HTBY',
        },
        {
          label: 'Non-profit company',
          code: 'KYFD',
        },
        {
          label: 'International business company',
          code: 'Q9Y1',
        },
        {
          label: 'Association',
          code: 'T4KB',
        },
        {
          label: 'Public company (corp)',
          code: 'TEPY',
        },
        {
          label: 'Limited partnership',
          code: 'X2X1',
        },
      ],
    },
  ],
  BE: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label:
            'Commanditaire vennootschap op aandelen met een sociaal oogmerk (CVA SO)',
          code: '1TX8',
        },
        {
          label:
            'Kommanditgesellschaft auf Aktien mit sozialer Zielsetzung (KGaAmsZ)',
          code: '1TX8',
        },
        {
          label: 'Coöperatieve vennootschap (CV)',
          code: '28FE',
        },
        {
          label: 'Société coopérative (SC)',
          code: '28FE',
        },
        {
          label: 'Genossenschaft (Gen)',
          code: '28FE',
        },
        {
          label:
            'Coöperatieve vennootschap met beperkte aansprakelijkheid met een sociaal oogmerk (CVBA SO)',
          code: '2QSA',
        },
        {
          label:
            'Genossenschaft mit beschränkter Haftung mit sozialer Zielsetzung (GbHsZ)',
          code: '2QSA',
        },
        {
          label: 'Europese vennootschap (SE)',
          code: '36KV',
        },
        {
          label: 'Société européenne (SE)',
          code: '36KV',
        },
        {
          label: 'Europäische Gesellschaft (SE)',
          code: '36KV',
        },
        {
          label: 'Onderneming-natuurlijk persoon (ONP)',
          code: '3N94',
        },
        {
          label: 'Entreprise personne physique (EPP)',
          code: '3N94',
        },
        {
          label: 'Unternehmen natürliche Person (UNP)',
          code: '3N94',
        },
        {
          label: 'Besloten Vennootschap (BV)',
          code: '3W7E',
        },
        {
          label: 'Société à responsabilité limitée (SRL)',
          code: '3W7E',
        },
        {
          label: 'Gesellschaft mit beschränkter Haftung (GmbH)',
          code: '3W7E',
        },
        {
          label:
            'Coöperatieve vennootschap met onbeperkte aansprakelijkheid met een sociaal oogmerk (CVOA SO)',
          code: '4E5P',
        },
        {
          label:
            'Genossenschaft mit unbeschränkter Haftung mit sozialer Zielsetzung (GuHsZ)',
          code: '4E5P',
        },
        {
          label: 'Europees economisch samenwerkingsverband (EESV)',
          code: '7SJP',
        },
        {
          label: "Groupement européen d'intérêt économique (GEIE)",
          code: '7SJP',
        },
        {
          label: 'Europäische wirtschaftliche Interessenvereinigung (EWIV)',
          code: '7SJP',
        },
        {
          label:
            'Coöperatieve vennootschap met beperkte aansprakelijkheid (CVBA)',
          code: '8E2A',
        },
        {
          label: 'Société coopérative à responsabilité limitée (SCRL)',
          code: '8E2A',
        },
        {
          label: 'Genossenschaft mit beschränkter Haftung (GenmbH)',
          code: '8E2A',
        },
        {
          label: 'Instelling zonder winstoogmerk (IZW)',
          code: '8YLB',
        },
        {
          label: 'Institution sans but lucratif (ISBL)',
          code: '8YLB',
        },
        {
          label: 'Einrichtung ohne Gewinnerzielungsabsicht (EoG)',
          code: '8YLB',
        },
        {
          label: 'Vennootschap onder firma met een sociaal oogmerk (VOF SO)',
          code: 'B910',
        },
        {
          label: 'Offene Handelsgesellschaft mit sozialer Zielsetzung (OHGmsZ)',
          code: 'B910',
        },
        {
          label:
            'Eenpersoons besloten vennootschap met beperkte aansprakelijkheid (EBVBA)',
          code: 'C609',
        },
        {
          label:
            'Société privée à responsabilité limitée unipersonnelle (SPRLU)',
          code: 'C609',
        },
        {
          label:
            'Privatgesellschaft mit beschränkter Haftung mit einem Alleingesellschafter (PGmbHmA)',
          code: 'C609',
        },
        {
          label:
            'Vennootschap of vereniging zonder rechtspersoonlijkheid (VVZRL)',
          code: 'CFH5',
        },
        {
          label: 'Société ou association sans personnalité juridique (SASPJ)',
          code: 'CFH5',
        },
        {
          label:
            'Gesellschaften oder Vereinigungen ohne Rechtspersönlichkeit (GVoRP)',
          code: 'CFH5',
        },
        {
          label: 'Vereniging zonder winstoogmerk van publiek recht (VZW PR)',
          code: 'EDMK',
        },
        {
          label: 'Association sans but lucratif de droit public (ASBL DPU)',
          code: 'EDMK',
        },
        {
          label:
            'Öffentlich-rechtliche Vereinigung ohne Gewinnerzielungsabsicht (ÖrVohGza)',
          code: 'EDMK',
        },
        {
          label:
            'Coöperatieve vennootschap met onbeperkte aansprakelijkheid (CVOA CD)',
          code: 'EXY8',
        },
        {
          label: 'Coopérative à responsabilité illimitée (SCRI CP)',
          code: 'EXY8',
        },
        {
          label:
            'Öffentlich-rechtliche Genossenschaft mit unbeschränkter Haftung (GmuHGB)',
          code: 'EXY8',
        },
        {
          label: 'Economisch samenwerkingsverband (ESV)',
          code: 'J5OU',
        },
        {
          label: "Groupement d'intérêt économique (GIE)",
          code: 'J5OU',
        },
        {
          label: 'Wirtschaftliche Interessenvereinigung (WIV)',
          code: 'J5OU',
        },
        {
          label: 'Coöperatieve vennootschap van publiek recht (CV PR)',
          code: 'JAM3',
        },
        {
          label: 'Société coopérative de droit public (SC DPU)',
          code: 'JAM3',
        },
        {
          label: 'Öffentlich-rechtliche Genossenschaft (ÖrGen)',
          code: 'JAM3',
        },
        {
          label:
            'Besloten vennootschap met beperkte aansprakelijkheid met een sociaal oogmerk (BVBA SO)',
          code: 'JNAD',
        },
        {
          label:
            'Privatgesellschaft mit beschränkter Haftung mit sozialer Zielsetzung (PmbHsZ)',
          code: 'JNAD',
        },
        {
          label:
            'Burgerlijke vennootschap onder vorm van commanditaire vennootschap op aandelen (BV CVA)',
          code: 'KLBO',
        },
        {
          label:
            'Société civile sous forme de société en commandite par actions (SC SCA)',
          code: 'KLBO',
        },
        {
          label:
            'Zivilrechtliche Gesellschaft in der Rechtsform einer Kommanditgesellschaft auf Aktien (ZRG KGaA)',
          code: 'KLBO',
        },
        {
          label: 'Maatschap (MS)',
          code: 'KM6O',
        },
        {
          label: 'Société de droit commun (SDC)',
          code: 'KM6O',
        },
        {
          label: 'Gesellschaft des allgemeinen Rechts (GaR)',
          code: 'KM6O',
        },
        {
          label: 'Besloten Vennootschap van publiek recht (BV PR)',
          code: 'L05H',
        },
        {
          label: 'Société à responsabilité limitée de droit public (SRL DPU)',
          code: 'L05H',
        },
        {
          label:
            'Öffentlich-rechtliche Gesellschaft mit beschränkter Haftung (ÖrGmbH)',
          code: 'L05H',
        },
        {
          label:
            'Coöperatieve vennootschap met onbeperkte aansprakelijkheid (CVOA)',
          code: 'LWHF',
        },
        {
          label: 'Société coopérative à responsabilité illimitée (SCRI)',
          code: 'LWHF',
        },
        {
          label: 'Genossenschaft mit unbeschränkter Haftung (GenmubH)',
          code: 'LWHF',
        },
        {
          label: 'Gewone commanditaire vennootschap (CommV)',
          code: 'LWV6',
        },
        {
          label: 'Société en commandite simple (SCS)',
          code: 'LWV6',
        },
        {
          label: 'Einfache Kommanditgesellschaft (EKG)',
          code: 'LWV6',
        },
        {
          label: 'Commanditaire vennootschap (CommV)',
          code: 'MQH3',
        },
        {
          label: 'Société en commandite (SComm)',
          code: 'MQH3',
        },
        {
          label: 'Kommanditgesellschaft (KommG)',
          code: 'MQH3',
        },
        {
          label: 'Besloten vennootschap met beperkte aansprakelijkheid (BVBA)',
          code: 'N5NT',
        },
        {
          label: 'Société privée à responsabilité limitée (SPRL)',
          code: 'N5NT',
        },
        {
          label: 'Privatgesellschaft mit beschränkter Haftung (PGmbH)',
          code: 'N5NT',
        },
        {
          label:
            'Economisch samenwerkingsverband met een sociaal oogmerk (ESV SO)',
          code: 'O59C',
        },
        {
          label:
            'Wirtschaftliche Interessenvereinigung mit sozialer Zielsetzung (WIVmsZ)',
          code: 'O59C',
        },
        {
          label:
            'Burgerlijke vennootschap onder vorm van coöperatieve vennootschap met beperkte aansprakelijkheid (BV CVBA)',
          code: 'O5HQ',
        },
        {
          label:
            'Société civile sous forme de société coopérative à responsabilité limitée (SC SCRL)',
          code: 'O5HQ',
        },
        {
          label:
            'Zivilrechtliche Gesellschaft in der Rechtsform einer Genossenschaft mit beschränkter Haftung (ZvGGbH)',
          code: 'O5HQ',
        },
        {
          label:
            'Eenpersoons besloten vennootschap met beperkte aansprakelijkheid met een sociaal oogmerk (EBVBA SO)',
          code: 'PVT3',
        },
        {
          label:
            'Privatgesellschaft mit beschränkter Haftung mit sozialer Zielsetzung mit einem Alleingesellschafter (PgbHsZ)',
          code: 'PVT3',
        },
        {
          label: 'Openbare instelling (OI)',
          code: 'QZIS',
        },
        {
          label: 'Etablissement public (ETSPUBLI)',
          code: 'QZIS',
        },
        {
          label: 'Öffentliche Einrichtung (ÖE)',
          code: 'QZIS',
        },
        {
          label: 'Naamloze vennootschap (NV)',
          code: 'R85P',
        },
        {
          label: 'Société anonyme (SA)',
          code: 'R85P',
        },
        {
          label: 'Aktiengesellschaft (AG)',
          code: 'R85P',
        },
        {
          label:
            'Burgerlijke vennootschap onder vorm van gewone commanditaire vennootschap (BV GCV)',
          code: 'T922',
        },
        {
          label:
            'Société civile sous forme de société en commandite simple (SC SCS)',
          code: 'T922',
        },
        {
          label:
            'Zivilrechtliche Gesellschaft in der Rechtsform einer einfachen Kommanditgesellschaft (ZRG EKG)',
          code: 'T922',
        },
        {
          label: 'Commanditaire vennootschap op aandelen (CommVA)',
          code: 'U2PN',
        },
        {
          label: 'Société en commandite par actions (SCA)',
          code: 'U2PN',
        },
        {
          label: 'Kommanditgesellschaft auf Aktien (KGaA)',
          code: 'U2PN',
        },
        {
          label:
            'Burgerlijke vennootschap onder vorm van vennootschap onder firma (BV VOF)',
          code: 'U5KF',
        },
        {
          label:
            'Société civile sous forme de société en nom collectif (SC SNC)',
          code: 'U5KF',
        },
        {
          label:
            'Zivilrechtliche Gesellschaft in der Rechtsform einer offenen Handelsgesellschaft (ZRG OHG)',
          code: 'U5KF',
        },
        {
          label:
            'Burgerlijke Vennootschap onder vorm van Landbouwvennootschap (BV LV)',
          code: 'UCT9',
        },
        {
          label: 'Société civile sous forme de société agricole (SC SAGR)',
          code: 'UCT9',
        },
        {
          label:
            'Zivilrechtliche Gesellschaft in der Rechtsform einer landwirtschaftlichen Gesellschaft (ZRG LG)',
          code: 'UCT9',
        },
        {
          label: 'Vennootschap onder firma (VOF)',
          code: 'UW1Y',
        },
        {
          label: 'Société en nom collectif (SNC)',
          code: 'UW1Y',
        },
        {
          label: 'Offene Handelsgesellschaft (OHG)',
          code: 'UW1Y',
        },
        {
          label: 'Internationale vereniging zonder winstoogmerk (IVZW)',
          code: 'V03J',
        },
        {
          label: 'Association internationale sans but lucratif (AISBL)',
          code: 'V03J',
        },
        {
          label:
            'Internationale Vereinigung ohne Gewinnerzielungsabsicht (IVoG)',
          code: 'V03J',
        },
        {
          label: 'Naamloze vennootschap met een sociaal oogmerk (NV SO)',
          code: 'V7FT',
        },
        {
          label: 'Aktiengesellschaft mit sozialer Zielsetzung (AGmsZ)',
          code: 'V7FT',
        },
        {
          label:
            'Burgerlijke vennootschap onder vorm van besloten vennootschap met beperkte aansprakelijkheid (BV BVBA)',
          code: 'V8L7',
        },
        {
          label:
            'Société civile sous forme de société privée à responsabilité limitée (SC SPRL)',
          code: 'V8L7',
        },
        {
          label:
            'Zivilrechtliche Gesellschaft in der Rechtsform einer Privatgesellschaft mit beschränkter Haftung (ZvGPgbH)',
          code: 'V8L7',
        },
        {
          label: 'Vereniging zonder winstoogmerk (VZW)',
          code: 'W3WH',
        },
        {
          label: 'Association sans but lucratif (ASBL)',
          code: 'W3WH',
        },
        {
          label: 'Vereinigung ohne Gewinnerzielungsabsicht (VoG)',
          code: 'W3WH',
        },
        {
          label:
            'Burgerlijke vennootschap onder vorm van coöperatieve vennootschap met onbeperkte aansprakelijkheid (BV CVOA)',
          code: 'WTLP',
        },
        {
          label:
            'Société civile sous forme de société coopérative à responsabilité illimitée (SC SCRI)',
          code: 'WTLP',
        },
        {
          label:
            'Zivilrechtliche Gesellschaft in der Rechtsform einer Genossenschaft mit unbeschränkter Haftung (ZvGGugcH)',
          code: 'WTLP',
        },
        {
          label:
            'Coöperatieve vennootschap met beperkte aansprakelijkheid van publiek recht (CVBA PR)',
          code: 'X8ZK',
        },
        {
          label:
            'Société coopérative à responsabilité limitée de droit public (SCRL DPU)',
          code: 'X8ZK',
        },
        {
          label:
            'Öffentlich-rechtliche Genossenschaft mit beschränkter Haftung (ÖfrGmbH)',
          code: 'X8ZK',
        },
        {
          label:
            'Gewone commanditaire vennootschap met een sociaal oogmerk (GCW SO)',
          code: 'XLEO',
        },
        {
          label:
            'Einfache Kommanditgesellschaft mit sozialer Zielsetzung (EKGmsZ)',
          code: 'XLEO',
        },
        {
          label: 'Private stichting (PS)',
          code: 'Y1Q4',
        },
        {
          label: 'Fondation privée (FONDPRIV)',
          code: 'Y1Q4',
        },
        {
          label: 'Privatstiftung (PrSt)',
          code: 'Y1Q4',
        },
        {
          label: 'Europese Coöperatieve Vennootschap (SCE)',
          code: 'YBHM',
        },
        {
          label: 'Société coopérative européenne (SCE)',
          code: 'YBHM',
        },
        {
          label: 'Europäische Genossenschaft (OFP)',
          code: 'YBHM',
        },
      ],
    },
  ],
  BG: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Evropeysko kooperativno druzhestvo s ogranichena otgovornost',
          code: '3FUP',
        },
        {
          label: 'Podelenie na evropeysko obedinenie po ikonomicheski interesi',
          code: '3HLJ',
        },
        {
          label: 'Sdruzhenie v obshtestvena polza',
          code: '3SHX',
        },
        {
          label: 'Klon na chuzhdestranen targovets',
          code: '45D7',
        },
        {
          label: 'Ednolichen targovets (ET)',
          code: '946C',
        },
        {
          label: 'Chuzhdestranno druzhestvo - fizichesko litse',
          code: '9F78',
        },
        {
          label: 'Kooperatsia',
          code: 'CTCH',
        },
        {
          label: 'Ednolichno aktsionerno druzhestvo (EAD)',
          code: 'EJ06',
        },
        {
          label: 'Sabiratelno druzhestvo (SD)',
          code: 'FPP2',
        },
        {
          label: 'Komanditno druzhestvo s aktsii (KDA)',
          code: 'HN63',
        },
        {
          label: 'Sdruzhenie v chastna polza',
          code: 'LECS',
        },
        {
          label: 'Chuzhdestranno druzhestvo - yuridichesko litse',
          code: 'O15N',
        },
        {
          label:
            'Aktsionerno druzhestvo sas spetsialna investitsionna tsel (ADSIC)',
          code: 'PW3Y',
        },
        {
          label:
            'Ednolichno aktsionerno druzhestvo sas spetsialna investitsionna tsel',
          code: 'QZAU',
        },
        {
          label: 'Evropeysko obedinenie po ikonomicheski interesi (EEIG)',
          code: 'RYDY',
        },
        {
          label: 'Komanditno druzhestvo (KD)',
          code: 'S94S',
        },
        {
          label: 'Evropeysko druzhestvo (ED)',
          code: 'SZW0',
        },
        {
          label:
            'Druzhestvo uchredeno po Zakona za zadalzheniyata i dogovorite (DZZD)',
          code: 'TCGV',
        },
        {
          label: 'Evropeysko kooperativno druzhestvo (EKD)',
          code: 'U8LM',
        },
        {
          label: 'Fondatsia',
          code: 'UFG8',
        },
        {
          label: 'Druzhestvo s ogranichena otgovornost (OOD)',
          code: 'VJ3G',
        },
        {
          label: 'Aktsionerno druzhestvo (AD)',
          code: 'WTK4',
        },
        {
          label: 'Ednolichno druzhestvo s ogranichena otgovornost (EOOD)',
          code: 'WVIN',
        },
      ],
    },
  ],
  BM: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Exempted Companies Limited by Shares',
          code: '7AS7',
        },
        {
          label: 'Exempted LLC',
          code: '7HCE',
        },
        {
          label: 'Exempted Limited Partnership',
          code: '7MGJ',
        },
        {
          label: 'Mutual Company',
          code: '9TCY',
        },
        {
          label: 'Exempted Partnership',
          code: 'ASR5',
        },
        {
          label: 'Local Company Limited by Guarantee',
          code: 'CW38',
        },
        {
          label: 'Local Unlimited Company',
          code: 'EURM',
        },
        {
          label: 'Exempted Company Limited by Guarantee',
          code: 'LC2L',
        },
        {
          label: 'Exempted Unlimited Company',
          code: 'MBVS',
        },
        {
          label: 'Local Company Limited by Shares',
          code: 'NAQG',
        },
        {
          label: 'Local LLC',
          code: 'UZWE',
        },
        {
          label: 'Mutual Fund Company',
          code: 'VFXJ',
        },
        {
          label: 'Permit',
          code: 'WF3Q',
        },
        {
          label: 'Overseas',
          code: 'ZLTD',
        },
      ],
    },
  ],
  BN: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Public Company',
          code: '2DGO',
        },
        {
          label: 'Private Limited Company',
          code: 'EULU',
        },
      ],
    },
  ],
  BO: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Empresa Unipersonal',
          code: '38JQ',
        },
        {
          label: 'Sociedad Anónima (SA)',
          code: '5T3W',
        },
        {
          label: 'Empresa Estatal ',
          code: '70BS',
        },
        {
          label: 'Institución Financiera De Desarrollo   ',
          code: 'DSML',
        },
        {
          label: 'Empresa Estatal Intergubernamental     ',
          code: 'JYAN',
        },
        {
          label: 'Sociedad Anónima Mixta                 (SAM)',
          code: 'LJYQ',
        },
        {
          label: 'Entidad Financiera De Vivienda',
          code: 'LN9O',
        },
        {
          label: 'Empresa Mixta                         ',
          code: 'MGOU',
        },
        {
          label: 'Sociedad en Comandita por Acciones  (SCA)',
          code: 'PJ29',
        },
        {
          label: 'Sociedad de Responsabilidad Limitada (SRL)',
          code: 'PM48',
        },
        {
          label: 'Empresa Estatal Mixta                  ',
          code: 'RXJN',
        },
        {
          label: 'Sociedad Colectiva (SC)',
          code: 'TNHO',
        },
        {
          label: 'Sociedad en Comandita Simple (SCS)',
          code: 'XTCQ',
        },
      ],
    },
  ],
  BQ: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Besloten vennootschap',
          code: '1A0A',
        },
        {
          label: 'Besloten vennootschap naar Nederlands recht',
          code: '3Y5C',
        },
        {
          label: 'Commanditaire vennootschap met meerdere beherende vennoten',
          code: '4S4Z',
        },
        {
          label: 'Maatschap',
          code: '4ZBE',
        },
        {
          label: 'Vereniging met beperkte rechtsbevoegdheid',
          code: '51WZ',
        },
        {
          label: 'Aandeelhouder bestuurde Besloten vennootschap',
          code: '56MU',
        },
        {
          label: 'Stichting',
          code: '92JZ',
        },
        {
          label: 'Stichting particulier fonds',
          code: 'AS4W',
        },
        {
          label: 'Nevenvestiging met hoofdvestiging buiten bonaire',
          code: 'B2UK',
        },
        {
          label: 'Naamloze vennootschap',
          code: 'DQMJ',
        },
        {
          label: 'Vereniging met volledige rechtsbevoegdheid',
          code: 'FCPM',
        },
        {
          label: 'Eenmanszaak met meerdere eigenaren',
          code: 'IPTQ',
        },
        {
          label: 'Buitenlandse rechtsvorm hoofdvestiging op Bonaire',
          code: 'JFQ5',
        },
        {
          label: 'Commanditaire vennootschap op aandelen',
          code: 'KSHL',
        },
        {
          label: 'Eenmanszaak',
          code: 'NPFB',
        },
        {
          label: 'Vennootschap onder Firma',
          code: 'SNRI',
        },
        {
          label: 'Cooperatie',
          code: 'VHIY',
        },
        {
          label: 'Vereniging zonder rechtsbevoegdheid',
          code: 'X088',
        },
        {
          label: 'Commanditaire vennootschap met één beherend vennoot',
          code: 'XL51',
        },
      ],
    },
  ],
  BR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Empresa Pública (EP)',
          code: '4LKN',
        },
        {
          label: 'Sociedade em Nome Coletivo (SNC)',
          code: '529B',
        },
        {
          label: 'Sociedade Simples (S/S)',
          code: '8GEA',
        },
        {
          label: 'Organização Religiosa',
          code: '9BIS',
        },
        {
          label: 'Partido Político',
          code: 'ANHM',
        },
        {
          label: 'Fundação (Fund)',
          code: 'I4T1',
        },
        {
          label: 'Associação (Assoc)',
          code: 'J7G9',
        },
        {
          label: 'Empresa Individual de Responsabilidade Limitada (EIRELI)',
          code: 'OB5J',
        },
        {
          label: 'Sociedade Cooperativa',
          code: 'PC5A',
        },
        {
          label: 'Sociedade Limitada (LTDA)',
          code: 'QHW1',
        },
        {
          label: 'Sociedade em Comdandita Simples (SCS)',
          code: 'WBEC',
        },
        {
          label: 'Sociedade Anônima (S/A)',
          code: 'X0SD',
        },
        {
          label: 'Companhia (S/A)',
          code: 'X0SD',
        },
        {
          label: 'Sociedade em Comandita por Ações (C/A)',
          code: 'X2ZT',
        },
        {
          label: 'Sociedade em Conta de Participação (SCP)',
          code: 'XQRI',
        },
      ],
    },
  ],
  BS: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '1W6I',
        },
        {
          label: 'Partnership',
          code: '78QF',
        },
        {
          label: 'Company Limited by Shares (Ltd)',
          code: '98A8',
        },
        {
          label: 'International Business Company',
          code: 'A9N4',
        },
        {
          label: 'Unlimited Liability Company',
          code: 'DAEG',
        },
        {
          label: 'Public Company Limited by Shares (Ltd)',
          code: 'HNSU',
        },
        {
          label: 'Company Limited both by Shares and by Guarantee (Ltd)',
          code: 'HP0B',
        },
        {
          label: 'Company Limited by Guarantee (Ltd)',
          code: 'PB7W',
        },
        {
          label: 'Non-Profit Company',
          code: 'PICJ',
        },
        {
          label: 'Exempted Limited Partnership',
          code: 'SQ8U',
        },
        {
          label: 'Foundation',
          code: 'VVOW',
        },
        {
          label: 'Sole Proprietor',
          code: 'XDKS',
        },
      ],
    },
  ],
  BW: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Close Companies ',
          code: '4IXL',
        },
        {
          label: 'Private Limited Liability Companies',
          code: '771I',
        },
        {
          label: 'Public Limited Liability Companies',
          code: 'QK1F',
        },
        {
          label: 'Companies limited by Guarantee',
          code: 'Y2L3',
        },
      ],
    },
  ],
  BY: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Zakrytae akcyyanernae tavarystva (ZAT)',
          code: '106J',
        },
        {
          label: 'Zakrytoe akcionernoe obshchestvo (ZAО)',
          code: '106J',
        },
        {
          label: 'Unіtarnae pradpryemstva (UP)',
          code: '9X63',
        },
        {
          label: 'Unitarnoe predpriyatie (UP)',
          code: '9X63',
        },
        {
          label: 'Tavarystva z abmezhavanaj adkaznascyu (ТАА)',
          code: 'AABD',
        },
        {
          label: "Obshchestvo s ogranichennoj otvetstvennost'yu (ООО)",
          code: 'AABD',
        },
        {
          label: 'Tavarystva z dadatkovaj adkaznascyu (ТDA)',
          code: 'JLR3',
        },
        {
          label: "Obshchestvo s dopolnitel'noj otvetstvennost'yu (ODO)",
          code: 'JLR3',
        },
        {
          label: 'Adkrytae akcyyanernae tavarystva (AAT)',
          code: 'MR85',
        },
        {
          label: 'Otkrytoe akcionernoe obshchestvo (ОАО)',
          code: 'MR85',
        },
      ],
    },
  ],
  BZ: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'International Business Company',
          code: '84DD',
        },
        {
          label: 'Unlimited Company',
          code: 'AO0O',
        },
        {
          label: 'Partnership',
          code: 'D1SL',
        },
        {
          label: 'Company Limited by Shares',
          code: 'EAD0',
        },
        {
          label: 'Association',
          code: 'GJKF',
        },
        {
          label: 'Company Limited by Guarantee',
          code: 'ZHT0',
        },
      ],
    },
  ],
  CA: [
    {
      subdivision_label: 'Québec',
      subdivision_code: 'CA-QC',
      legal_forms: [
        {
          label: 'Syndicat coopératif',
          code: '10UR',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les cercles agricoles',
          code: '16GH',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la mise en marché des produits agricoles',
          code: '16RL',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les évêques catholiques romains',
          code: '1VTA',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les cités et villes',
          code: '27WJ',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les sociétés agricoles et laitières',
          code: '2ODA',
        },
        {
          label: 'Société en nom collectif à responsabilité limitée (SENCRL)',
          code: '2U3G',
        },
        {
          label:
            'Société par actions constitué par Loi sur les compagnies minières',
          code: '30IT',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la probation et sur les établissements de détention',
          code: '3C5P',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les corporations de fonds de sécurité',
          code: '3FP6',
        },
        {
          label:
            'Société par actions constitué par Loi sur les sociétés de patrons de fabriques de producteurs laitiers',
          code: '4B4B',
        },
        {
          label: 'Société par actions constitué par Loi sur les assurances',
          code: '50Z9',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les villes minières',
          code: '52CK',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la formation et la qualification professionnelles de la main-d’œuvre',
          code: '6ZCO',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les Chevaliers de Colomb de la province de Québec',
          code: '702U',
        },
        {
          label: 'Société en participation',
          code: '814Y',
        },
        {
          label:
            "Personne morale sans but lucratif par Loi sur les collèges d'enseignement général et professionnel",
          code: '87OW',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les services de santé et les services sociaux pour les autochtones cris',
          code: '95EN',
        },
        {
          label:
            "Société par actions constitué par Loi sur les syndicats d'élevage",
          code: '9CB2',
        },
        {
          label:
            "Société par actions constitué par Loi sur les sociétés d'exploration minière",
          code: '9CEN',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les associations coopératives',
          code: '9IF2',
        },
        {
          label: 'Selon la loi par Loi spéciale du Québec à caractère public',
          code: 'A7KA',
        },
        {
          label:
            'Société par actions constitué par Loi sur les compagnies de gaz, d’eau et d’électricité',
          code: 'AS7L',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la Société d’habitation du Québec',
          code: 'CG81',
        },
        {
          label: 'Personne morale sans but lucratif par Loi sur les compagnies',
          code: 'CT3J',
        },
        {
          label: 'Personne morale sans but lucratif par Loi sur les compagnies',
          code: 'D2T8',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur l’instruction publique',
          code: 'F9CT',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les sociétés nationales de bienfaisance',
          code: 'GAMO',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la constitution de certaines Églises',
          code: 'HVWR',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les services de santé et les services sociaux',
          code: 'I3UX',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la conservation et la mise en valeur de la faune',
          code: 'J5SC',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les clubs de chasse et de pêche',
          code: 'JBU2',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la mise en marché des produits agricoles, alimentaires et de la pêche',
          code: 'JI4V',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la conservation de la faune',
          code: 'JLE0',
        },
        {
          label:
            'Société par actions constitué par Loi sur les compagnies de fidéicommis',
          code: 'JLZW',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les sociétés préventives de cruauté envers les animaux',
          code: 'JQNA',
        },
        {
          label:
            'Société par actions par Fonds mutuel constitué par acte de fiducie',
          code: 'JVMD',
        },
        {
          label:
            "Société par actions constitué par Loi sur les sociétés de fiducie et les sociétés d'épargne",
          code: 'JXO5',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les compagnies de cimetière',
          code: 'K08P',
        },
        {
          label:
            'Société par actions par Fonds mutuel constitué par acte de fiducie',
          code: 'L26C',
        },
        {
          label:
            "Personne morale sans but lucratif par Loi sur les sociétés d'horticulture",
          code: 'L3XH',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les villages miniers',
          code: 'LN3N',
        },
        {
          label:
            "Personne morale sans but lucratif par Loi sur les sociétés d'agriculture",
          code: 'MCY8',
        },
        {
          label:
            "Société par actions constitué par Loi sur les sociétés d'entraide économique",
          code: 'MK1I',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la santé et la sécurité du travail',
          code: 'MQT7',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les décrets de convention collective',
          code: 'MR95',
        },
        {
          label:
            "Personne morale sans but lucratif par Loi sur l'organisation territoriale municipale",
          code: 'NVXN',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les clubs de récréation',
          code: 'OMUP',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les syndicats professionnels',
          code: 'Q8NY',
        },
        {
          label: 'Coopérative (coop)',
          code: 'QB2W',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les villages nordiques et l’Administration régionale Kativik',
          code: 'RC3D',
        },
        {
          label:
            'Société par actions constitué par Loi sur les compagnies de télégraphe et de téléphone',
          code: 'RPGT',
        },
        {
          label: 'Société en commandite (SEC)',
          code: 'RQIC',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la Fédération des scouts catholiques du Québec',
          code: 'S72N',
        },
        {
          label: 'Syndicat de copropriété',
          code: 'T1LR',
        },
        {
          label:
            'Société par actions constitué par Loi sur les compagnies de flottage',
          code: 'TA7J',
        },
        {
          label:
            "Société par actions constitué par Loi sur les sociétés de développement de l'entreprise québécoise",
          code: 'TKAB',
        },
        {
          label: 'Association',
          code: 'TWLR',
        },
        {
          label:
            'Société par actions par Loi sur les sociétés par actions (ltd)',
          code: 'UG3Z',
        },
        {
          label:
            'Société par actions constitué par Loi sur les sociétés de construction',
          code: 'UVCG',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur l’aménagement et l’urbanisme',
          code: 'V5IH',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la société Saint-Jean-Baptiste de Trois-Rivières',
          code: 'V9GU',
        },
        {
          label: 'Personne morale sans but lucratif par Loi sur les fabriques',
          code: 'VGP6',
        },
        {
          label: 'Personne morale sans but lucratif par Code des professions',
          code: 'VRVJ',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur l’organisation municipale de certains territoires',
          code: 'WGEA',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi concernant le regroupement et la gestion des commissions scolaires',
          code: 'XS49',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la Fédération des guides catholiques du Québec',
          code: 'XW5K',
        },
        {
          label: 'Personne morale sans but lucratif par Loi sur les assurances',
          code: 'YBMJ',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les corporations religieuses',
          code: 'YIIS',
        },
        {
          label: 'Société en nom collectif (SENC)',
          code: 'YRUJ',
        },
        {
          label: 'Selon la loi par Loi spéciale du Québec à caractère privé',
          code: 'Z6MF',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les services correctionnels',
          code: 'ZGEX',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur la société Saint-Jean-Baptiste du Québec',
          code: 'ZQQU',
        },
        {
          label:
            'Personne morale sans but lucratif par Loi sur les compagnies de cimetières catholiques romains',
          code: 'ZX1F',
        },
      ],
    },
    {
      subdivision_label: 'Northwest Territories',
      subdivision_code: 'CA-NT',
      legal_forms: [
        {
          label: 'Extra-Territorial Corporation (Ltée)',
          code: '1ADH',
        },
        {
          label: 'Limited Liability partnership',
          code: '85EC',
        },
        {
          label: 'NT society',
          code: 'CS15',
        },
        {
          label: 'Co-operative (Ltée)',
          code: 'KAM9',
        },
        {
          label: 'NT corporation (Ltée)',
          code: 'PIDT',
        },
        {
          label:
            'NT Professional Corporation authorized under the Professional Corporations Act (Ltée)',
          code: 'QN8Y',
        },
        {
          label: 'Sole proprietorship',
          code: 'QR4F',
        },
        {
          label: 'Limited partnership',
          code: 'TA98',
        },
        {
          label: 'Private act corporation',
          code: 'TPZ2',
        },
        {
          label: 'General partnership',
          code: 'VAPN',
        },
        {
          label: 'Private act not-for-profit corporation',
          code: 'W126',
        },
      ],
    },
    {
      subdivision_label: 'British Columbia',
      subdivision_code: 'CA-BC',
      legal_forms: [
        {
          label: 'Sole proprietorship (SRL)',
          code: '1CML',
        },
        {
          label: 'Limited partnership (SRL)',
          code: '23V9',
        },
        {
          label: 'Association (co-op)',
          code: 'AW80',
        },
        {
          label: 'Limited partnership formed outside of BC (SRL)',
          code: 'F9J3',
        },
        {
          label: 'Pension fund society',
          code: 'HLBQ',
        },
        {
          label: 'Limited liability partnership (SRL)',
          code: 'LNBY',
        },
        {
          label: 'Mutual fire insurance company',
          code: 'LRRN',
        },
        {
          label: 'General partnership (SRL)',
          code: 'O13B',
        },
        {
          label: 'Extraprovincial non-share corporation',
          code: 'OTU7',
        },
        {
          label: 'Extraprovincial limited liability partnership (SRL)',
          code: 'QODJ',
        },
        {
          label: 'Registered extraprovincial non-share corporation',
          code: 'S3K4',
        },
        {
          label: 'Extraprovincial corporation (Inc)',
          code: 'TGLV',
        },
        {
          label: 'Society',
          code: 'WFPH',
        },
        {
          label: 'Credit union',
          code: 'XOYI',
        },
        {
          label: 'Company (Inc)',
          code: 'YG5M',
        },
      ],
    },
    {
      subdivision_label: 'Newfoundland and Labrador',
      subdivision_code: 'CA-NL',
      legal_forms: [
        {
          label: 'Limited Partnership (LP)',
          code: '1E9M',
        },
        {
          label: 'Co-operatives (co-op)',
          code: '3Y5V',
        },
        {
          label: 'Share corporations (Ltd)',
          code: 'ASWX',
        },
        {
          label: 'Credit Union',
          code: 'EBYQ',
        },
        {
          label: 'Registration of extra-provincial corporations',
          code: 'ER0H',
        },
        {
          label: 'Condominium Corporation',
          code: 'GTBR',
        },
        {
          label: 'Non-share corporations (Inc)',
          code: 'IKGM',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'SYPT',
        },
      ],
    },
    {
      subdivision_label: 'Alberta',
      subdivision_code: 'CA-AB',
      legal_forms: [
        {
          label: 'Sole proprietorship',
          code: '1EQC',
        },
        {
          label: 'Alberta trust corporation',
          code: '60MF',
        },
        {
          label: 'Alberta Business Corporation',
          code: '8ZH8',
        },
        {
          label: 'Public recreation company',
          code: '94UQ',
        },
        {
          label: 'Alberta private non-profit company',
          code: 'B69V',
        },
        {
          label: 'Alberta cooperative',
          code: 'BASA',
        },
        {
          label: 'Rural utility cooperative',
          code: 'BH5D',
        },
        {
          label: 'Alberta professional corporation',
          code: 'C9KZ',
        },
        {
          label: 'Alberta loan corporation',
          code: 'DK7L',
        },
        {
          label: 'Alberta credit union',
          code: 'G65S',
        },
        {
          label: 'Alberta unlimited liability corporation',
          code: 'J3C5',
        },
        {
          label: 'Alberta society',
          code: 'K1QX',
        },
        {
          label: 'General partnership',
          code: 'KVC1',
        },
        {
          label: 'Private recreation company',
          code: 'PXPK',
        },
        {
          label: 'Private act corporation',
          code: 'R0KX',
        },
        {
          label: 'Private act non-profit corp',
          code: 'RHVP',
        },
        {
          label: 'Alberta limited partnership',
          code: 'RN4K',
        },
        {
          label: 'Agricultural society',
          code: 'TRO6',
        },
        {
          label: 'Religious society',
          code: 'TS9O',
        },
        {
          label: 'Alberta limited liability partnership',
          code: 'U938',
        },
        {
          label: 'Public non-profit company',
          code: 'VHXN',
        },
      ],
    },
    {
      subdivision_label: 'Ontario',
      subdivision_code: 'CA-ON',
      legal_forms: [
        {
          label: 'Limited Partnership',
          code: '1JGX',
        },
        {
          label: 'Sole proprietorship',
          code: '456Q',
        },
        {
          label: 'Condominium Corporation',
          code: '7VKC',
        },
        {
          label:
            'Corporations formed by an Act of the Ontario Legislative Assembly',
          code: 'BOYK',
        },
        {
          label: 'Not-For-Profit corporations',
          code: 'CMB6',
        },
        {
          label:
            'Insurance Corporations incorporated under Part V of the Corporations Act',
          code: 'EIDL',
        },
        {
          label: 'Credit Union',
          code: 'ESVK',
        },
        {
          label: 'Co-operative Corporation',
          code: 'FBQL',
        },
        {
          label: 'General partnership',
          code: 'HQKE',
        },
        {
          label: 'Business corporations',
          code: 'O90R',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'PCO0',
        },
      ],
    },
    {
      subdivision_label: 'Nova Scotia',
      subdivision_code: 'CA-NS',
      legal_forms: [
        {
          label: 'Partnerships',
          code: '2FHC',
        },
        {
          label:
            'A company formed under the Companies Act may seek designation as a Community Interest Company (CIC)',
          code: '3M14',
        },
        {
          label: 'Unlimited company',
          code: '42GN',
        },
        {
          label: 'Credit Unions',
          code: '876U',
        },
        {
          label: 'Condominiums',
          code: '8M03',
        },
        {
          label: 'Limited liability partnerships (LLP)',
          code: '8OZ0',
        },
        {
          label: 'Business names',
          code: '9XHE',
        },
        {
          label: 'For profit and not-for profit co-operatives (Ltd)',
          code: 'FQJ9',
        },
        {
          label: 'Company limited by guarantee (Ltd)',
          code: 'GG0S',
        },
        {
          label: 'Limited partnerships formed in NS',
          code: 'T0CV',
        },
        {
          label:
            'A company formed under the Companies Act may seek designation as a Private Investment Holding Company (Ltd)',
          code: 'TY0S',
        },
        {
          label: 'Company limited by shares (Ltd)',
          code: 'ULJ1',
        },
        {
          label: 'Sole proprietors',
          code: 'W8XG',
        },
        {
          label: 'Non-profit Corporations (Association)',
          code: 'Y80K',
        },
      ],
    },
    {
      subdivision_label: 'Saskatchewan',
      subdivision_code: 'CA-SK',
      legal_forms: [
        {
          label: 'Non-profit Corporations (Corp)',
          code: '3OVJ',
        },
        {
          label: 'Limited Liabilty Partnerships',
          code: '5D9I',
        },
        {
          label:
            'Corporations formed by an Act of the Saskatchewan legislature',
          code: '5EEA',
        },
        {
          label: 'Limited Partnership',
          code: '7IKZ',
        },
        {
          label: 'New Generation Co-operatives (co-op ltd)',
          code: '7XZL',
        },
        {
          label: 'Condominium Corporations',
          code: '8KL5',
        },
        {
          label: 'Credit Unions',
          code: 'FYYY',
        },
        {
          label: 'Sole Proprietors',
          code: 'I8VP',
        },
        {
          label: 'Co-operatives (ltd)',
          code: 'JNCJ',
        },
        {
          label: 'Syndicates',
          code: 'KZDW',
        },
        {
          label: 'Joint Ventures',
          code: 'L2NN',
        },
        {
          label: 'Partnership',
          code: 'LMSY',
        },
        {
          label: 'Business Corporations (Inc)',
          code: 'UWMY',
        },
      ],
    },
    {
      subdivision_label: 'New Brunswick',
      subdivision_code: 'CA-NB',
      legal_forms: [
        {
          label: 'Partnership',
          code: '3Q15',
        },
        {
          label: 'Société en commandite',
          code: '40JO',
        },
        {
          label: 'Professional corporation (PC)',
          code: '44LR',
        },
        {
          label: 'Loan company',
          code: '4M62',
        },
        {
          label: 'Agricultural fair association',
          code: '56KZ',
        },
        {
          label: 'Association coopérative (ltée)',
          code: '64XG',
        },
        {
          label: 'Condominium corporation',
          code: '6U2Q',
        },
        {
          label: 'Limited partnership (LP)',
          code: '7P8Y',
        },
        {
          label: 'New Brunswick limited liability partnership (LLP)',
          code: '8MEC',
        },
        {
          label: 'Depends on Act of the Legislature',
          code: 'AXW3',
        },
        {
          label: 'Corporation (Inc)',
          code: 'B3RX',
        },
        {
          label: 'Professional corporation (PC)',
          code: 'D8UQ',
        },
        {
          label: 'Project company',
          code: 'DHGI',
        },
        {
          label: 'New Brunswick limited liability partnership (LLP)',
          code: 'EPFN',
        },
        {
          label: 'Association condominiale',
          code: 'FGSL',
        },
        {
          label: 'Business name - sole proprietor',
          code: 'GF6X',
        },
        {
          label: 'Agricultural society',
          code: 'GLX2',
        },
        {
          label: 'Compagnie',
          code: 'GPG0',
        },
        {
          label: 'Compagnie de fiducie',
          code: 'IDBS',
        },
        {
          label: 'Co-operative association (Ltd)',
          code: 'IKMB',
        },
        {
          label: 'Société en nom collectif',
          code: 'IKSU',
        },
        {
          label: 'Caisse populaire',
          code: 'ILON',
        },
        {
          label: 'Company',
          code: 'JBBW',
        },
        {
          label: 'Depends on Act of the Legislature',
          code: 'M53U',
        },
        {
          label: 'Credit union',
          code: 'MY4W',
        },
        {
          label: 'Corporation (Inc)',
          code: 'NONB',
        },
        {
          label: 'Gérant de projet',
          code: 'QJ3A',
        },
        {
          label: 'Foreign resident corporation',
          code: 'QNSC',
        },
        {
          label: 'Agricultural fair association',
          code: 'TSQ7',
        },
        {
          label: 'Société agricole',
          code: 'WI0P',
        },
        {
          label: 'Trust company',
          code: 'ZACY',
        },
        {
          label: 'Appellation commerciale - propriétaire unique',
          code: 'ZY4E',
        },
      ],
    },
    {
      subdivision_label: 'Prince Edward Island',
      subdivision_code: 'CA-PE',
      legal_forms: [
        {
          label: 'Corporation created by an Act of the Legislature',
          code: '487P',
        },
        {
          label: 'Condominium corporation',
          code: '5SGE',
        },
        {
          label: 'Non-profit company (Inc)',
          code: '790T',
        },
        {
          label: 'Public unlimited liability corporation (ULC)',
          code: 'CUQF',
        },
        {
          label: 'Limited partnership',
          code: 'E281',
        },
        {
          label: 'Public limited corporation (Inc)',
          code: 'HK03',
        },
        {
          label: 'Sole proprietorship',
          code: 'I5NO',
        },
        {
          label: 'Credit union (Ltée)',
          code: 'JOGO',
        },
        {
          label: 'Professional corporation (Inc)',
          code: 'LT1Y',
        },
        {
          label: 'Public company (Inc)',
          code: 'N745',
        },
        {
          label: 'Private limited corporation (Inc)',
          code: 'PFPW',
        },
        {
          label: 'Private unlimited liability corporation (ULC)',
          code: 'QZMH',
        },
        {
          label: 'Private company (Inc)',
          code: 'W4DB',
        },
        {
          label: 'Co-operative association (Coop)',
          code: 'X6N9',
        },
        {
          label: 'Partnership',
          code: 'Y33B',
        },
      ],
    },
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Board of trade',
          code: '59T2',
        },
        {
          label: 'Corporations enacted by an Act of Parliament',
          code: '6TRA',
        },
        {
          label: 'Corporation without share capital',
          code: 'D90J',
        },
        {
          label: 'Pension fund society',
          code: 'M4IF',
        },
        {
          label: 'Cooperative (co-op)',
          code: 'MGE0',
        },
        {
          label: 'Business corporation (Inc)',
          code: 'UDLA',
        },
      ],
    },
    {
      subdivision_label: 'Manitoba',
      subdivision_code: 'CA-MB',
      legal_forms: [
        {
          label: 'Religious Society',
          code: '5ONY',
        },
        {
          label: 'Non-share corporations (Inc)',
          code: '9D1K',
        },
        {
          label: 'Share corporations (Ltd)',
          code: 'FN6X',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'G9PJ',
        },
        {
          label: 'Cooperative (Co-op)',
          code: 'GM04',
        },
        {
          label: 'Ukrainian Catholic Parish',
          code: 'HBCT',
        },
        {
          label: 'Condominium Corporation',
          code: 'LV28',
        },
        {
          label: 'Sole Proprietorship',
          code: 'MXLT',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'OPRT',
        },
        {
          label: 'Partnership',
          code: 'RBBY',
        },
        {
          label: 'Registration of extra-provincial corporations',
          code: 'SS1V',
        },
        {
          label: 'Credit union or Caisse Populaire',
          code: 'Y485',
        },
      ],
    },
  ],
  CH: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label:
            'Swiss branch of an enterprise domiciled abroad not registered in the commercial register (Foreign Coop & LtdCo)',
          code: '1BL5',
        },
        {
          label: 'Foreign public administration',
          code: '1RKS',
        },
        {
          label: 'Simple partnership',
          code: '2B81',
        },
        {
          label: 'Foundation',
          code: '2JZ4',
        },
        {
          label: 'Foreign public company',
          code: '2WFG',
        },
        {
          label: 'Federal administration',
          code: '2XJA',
        },
        {
          label: 'Limited liability company (Ltd)',
          code: '3EKS',
        },
        {
          label: 'Investment company with fixed capital',
          code: '54WI',
        },
        {
          label: 'Special legal form',
          code: '5BEZ',
        },
        {
          label: 'Public sector institution',
          code: '7MNN',
        },
        {
          label: 'Sole proprietorship',
          code: 'AZA0',
        },
        {
          label: 'Federal public company',
          code: 'BF9N',
        },
        {
          label: 'Limited partnership',
          code: 'CQMY',
        },
        {
          label: 'International organisation',
          code: 'DP2E',
        },
        {
          label: 'Public corporation',
          code: 'E0NE',
        },
        {
          label: 'Cantonal administration',
          code: 'FFTN',
        },
        {
          label: 'Trust',
          code: 'FJG4',
        },
        {
          label: 'Fund',
          code: 'FLNB',
        },
        {
          label: 'Local administration (Local administration)',
          code: 'GP8M',
        },
        {
          label: 'Association',
          code: 'H781',
        },
        {
          label: 'Cantonal public company (Cant publ co)',
          code: 'HX77',
        },
        {
          label:
            "Succursale di una impresa domiciliata all'estero iscritta al registro di commercio (SA dom estero)",
          code: 'JB25',
        },
        {
          label: 'Non-commercial power of attorney (Power Att)',
          code: 'KJ9Q',
        },
        {
          label: 'Local public company',
          code: 'L5DU',
        },
        {
          label: 'Corpororate public company (Corp publ co)',
          code: 'M848',
        },
        {
          label: 'General partnership',
          code: 'MRSY',
        },
        {
          label: 'Company limited by shares (PLC)',
          code: 'MVII',
        },
        {
          label:
            'Representative of an ownership in undivided shares (Undivided)',
          code: 'OBFU',
        },
        {
          label: 'Cooperative',
          code: 'QSI2',
        },
        {
          label: 'Foreign enterprise',
          code: 'R9TC',
        },
        {
          label: 'Limited partnership for collective investments (Ldt)',
          code: 'TL87',
        },
        {
          label:
            'Swiss branch registered at the registry of commerce (Branch RC)',
          code: 'UNA9',
        },
        {
          label: 'District public company',
          code: 'VPRH',
        },
        {
          label: 'Patnership limited by shares',
          code: 'W6A7',
        },
        {
          label: 'Investment company with variable capital',
          code: 'XJOT',
        },
        {
          label: 'District administration',
          code: 'YRGM',
        },
      ],
    },
  ],
  CK: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'International Company',
          code: '70NZ',
        },
        {
          label: 'Limited Liability Company',
          code: 'A7G6',
        },
        {
          label: 'International Partnership',
          code: 'KNIC',
        },
        {
          label: 'Foundation',
          code: 'N00R',
        },
        {
          label: 'International Trust',
          code: 'YCQM',
        },
        {
          label: 'Foreign Company',
          code: 'Z3Y2',
        },
      ],
    },
  ],
  CL: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sociedad Colectiva Civil',
          code: '2UZP',
        },
        {
          label: 'Asociación',
          code: '78VN',
        },
        {
          label: 'Sociedad Legal Minera (SLM)',
          code: '7T6S',
        },
        {
          label: 'Asociación Gremial (AG)',
          code: '7ZMX',
        },
        {
          label: 'Entidad religiosa',
          code: 'B8OI',
        },
        {
          label: 'Cooperativa',
          code: 'C6AH',
        },
        {
          label: 'Sociedad de Responsabilidad Limitada (SRL)',
          code: 'CVDP',
        },
        {
          label: 'Sociedad Colectiva Comercial',
          code: 'CZCE',
        },
        {
          label: 'Sociedad en Comandita Comercial',
          code: 'EE3W',
        },
        {
          label: 'Sociedad por Acciones (SpA)',
          code: 'KUUZ',
        },
        {
          label: 'Sociedad Anónima (SA)',
          code: 'OA6N',
        },
        {
          label: 'Sociedad Contractual Minera (SCM)',
          code: 'Q1J0',
        },
        {
          label: 'Empresa Individual de Responsabilidad Limitada (EIRL)',
          code: 'QFYC',
        },
        {
          label: 'Organización Comunitaria',
          code: 'RNQ0',
        },
        {
          label: 'Sociedad en Comandita Civil',
          code: 'VZ3G',
        },
        {
          label: 'Organización Deportiva',
          code: 'Y8F1',
        },
        {
          label: 'Fundación',
          code: 'YDNQ',
        },
        {
          label: 'Comunidad o Asociación Indígena',
          code: 'ZHHH',
        },
      ],
    },
  ],
  CN: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Wai guo chang zhu xin wen ji gou',
          code: '1IWK',
        },
        {
          label: 'Ji jin hui',
          code: '2M6Y',
        },
        {
          label: 'Jun dui shi ye dan wei',
          code: '5A9K',
        },
        {
          label: 'Cun ji ji ti jing ji zu zhi',
          code: '5GXA',
        },
        {
          label: 'Zong jiao yuan xiao',
          code: '6KC0',
        },
        {
          label: 'Lv shi zhi ye ji gou',
          code: '6NSC',
        },
        {
          label: 'Wai guo zai hua wen hua zhong xin',
          code: '8XAW',
        },
        {
          label: 'Shi ye dan wei',
          code: 'B5UZ',
        },
        {
          label:
            'Bian ban zhi jie guan li ji gou bian zhi de qun zhong tuan ti',
          code: 'BDTI',
        },
        {
          label: 'Qi ta wai jiao',
          code: 'C38B',
        },
        {
          label: 'Qi ta zhong yang jun wei gai ge he bian zhi ban gong shi',
          code: 'CYV6',
        },
        {
          label: 'Xiang zhen ji ji ti jing ji zu zhi',
          code: 'DLEK',
        },
        {
          label: 'Min ban fei qi ye dan wei',
          code: 'E4FG',
        },
        {
          label: 'Gang ao tai di qu lv you bu men chang zhu nei di',
          code: 'E4YV',
        },
        {
          label: 'Gong zheng chu',
          code: 'E88S',
        },
        {
          label: 'Qi ye',
          code: 'ECAK',
        },
        {
          label: 'Qi ta nong ye',
          code: 'ESZ8',
        },
        {
          label: 'She hui tuan ti',
          code: 'GGZ5',
        },
        {
          label: 'Qi ta wen hua',
          code: 'GPHT',
        },
        {
          label: 'Ji guan',
          code: 'I39S',
        },
        {
          label: 'Ji ceng fa lv fu wu suo',
          code: 'IBEM',
        },
        {
          label: 'Qi ta shi yong yu wu deng ji ji gou de de qing kuang',
          code: 'LURL',
        },
        {
          label: 'Qi ta si fa xing zheng',
          code: 'NWJX',
        },
        {
          label: 'Ji ceng gong hui',
          code: 'OH9O',
        },
        {
          label: 'Qi ta lv you',
          code: 'OMUD',
        },
        {
          label: 'Qi ta gong hui',
          code: 'R3O0',
        },
        {
          label: 'Ge ti gong shang hu',
          code: 'RV48',
        },
        {
          label: 'Si fa jian ding ji gou',
          code: 'S9UH',
        },
        {
          label: 'Qi ta ji gou bian zhi',
          code: 'SH05',
        },
        {
          label: 'Qi ta zong jiao',
          code: 'T0KK',
        },
        {
          label: 'Wai guo lv you bu men chang zhu dai biao ji gou',
          code: 'TBG2',
        },
        {
          label: 'Nong min zhuan ye he zuo she',
          code: 'UMCR',
        },
        {
          label: 'Zhong cai wei yuan hui',
          code: 'V251',
        },
        {
          label: 'Zu ji ji ti jing ji zu zhi',
          code: 'V816',
        },
        {
          label: 'Qi ta min zheng',
          code: 'WEUX',
        },
        {
          label: 'Zong jiao huo dong chang suo',
          code: 'YXJ5',
        },
      ],
    },
  ],
  CO: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Empresa Unipersonal (EU)',
          code: '1PAD',
        },
        {
          label: 'Sociedad Anónima (SA)',
          code: '3RHN',
        },
        {
          label: 'Persona natural comerciante',
          code: '4OYE',
        },
        {
          label: 'Sucursal de Sociedad Extranjera',
          code: '6Z8B',
        },
        {
          label: 'Empresa Asociativa de Trabajo',
          code: 'D2T3',
        },
        {
          label: 'Sociedad Colectiva',
          code: 'DEJ0',
        },
        {
          label: 'Sociedad en comandita simple (Cía S en C)',
          code: 'IUIY',
        },
        {
          label: 'Sociedad de Economía Mixta',
          code: 'KC51',
        },
        {
          label: 'Sociedad por Acciones Simplificada (SAS)',
          code: 'KYVO',
        },
        {
          label: 'Sociedad en comandita por acciones (Cía SCA)',
          code: 'N1TE',
        },
        {
          label: 'Sociedades Agrarias de Transformación (SAT)',
          code: 'SJB8',
        },
        {
          label: 'Sociedad de Responsabilidad Limitada (Ltda)',
          code: 'WX45',
        },
      ],
    },
  ],
  CR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Institución Autónoma',
          code: '4SFS',
        },
        {
          label: 'Entidad Creada por Ley Especial',
          code: '4Y4D',
        },
        {
          label: 'Organismo Internacional',
          code: '72BT',
        },
        {
          label:
            'Junta de Educación y Junta Administrativa de Patronatos Escolares',
          code: 'DUXT',
        },
        {
          label: 'Sociedad Profesional',
          code: 'E26K',
        },
        {
          label: 'Municipalidad',
          code: 'EFE3',
        },
        {
          label: 'Fundación',
          code: 'G5RR',
        },
        {
          label: 'Sociedad civil (AC)',
          code: 'H2BW',
        },
        {
          label: 'Temporalidad de la Iglesia',
          code: 'HSX9',
        },
        {
          label: 'Sociedad de usuarios de aguas',
          code: 'KZMF',
        },
        {
          label: 'Sucesión Indivisa',
          code: 'LQVG',
        },
        {
          label: 'Condominio',
          code: 'M0X8',
        },
        {
          label: 'Asociación civil, Asociación deportiva, Federación',
          code: 'O4X3',
        },
        {
          label:
            'Sociedad de Responsabilidad Limitada o Sociedad Limitada (SL)',
          code: 'P864',
        },
        {
          label: 'Mutual de Ahorro y Préstamo',
          code: 'Q4J1',
        },
        {
          label: 'Sociedad colectiva (SC)',
          code: 'SBQF',
        },
        {
          label: 'Sindicato y su Federación',
          code: 'SMWM',
        },
        {
          label: 'Fideicomiso de Partidos Políticos',
          code: 'YFZL',
        },
        {
          label: 'Sociedad Anónima (SA)',
          code: 'YOIW',
        },
        {
          label: 'Sociedad en comandita (S en C)',
          code: 'YP63',
        },
        {
          label: 'Empresa Individual de Responsabilidad Limitada (EIRL)',
          code: 'YW6W',
        },
        {
          label: 'Asociación Cooperativa',
          code: 'YYZO',
        },
      ],
    },
  ],
  CW: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Stille vennootschap',
          code: '1SVL',
        },
        {
          label: 'Stichting',
          code: '1T06',
        },
        {
          label: 'Stichting Particulier Fonds',
          code: '2J66',
        },
        {
          label: 'Naamloze Vennootschap',
          code: '36AO',
        },
        {
          label: 'Eenmanszaak',
          code: '5OZV',
        },
        {
          label: 'Commanditaire vennootschap',
          code: '9E0Q',
        },
        {
          label: 'Coöperatie',
          code: 'ADF8',
        },
        {
          label: 'Rederij',
          code: 'IHD0',
        },
        {
          label: 'Openbare vennootschap',
          code: 'KM1T',
        },
        {
          label: 'Besloten Vennootschap',
          code: 'MZB1',
        },
        {
          label: 'Onderlinge waarborgmaatschappij',
          code: 'SS3A',
        },
        {
          label: 'Vereniging',
          code: 'WV3I',
        },
        {
          label: 'Trust',
          code: 'ZKTC',
        },
      ],
    },
  ],
  CY: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Alli nomiki morfi',
          code: '1MBR',
        },
        {
          label: 'Synetairismos',
          code: '233U',
        },
        {
          label: 'Evropaiki Dimosia Etaireia Periorismenis Efthynis',
          code: '8BL9',
        },
        {
          label: 'Trust',
          code: '8VZ0',
        },
        {
          label: 'Dimosia Etaireia Periorismenis Efthynis me metoches',
          code: 'BCF5',
        },
        {
          label: 'Emporiki Eponymia',
          code: 'FV0K',
        },
        {
          label: 'Somateia kai Idrymata',
          code: 'GLCH',
        },
        {
          label: 'Allodapi Etaireia',
          code: 'HIL6',
        },
        {
          label: 'Aftotelos ergazomenos',
          code: 'I8RE',
        },
        {
          label:
            'Etaireia Periorismenis Efthynis me engyisi, kai pou echei metochiko kefalaio',
          code: 'INTY',
        },
        {
          label: 'Idiotiki Etaireia Periorismenis Efthynis me metoches',
          code: 'K9L6',
        },
        {
          label: 'Dimosia Etaireia',
          code: 'MYKG',
        },
        {
          label: 'Tameia Pronoias',
          code: 'P12B',
        },
        {
          label: 'Ευρωπαϊκός Όμιλος Οικονομικού Σκοπού (ΕΟΟΣ)',
          code: 'P5JT',
        },
        {
          label: 'Idiotiki Etaireia',
          code: 'RCPI',
        },
        {
          label: 'Ontotites pou diepontai apo to dimosio dikaio',
          code: 'T1JS',
        },
        {
          label: 'Synergatika Pistotika Idrymata',
          code: 'U24X',
        },
        {
          label:
            'Etaireia Periorismenis Efthynis me engyisi, choris metochiko kefalaio',
          code: 'W86L',
        },
      ],
    },
  ],
  CZ: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Svépomocné zemědělské družstvo',
          code: '1JK6',
        },
        {
          label: 'Podnik nebo hospodářské zařízení politické strany',
          code: '2DK3',
        },
        {
          label: 'Česká konsolidační agentura',
          code: '2N9X',
        },
        {
          label: 'Evropské seskupení pro územní spolupráci',
          code: '3BX3',
        },
        {
          label: 'Nadace',
          code: '3G3D',
        },
        {
          label: 'Státní fond ze zákona',
          code: '3RMA',
        },
        {
          label: 'Zájmová organizace družstev',
          code: '4A26',
        },
        {
          label: 'Regionální rada regionu soudržnosti',
          code: '4OTK',
        },
        {
          label: 'Garanční fond obchodníků s cennými papíry',
          code: '4UB2',
        },
        {
          label: 'Stálý rozhodčí soud',
          code: '4YAK',
        },
        {
          label: 'Pobočná mezinárodní organizace zaměstnavatelů',
          code: '5AP3',
        },
        {
          label: 'Veřejná obchodní společnost',
          code: '5KU5',
        },
        {
          label: 'Akciová společnost',
          code: '6CQN',
        },
        {
          label: 'Svěřenský fond',
          code: '6D9L',
        },
        {
          label: 'Státní podnik',
          code: '6FAI',
        },
        {
          label: 'Organizační jednotka občanského sdružení',
          code: '6OMW',
        },
        {
          label: 'Společnost komanditní na akcie',
          code: '747U',
        },
        {
          label: 'Státní příspěvková organizace ostatní',
          code: '74W6',
        },
        {
          label: 'Zájmové sdružení',
          code: '7OZQ',
        },
        {
          label: 'Státní hospodářská organizace řízená okresním úřadem',
          code: '84J8',
        },
        {
          label: 'Spotřební družstvo',
          code: '8K5T',
        },
        {
          label: 'Česká tisková kancelář',
          code: '917C',
        },
        {
          label: 'Podnikající fyzická osoba tuzemská',
          code: '95G8',
        },
        {
          label: 'Podnik nebo hospodářské zařízení sdružení',
          code: '971Y',
        },
        {
          label: 'Společnost s ručením omezeným',
          code: '9HLU',
        },
        {
          label: 'Družstvo',
          code: '9RVC',
        },
        {
          label: 'Občanské sdružení',
          code: '9XDB',
        },
        {
          label:
            'Státní fond ze zákona nezapisující se do obchodního rejstříku',
          code: 'AM3T',
        },
        {
          label: 'Správa železniční dopravní cesty, státní organizace',
          code: 'APEN',
        },
        {
          label: 'Obecně prospěšná společnost',
          code: 'BL4B',
        },
        {
          label: 'Všeobecná zdravotní pojišťovna',
          code: 'C4Q2',
        },
        {
          label: 'Samostatná drobná provozovna',
          code: 'C5FE',
        },
        {
          label: 'Výrobní družstvo',
          code: 'C7GZ',
        },
        {
          label: 'Spolek',
          code: 'CATU',
        },
        {
          label: 'Společnost komanditní',
          code: 'CD28',
        },
        {
          label: 'Podnik se zahraniční majetkovou účastí',
          code: 'CHJR',
        },
        {
          label: 'Veřejná výzkumná instituce',
          code: 'CIO8',
        },
        {
          label: 'Mezinárodní organizace zaměstnavatelů',
          code: 'CUKQ',
        },
        {
          label: 'Zahraniční fyzická osoba',
          code: 'CZUA',
        },
        {
          label: 'Školská právnická osoba',
          code: 'D1VK',
        },
        {
          label: 'Zahraniční spolek',
          code: 'D35X',
        },
        {
          label: 'Svazy církví a náboženských společností',
          code: 'ET6Z',
        },
        {
          label: 'Banka-státní peněžní ústav',
          code: 'FIPS',
        },
        {
          label: 'Církve a náboženské společnosti',
          code: 'FY1B',
        },
        {
          label: 'Pobočný spolek',
          code: 'G2I3',
        },
        {
          label: 'Veřejné neziskové ústavní zdravotnické zařízení',
          code: 'H4XQ',
        },
        {
          label: 'Státní příspěvková organizace',
          code: 'H6WW',
        },
        {
          label: 'Komora',
          code: 'HQPK',
        },
        {
          label: 'Vnitřní organizační jednotka organizační složky státu',
          code: 'HS5W',
        },
        {
          label: 'Podnik zahraničního obchodu',
          code: 'HSNC',
        },
        {
          label: 'Organizační složka zahraničního nadačního fondu',
          code: 'HY6K',
        },
        {
          label: 'Sdružení mezinárodního obchodu',
          code: 'I32F',
        },
        {
          label:
            'Organizační jednotka zvláštní organizace pro zastoupení českých zájmů v mezinárodních nevládních organizacích',
          code: 'I68R',
        },
        {
          label: 'Společenství vlastníků jednotek',
          code: 'IQ9O',
        },
        {
          label: 'Obec',
          code: 'J8PB',
        },
        {
          label: 'Evidované církevní právnické osoby',
          code: 'JCAD',
        },
        {
          label: 'Zahraniční pobočný spolek',
          code: 'JJYB',
        },
        {
          label: 'Státní banka československá',
          code: 'KM6Z',
        },
        {
          label: 'Honební společenstvo',
          code: 'LEDI',
        },
        {
          label: 'Československé státní dráhy-státní organizace',
          code: 'LJ92',
        },
        {
          label: 'Organizační složka státu',
          code: 'LJL0',
        },
        {
          label: 'Mezinárodní odborová organizace',
          code: 'LPCQ',
        },
        {
          label: 'Dobrovolný svazek obcí',
          code: 'MAVU',
        },
        {
          label: 'Příspěvková organizace zřízená územním samosprávným celkem',
          code: 'MBUU',
        },
        {
          label: 'Veřejnoprávní instituce',
          code: 'NI3I',
        },
        {
          label: 'Společný podnik',
          code: 'NJ87',
        },
        {
          label: 'Česká národní banka',
          code: 'NPH3',
        },
        {
          label: 'Komoditní burza',
          code: 'NQHQ',
        },
        {
          label: 'Samosprávná stavovská organizace',
          code: 'O9PW',
        },
        {
          label: 'Vysoká škola',
          code: 'OVKW',
        },
        {
          label: 'Pobočná odborová organizace a organizace zaměstnavatelů',
          code: 'P3YF',
        },
        {
          label: 'Městská část, městský obvod',
          code: 'PFE5',
        },
        {
          label: 'Mezinárodní nevládní organizace',
          code: 'PQJE',
        },
        {
          label: 'Odborová organizace a organizace zaměstnavatelů',
          code: 'Q25I',
        },
        {
          label: 'Kraj',
          code: 'QIEL',
        },
        {
          label: 'Ústav',
          code: 'QJ0F',
        },
        {
          label: 'Rada pro veřejný dohled nad auditem',
          code: 'QJWK',
        },
        {
          label: 'Zdravotní pojišťovna',
          code: 'QQ49',
        },
        {
          label: 'Nadační fond',
          code: 'QS6A',
        },
        {
          label: 'Organizační jednotka mezinárodní nevládní organizace',
          code: 'R2XE',
        },
        {
          label: 'Evropské hospodářské zájmové sdružení (EHZS)',
          code: 'RBGQ',
        },
        {
          label: 'Družstevní podnik',
          code: 'RHFQ',
        },
        {
          label: 'Obecní podnik',
          code: 'RP0E',
        },
        {
          label: 'Podílový, penzijní fond',
          code: 'SNWJ',
        },
        {
          label: 'Evropská družstevní společnost (SCE)',
          code: 'T1FT',
        },
        {
          label: 'Bytové družstvo',
          code: 'T3Q1',
        },
        {
          label: 'Odštěpný závod zahraniční právnické osoby',
          code: 'TNBA',
        },
        {
          label: 'Odštěpný závod zahraniční fyzické osoby',
          code: 'U95A',
        },
        {
          label: 'Národní podnik',
          code: 'UFDA',
        },
        {
          label: 'Společná zájmová organizace družstev',
          code: 'V6YH',
        },
        {
          label: 'Zájmové sdružení právnických osob',
          code: 'VIE3',
        },
        {
          label: 'Organizační složka zahraniční nadace',
          code: 'VQU7',
        },
        {
          label: 'Družstevní podnik zemědělský',
          code: 'WJ30',
        },
        {
          label:
            'Zvláštní organizace pro zastoupení českých zájmů v mezinárodních nevládních organizacích',
          code: 'WL83',
        },
        {
          label: 'Pobočná mezinárodní odborová organizace',
          code: 'WO0T',
        },
        {
          label: 'Odštěpný závod',
          code: 'WUFK',
        },
        {
          label: 'Účelová zahraničně obchodní organizace',
          code: 'WVZF',
        },
        {
          label:
            'Právnikcá osoba zřízená zvláštním zákonem zapisovaná do veřejného rejstříku',
          code: 'XG70',
        },
        {
          label: 'Politická strana, politické hnutí',
          code: 'XRK7',
        },
        {
          label: 'Společný podnik',
          code: 'Y18E',
        },
        {
          label: 'Zemědělské družstvo',
          code: 'Z3BF',
        },
        {
          label: 'Evropská společnost (SE)',
          code: 'ZQO8',
        },
        {
          label: 'Jiné družstvo',
          code: 'ZU32',
        },
      ],
    },
  ],
  DE: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Versicherungsverein auf Gegenseitigkeit (VVaG)',
          code: '13AV',
        },
        {
          label: 'Gesellschaft mit beschränkter Haftung (GmbH)',
          code: '2HBR',
        },
        {
          label:
            'Partnerschaftsgesellschaft mit beschränkter Berufshaftung (PartG mbB)',
          code: '2YZO',
        },
        {
          label: 'Offene Handelsgesellschaft (OHG mbH)',
          code: '40DB',
        },
        {
          label: 'Unternehmergesellschaft (UG)',
          code: '63KS',
        },
        {
          label: 'Aktiengesellschaft (AG)',
          code: '6QQB',
        },
        {
          label: 'Nicht eingetragener Verein',
          code: '79H0',
        },
        {
          label: 'Wirtschaftlicher Verein',
          code: '7J3S',
        },
        {
          label: 'Partnerschaftsgesellschaft (PartG)',
          code: '8CM0',
        },
        {
          label: 'Kommanditgesellschaft (GmbH & Co KG)',
          code: '8Z6G',
        },
        {
          label: 'Landesinnungsverband',
          code: '9JGX',
        },
        {
          label: 'REIT-Aktiengesellschaft (REIT-AG)',
          code: 'AMKW',
        },
        {
          label: 'Eingetragene Genossenschaft (eG)',
          code: 'AZFE',
        },
        {
          label: 'Verein alten Rechts',
          code: 'D40E',
        },
        {
          label: 'Gesellschaft bürgerlichen Rechts (GbR)',
          code: 'FR3V',
        },
        {
          label: 'Investmentaktiengesellschaft (InvAG)',
          code: 'JMVF',
        },
        {
          label: 'Europäische wirtschaftliche Interessenvereinigung (EWIV)',
          code: 'JNDX',
        },
        {
          label:
            'Einzelunternehmen, eingetragener Kaufmann, eingetragene Kauffrau (eK)',
          code: 'OL20',
        },
        {
          label: 'Eingetragener Verein (eV)',
          code: 'QZ3L',
        },
        {
          label: 'Gemeinnützige Gesellschaft mit beschränkter Haftung (gGmbH)',
          code: 'SCE1',
        },
        {
          label: 'Europäische Aktiengesellschaft (SE)',
          code: 'SGST',
        },
        {
          label: 'Körperschaft des öffentlichen Rechts',
          code: 'SQKS',
        },
        {
          label: 'Europäische Genossenschaft (SCE)',
          code: 'SUA1',
        },
        {
          label: 'Kommanditgesellschaft auf Aktien (GmbH & Co KGaA)',
          code: 'T0YJ',
        },
        {
          label: 'Genossenschaft (eG)',
          code: 'US8E',
        },
        {
          label: 'Stiftung des privaten Rechts',
          code: 'V2YH',
        },
        {
          label: 'Anstalt des öffentlichen Rechts',
          code: 'XLWA',
        },
        {
          label: 'Gemeinnützige Aktiengesellschaft (gAG)',
          code: 'YA01',
        },
        {
          label: 'Kirchliche Stiftung des öffentlichen Rechts',
          code: 'YJ4C',
        },
      ],
    },
  ],
  DK: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Fond',
          code: '1MWR',
        },
        {
          label: 'Region',
          code: '37UT',
        },
        {
          label: 'Forening',
          code: '40R4',
        },
        {
          label: 'Fonde og andre selvejende institutioner',
          code: '599X',
        },
        {
          label: 'Iværksætterselskab (IVS)',
          code: '5QS7',
        },
        {
          label: 'Interessentskab (I/S)',
          code: '7WRN',
        },
        {
          label: 'Partnerselskab (P/S)',
          code: '9KSX',
        },
        {
          label: 'Frivillig forening',
          code: 'D4PU',
        },
        {
          label: 'Særlig Finansiel virksomhed',
          code: 'F7JY',
        },
        {
          label: 'Enkeltmandsvirksomhed',
          code: 'FUKI',
        },
        {
          label: 'Europæisk andelsselskab (SCE-selskab)',
          code: 'FW7S',
        },
        {
          label: 'Primærkommune',
          code: 'GFXN',
        },
        {
          label: 'Selskab med begrænset ansvar (SMBA)',
          code: 'GULL',
        },
        {
          label: 'Anpartsselskab (ApS)',
          code: 'H8VP',
        },
        {
          label: 'Medarbejderinvesteringsselskab',
          code: 'LWOD',
        },
        {
          label: 'Kommanditselskab (K/S)',
          code: 'NUL8',
        },
        {
          label: 'Erhvervsdrivende fond',
          code: 'PIOI',
        },
        {
          label: 'Andelsselskab',
          code: 'PMJW',
        },
        {
          label: 'Andelsselskab med begrænset ansvar (AMBA)',
          code: 'PZ6Y',
        },
        {
          label: 'Europæisk økonomisk firmagruppe (EØFG)',
          code: 'TB2C',
        },
        {
          label: 'Europæisk selskab (SE-selskab)',
          code: 'UA1O',
        },
        {
          label: 'Forening med begrænset ansvar (FMBA)',
          code: 'WU7R',
        },
        {
          label: 'Aktieselskab (A/S)',
          code: 'ZRPO',
        },
      ],
    },
  ],
  DO: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sociedad Anónima (S A)',
          code: '3ZBR',
        },
        {
          label: 'Sociedad en Comandita Simple (S en C)',
          code: '8WQ9',
        },
        {
          label: 'Sociedad en Participación',
          code: 'B35X',
        },
        {
          label: 'Sociedad Anónima Simplificada (SAS)',
          code: 'DQAU',
        },
        {
          label: 'Empresa Individual de Responsabilidad Limitada  (EIRL)',
          code: 'K4CD',
        },
        {
          label: 'Sociedad en Nombre Colectivo',
          code: 'LSWY',
        },
        {
          label: 'Sociedad de Responsaibilidad Limitada (S R L)',
          code: 'SWO8',
        },
        {
          label: 'Sociedad en Comandita por Acciones',
          code: 'T4LC',
        },
      ],
    },
  ],
  EC: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Compañía en nombre colectivo (CNCOL)',
          code: '6TPE',
        },
        {
          label: 'Compañía en Comandita Simple y dividida por acciones (CCSDA)',
          code: 'FKVG',
        },
        {
          label: 'Compañía Anónima (SA)',
          code: 'J3T2',
        },
        {
          label: 'Compañía de Economía Mixta (CEMIX)',
          code: 'N9QH',
        },
        {
          label: 'Compañía de Responsabilidad Limitada (CIA LTDA)',
          code: 'XOAD',
        },
      ],
    },
  ],
  EE: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sihtasutus (SA)',
          code: '1NKP',
        },
        {
          label: 'Füüsilisest isikust ettevõtja (FIE)',
          code: '3UPJ',
        },
        {
          label: 'Hooneühistu (HÜ)',
          code: '6I0P',
        },
        {
          label: 'Euroopa äriühing (SE)',
          code: '752Q',
        },
        {
          label: 'Korteriühistu (KÜ)',
          code: '8ZQE',
        },
        {
          label: 'Aktsiaselts (AS)',
          code: '9LJA',
        },
        {
          label: 'Usuline ühendus',
          code: 'CKOS',
        },
        {
          label: 'Erakond',
          code: 'GA3A',
        },
        {
          label: 'Täisühing (TÜ)',
          code: 'I1UP',
        },
        {
          label: 'Ametiühing (AÜ)',
          code: 'J34T',
        },
        {
          label: 'Osaühing (OÜ)',
          code: 'JC0Y',
        },
        {
          label: 'Euroopa majandushuviühing (EMHÜ)',
          code: 'LA47',
        },
        {
          label: 'Usaldusühing (UÜ)',
          code: 'LVEQ',
        },
        {
          label: 'Loomeliit',
          code: 'PMOF',
        },
        {
          label: 'Mittetulundusühing (MTÜ)',
          code: 'PRTB',
        },
        {
          label: 'Euroopa ühistu (SCE)',
          code: 'RXTT',
        },
        {
          label: 'Tulundusühistu (TÜH)',
          code: 'VSEV',
        },
      ],
    },
  ],
  ES: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sociedad Limitada Laboral (SLL)',
          code: '1G29',
        },
        {
          label: 'Sociedad Cooperativa (SCoop)',
          code: '1QU8',
        },
        {
          label: 'Empresario Individual',
          code: '1SL4',
        },
        {
          label: 'Sociedad Colectiva Profesional (SRCP)',
          code: '1ZHJ',
        },
        {
          label: 'Caja de Ahorros',
          code: '4S57',
        },
        {
          label: 'Sociedad Agraría de Trasformación (SAT)',
          code: '4SJR',
        },
        {
          label: 'Sociedad Anonima (SA)',
          code: '5RDO',
        },
        {
          label: 'Sociedades de Inversion Inmobiliaria (SII)',
          code: '7U8O',
        },
        {
          label: 'Sociedad de Inversión de Capital Variable (SICAV)',
          code: '8EHB',
        },
        {
          label: 'Sociedad Limitada de nueva empresa (SLNE)',
          code: '956I',
        },
        {
          label: 'Sociedad Anonima Europea (SE)',
          code: '9FPZ',
        },
        {
          label: 'Asociación',
          code: 'A0J6',
        },
        {
          label: 'Mutualidad de Prevision Social (MPS)',
          code: 'A97B',
        },
        {
          label: 'Sociedad Anonima Profesional (SAP)',
          code: 'AJ9U',
        },
        {
          label: 'Sociedad Civil Profesional',
          code: 'ARDP',
        },
        {
          label: 'Sociedad de Capital-Riesgo (SCR)',
          code: 'AXS5',
        },
        {
          label: 'Sociedad Cooperativa Profesional (SCoopP)',
          code: 'B0V5',
        },
        {
          label: 'Agrupacion Europea de Interes Economico (AEIE)',
          code: 'BI3B',
        },
        {
          label: 'Mutua',
          code: 'CUIH',
        },
        {
          label: 'Sociedad de Capital-Riesgo-Pyme (SCR-Pyme)',
          code: 'DDES',
        },
        {
          label: 'Sociedad de Responsabilidad Limitada (SRL)',
          code: 'DP3Q',
        },
        {
          label: 'Fondo de Inversion en activos del Mercado Monetario (FIAMM)',
          code: 'FCJF',
        },
        {
          label: 'Fondo de Pensiones (FP)',
          code: 'FH4R',
        },
        {
          label: 'Sociedad Comanditaria por Acciones (SCom p A)',
          code: 'GJL1',
        },
        {
          label: 'Sociedad Comanditaria por Acciones Profesional (SCom p AP)',
          code: 'GJTL',
        },
        {
          label: 'Sociedad Cooperativa Europea (SCE)',
          code: 'HRQA',
        },
        {
          label: 'Agrupacion de Interes Economico (AIE)',
          code: 'I2WU',
        },
        {
          label: 'Sociedad de Garantia Reciproca (SGR)',
          code: 'IAS6',
        },
        {
          label: 'Emprendedor de Responsabilidad Limitada (ERL)',
          code: 'IT6N',
        },
        {
          label: 'Sociedad Limitada Profesional (SLP)',
          code: 'JB2M',
        },
        {
          label: 'Fondo de Capital-Riesgo (FCR)',
          code: 'JTV5',
        },
        {
          label: 'Institucion de Inversion Colectiva (IIC)',
          code: 'K0RI',
        },
        {
          label: 'Sociedad Civil Profesional',
          code: 'K6L9',
        },
        {
          label: 'Fundación',
          code: 'MDOL',
        },
        {
          label: 'Fondos de Inversion Inmobiliaria (FII)',
          code: 'QMUM',
        },
        {
          label: 'Sociedad Anonima Laboral (SAL)',
          code: 'R2L8',
        },
        {
          label: 'Sociedad Limitada Unipersonal (SLU)',
          code: 'R6UT',
        },
        {
          label: 'Fondo de Inversion (FI)',
          code: 'S0Z5',
        },
        {
          label: 'Congregación',
          code: 'S6MS',
        },
        {
          label: 'Sociedad Colectiva (SRC)',
          code: 'S6X7',
        },
        {
          label: 'Cooperativa de Credito',
          code: 'SS0L',
        },
        {
          label: 'Sociedad Comanditaria Simple (S Com)',
          code: 'TDD5',
        },
        {
          label: 'Corporación de Derecho Público',
          code: 'TUHS',
        },
        {
          label: 'Fondo de Inversion Mobiliaria (FIM)',
          code: 'UJ35',
        },
        {
          label: 'Sociedad Comanditaria Simple Profesional (SComP)',
          code: 'VFIU',
        },
        {
          label: 'Fondo de Capital-Riesgo-Pyme (FCR-Pyme)',
          code: 'XYGP',
        },
      ],
    },
  ],
  FI: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Muu yhtiö',
          code: '1AFG',
        },
        {
          label: 'Annat bolag',
          code: '1AFG',
        },
        {
          label: 'Metsänhoitoyhdistys',
          code: '1YIR',
        },
        {
          label: 'Skogsvårdsförening',
          code: '1YIR',
        },
        {
          label: 'Kunta',
          code: '2RK5',
        },
        {
          label: 'Kommun',
          code: '2RK5',
        },
        {
          label: 'Elinkeinoyhtymä',
          code: '37GR',
        },
        {
          label: 'Näringssammanslutning',
          code: '37GR',
        },
        {
          label: 'Keskinäinen vakuutusyhtiö',
          code: '4H61',
        },
        {
          label: 'Ömsesidigt försäkringsbolag',
          code: '4H61',
        },
        {
          label: 'Eläkesäätiö',
          code: '5WI2',
        },
        {
          label: 'Pensionsstiftelse',
          code: '5WI2',
        },
        {
          label: 'Paikallisyhteisö',
          code: '6PEQ',
        },
        {
          label: 'Lokalsamfund',
          code: '6PEQ',
        },
        {
          label: 'Muu yhdistys',
          code: '760X',
        },
        {
          label: 'Annan förening',
          code: '760X',
        },
        {
          label: 'Sivuliike',
          code: '883O',
        },
        {
          label: 'Filial',
          code: '883O',
        },
        {
          label: 'Erityislainsäädäntöön perustuva yhdistys',
          code: '8HGS',
        },
        {
          label: 'Förening som grundar sig på speciallagstiftning',
          code: '8HGS',
        },
        {
          label: 'Keskinäinen kiinteistöosakeyhtiö',
          code: '8WJ7',
        },
        {
          label: 'Ömsesidigt fastighetsaktiebolag',
          code: '8WJ7',
        },
        {
          label: 'Vakuutuskassa',
          code: '97PB',
        },
        {
          label: 'Försäkringskassa',
          code: '97PB',
        },
        {
          label: 'Vakuutusyhdistys',
          code: '9AUC',
        },
        {
          label: 'Försäkringsförening',
          code: '9AUC',
        },
        {
          label: 'Muu kiinteistöosakeyhtiö',
          code: 'AUPV',
        },
        {
          label: 'Annat fastighetsaktiebolag',
          code: 'AUPV',
        },
        {
          label: 'Valtio ja sen laitokset',
          code: 'BKQO',
        },
        {
          label: 'Staten och dess inrättningar',
          code: 'BKQO',
        },
        {
          label: 'Hypoteekkiyhdistys',
          code: 'BKVI',
        },
        {
          label: 'Hypoteksförening',
          code: 'BKVI',
        },
        {
          label: 'Laivanisännistöyhtiö',
          code: 'BQ19',
        },
        {
          label: 'Rederibolag',
          code: 'BQ19',
        },
        {
          label: 'Eurooppalainen taloudellinen etuyhtymä sivutoimipaikka',
          code: 'C51L',
        },
        {
          label: 'Filial för en europeisk ekonomisk intressegrupp',
          code: 'C51L',
        },
        {
          label: 'Vakuutusosakeyhtiö',
          code: 'DAFV',
        },
        {
          label: 'Försäkringsaktiebolag',
          code: 'DAFV',
        },
        {
          label: 'Osakeyhtiö (oy)',
          code: 'DKUW',
        },
        {
          label: 'Aktiebolag (ab)',
          code: 'DKUW',
        },
        {
          label: 'Ulkomainen yhteisö',
          code: 'DL9Z',
        },
        {
          label: 'Utländskt samfund',
          code: 'DL9Z',
        },
        {
          label: 'Työeläkekassa',
          code: 'DMT8',
        },
        {
          label: 'Arbetspensionskassa',
          code: 'DMT8',
        },
        {
          label: 'Taloudellinen yhdistys',
          code: 'EDN7',
        },
        {
          label: 'Ekonomisk förening',
          code: 'EDN7',
        },
        {
          label: 'Ylioppilaskunta tai osakunta',
          code: 'EDZP',
        },
        {
          label: 'Studentkår eller studentnation',
          code: 'EDZP',
        },
        {
          label: 'Osuuskunta (osk)',
          code: 'EE90',
        },
        {
          label: 'Andelslag (anl)',
          code: 'EE90',
        },
        {
          label: 'Muu taloudellinen yhdistys',
          code: 'EMC8',
        },
        {
          label: 'Annan ekonomisk förening',
          code: 'EMC8',
        },
        {
          label: 'Julkinen keskinäinen vakuutusyhtiö',
          code: 'HBR3',
        },
        {
          label: 'Publikt ömsesidigt försäkringsaktiebolag',
          code: 'HBR3',
        },
        {
          label: 'Kommandiittiyhtiö (ky)',
          code: 'HEOB',
        },
        {
          label: 'Kommanditbolag (kb)',
          code: 'HEOB',
        },
        {
          label: 'Eurooppalainen taloudellinen etuyhtymä (ETEY)',
          code: 'HNWB',
        },
        {
          label: 'Europeisk ekonomisk intresseguppering (EEIG)',
          code: 'HNWB',
        },
        {
          label: 'Erillishallinnollinen valtion laitos',
          code: 'HTT9',
        },
        {
          label: 'Statlig inrättning med separat förvaltning',
          code: 'HTT9',
        },
        {
          label: 'Asukashallintoalue',
          code: 'IX7U',
        },
        {
          label: 'Boendeförvaltningsområde',
          code: 'IX7U',
        },
        {
          label: 'Evankelis-luterilainen kirkko',
          code: 'IYF9',
        },
        {
          label: 'Evluth kyrkan',
          code: 'IYF9',
        },
        {
          label: 'Keskinäinen vahinkovakuutusyhdistys',
          code: 'JI3A',
        },
        {
          label: 'Ömsesidigt skadeförsäkringsförening',
          code: 'JI3A',
        },
        {
          label: 'Yksityinen elinkeinonharjoittaja',
          code: 'K09E',
        },
        {
          label: 'Enskild näringsidkare',
          code: 'K09E',
        },
        {
          label: 'Muut oikeushenkilöt',
          code: 'K2G8',
        },
        {
          label: 'Övriga juridiska personer',
          code: 'K2G8',
        },
        {
          label: 'Julkinen osakeyhtiö (oyj)',
          code: 'K6VE',
        },
        {
          label: 'Publikt aktiebolag (abp)',
          code: 'K6VE',
        },
        {
          label: 'Julkinen vakuutusosakeyhtiö',
          code: 'KHI5',
        },
        {
          label: 'Publikt försäkringsaktiebolag',
          code: 'KHI5',
        },
        {
          label: 'Paliskunta',
          code: 'L7XJ',
        },
        {
          label: 'Renbeteslag',
          code: 'L7XJ',
        },
        {
          label: 'Muu yhteisvastuullinen pidätysvelvollinen',
          code: 'LBQS',
        },
        {
          label: 'Annan solidariskt ansvarig innehållningspliktig',
          code: 'LBQS',
        },
        {
          label: 'Asumisoikeusyhdistys',
          code: 'MBPY',
        },
        {
          label: 'Boenderättsförening',
          code: 'MBPY',
        },
        {
          label: 'Muu julkisoikeudellinen oikeushenkilö',
          code: 'MRW9',
        },
        {
          label: 'Annan offentligrättslig juridisk person',
          code: 'MRW9',
        },
        {
          label: 'Rekisteröity uskonnollinen yhdyskunta',
          code: 'N3LC',
        },
        {
          label: 'Registrerat religiöst samfund',
          code: 'N3LC',
        },
        {
          label: 'Muu säätiö',
          code: 'NV7C',
        },
        {
          label: 'Annan stiftelse',
          code: 'NV7C',
        },
        {
          label: 'Valtion liikelaitos',
          code: 'OXLO',
        },
        {
          label: 'Statlig affärsinrättning',
          code: 'OXLO',
        },
        {
          label: 'Avoin yhtiö',
          code: 'PPMX',
        },
        {
          label: 'Öppet bolag',
          code: 'PPMX',
        },
        {
          label: 'Kauppakamari',
          code: 'R39F',
        },
        {
          label: 'Handelskammare',
          code: 'R39F',
        },
        {
          label: 'Eurooppaosuuskunnan kiinteä toimipaikka',
          code: 'R5ZC',
        },
        {
          label: 'Fast driftställe för europeisk kooperativ förening',
          code: 'R5ZC',
        },
        {
          label: 'Verotusyhtymä',
          code: 'R6UB',
        },
        {
          label: 'Beskattningssammanslutning',
          code: 'R6UB',
        },
        {
          label: 'Työttömyyskassa',
          code: 'SDPE',
        },
        {
          label: 'Arbetslöshetskassa',
          code: 'SDPE',
        },
        {
          label: 'Osuuspankki',
          code: 'SJL9',
        },
        {
          label: 'Andelsbank',
          code: 'SJL9',
        },
        {
          label: 'Yhteisetuudet',
          code: 'SKGX',
        },
        {
          label: 'Samfällda förmåner',
          code: 'SKGX',
        },
        {
          label: 'Ahvenanmaan maakunta ja sen virastot',
          code: 'SQS1',
        },
        {
          label: 'Lanskapet Åland och dess ämbetsverk',
          code: 'SQS1',
        },
        {
          label: 'Muu verotuksen yksikkö',
          code: 'SUHV',
        },
        {
          label: 'Annan enhet inom beskattningen',
          code: 'SUHV',
        },
        {
          label: 'Kuntainliiton liikelaitos',
          code: 'T31U',
        },
        {
          label: 'Kommunförbundets affärsinrättning',
          code: 'T31U',
        },
        {
          label: 'Yhteismetsä',
          code: 'T3K4',
        },
        {
          label: 'Samfälld skog',
          code: 'T3K4',
        },
        {
          label: 'Kunnallinen liikelaitos',
          code: 'TZ1U',
        },
        {
          label: 'Kommunal affärsinrättning',
          code: 'TZ1U',
        },
        {
          label: 'Eurooppaosuuskunta (SCE)',
          code: 'UAV1',
        },
        {
          label: 'Europaandelslag (SCE)',
          code: 'UAV1',
        },
        {
          label: 'Konkurssipesä',
          code: 'UMF0',
        },
        {
          label: 'Konkursbo',
          code: 'UMF0',
        },
        {
          label: 'Aatteellinen yhdistys',
          code: 'UXEW',
        },
        {
          label: 'Ideell förening',
          code: 'UXEW',
        },
        {
          label: 'Asunto-osakeyhtiö',
          code: 'V0TJ',
        },
        {
          label: 'Bostadsaktiebolag',
          code: 'V0TJ',
        },
        {
          label: 'Ortodoksinen kirkko',
          code: 'V42B',
        },
        {
          label: 'Ortodoxa kyrkan',
          code: 'V42B',
        },
        {
          label: 'Eurooppaosuuspankki',
          code: 'VKKN',
        },
        {
          label: 'Europaandelsbank',
          code: 'VKKN',
        },
        {
          label: 'Kuntayhtymä',
          code: 'VOTI',
        },
        {
          label: 'Samkommun',
          code: 'VOTI',
        },
        {
          label: 'Ahvenanmaan liikelaitos',
          code: 'XGKW',
        },
        {
          label: 'Ålands affärsverk',
          code: 'XGKW',
        },
        {
          label: 'Eurooppayhtiö (SE)',
          code: 'XJH3',
        },
        {
          label: 'Europabolag (SE)',
          code: 'XJH3',
        },
        {
          label: 'Säätiö (sr)',
          code: 'YK5G',
        },
        {
          label: 'Stiftelse (sr)',
          code: 'YK5G',
        },
        {
          label: 'Säästöpankki',
          code: 'Z38E',
        },
        {
          label: 'Sparbank',
          code: 'Z38E',
        },
        {
          label: 'Kuolinpesä',
          code: 'ZMTL',
        },
        {
          label: 'Dödsbo',
          code: 'ZMTL',
        },
      ],
    },
  ],
  FR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Société civile (SC)',
          code: '1L24',
        },
        {
          label: 'Société Anonyme à directoire (SA)',
          code: '5VCF',
        },
        {
          label: 'Société par actions simplifiée (SAS)',
          code: '6CHY',
        },
        {
          label: 'Société en commandite par actions (SCA)',
          code: 'DEG4',
        },
        {
          label: 'Société en nom collectif (SNC)',
          code: 'H1U2',
        },
        {
          label: "Société Anonyme à conseil d'administration (SA)",
          code: 'K65D',
        },
        {
          label: 'Société par actions simplifiée unipersonnelle (SASU)',
          code: '6CHY',
        },
        {
          label: 'Société à responsabilité limitée (SARL)',
          code: 'V9QP',
        },
        {
          label: 'Entreprise unipersonnelle à responsabilité limitée (EURL)',
          code: 'V9QP',
        },
        {
          label: 'Société en commandite simple (SCS)',
          code: 'VD7Z',
        },
        {
          label: 'Société civile de placement collectif immobilier (SCPI)',
          code: 'YX4E',
        },
        {
          label: 'Entrepreneur individuel (EI)',
          code: 'ZY9X',
        },
        {
          label: 'SARL coopérative de production (SCOP)',
          code: 'V1Z5',
        },
      ],
    },
  ],
  GB: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Community Interest Company (CIC)',
          code: '17R0',
        },
        {
          label: 'Oversea Company',
          code: '1W62',
        },
        {
          label: 'United Kingdom Societas (UKS)',
          code: '468Q',
        },
        {
          label: 'Northern Ireland Company',
          code: '4A3J',
        },
        {
          label: 'Private Limited by Guarantee (Ltd)',
          code: '4GJI',
        },
        {
          label: 'Cwmni Cyfyngedig Cyhoeddus (CCC)',
          code: '55MA',
        },
        {
          label: 'Private Unlimited',
          code: '57V7',
        },
        {
          label: 'Other Type of Company',
          code: '5FRT',
        },
        {
          label: 'Private Unlimited with No Share Capital',
          code: '60IF',
        },
        {
          label: 'Charitable Incorporated Organisation',
          code: '7T8N',
        },
        {
          label: 'Co-operative Society (Co-op)',
          code: '7VVZ',
        },
        {
          label: 'Assurance Company',
          code: '8CF0',
        },
        {
          label: 'Royal Charter Company',
          code: '9B78',
        },
        {
          label: 'Unregistered Company',
          code: 'A2X8',
        },
        {
          label: 'Government Entity',
          code: 'AVYY',
        },
        {
          label: 'Public Limited Company (PLC)',
          code: 'B6ES',
        },
        {
          label: 'Cwmni Cyfyngedig Cyhoeddus (CCC)',
          code: 'B6ES',
        },
        {
          label: 'Friendly Society',
          code: 'BX6Y',
        },
        {
          label: 'Private Limited by Shares',
          code: 'C605',
        },
        {
          label: 'UK Establishment (BR)',
          code: 'CDOT',
        },
        {
          label: 'Building Society',
          code: 'E12O',
        },
        {
          label: 'Industrial and Provident Society',
          code: 'FVGZ',
        },
        {
          label: 'Private Limited by Guarantee with No Share Capital',
          code: 'G12F',
        },
        {
          label: 'Private Limited Company (Ltd)',
          code: 'H0PO',
        },
        {
          label: 'Cyfyngedig (cyf)',
          code: 'H0PO',
        },
        {
          label: 'European Public Limited-Liability Company (SE)',
          code: 'HHYT',
        },
        {
          label: 'Private Limited',
          code: 'HX6D',
        },
        {
          label: 'Limited Partnership (limited partnership)',
          code: 'ID30',
        },
        {
          label: 'Community Benefit Society (BenCom)',
          code: 'IYXU',
        },
        {
          label: 'Credit Union',
          code: 'JTCO',
        },
        {
          label: 'Other Company Type',
          code: 'NAUO',
        },
        {
          label: 'Scottish Partnership',
          code: 'NBTW',
        },
        {
          label: 'Unregistered Company',
          code: 'Q0M5',
        },
        {
          label: 'Scottish Charitable Incorporated Organisation',
          code: 'STX7',
        },
        {
          label: 'Cyfyngedig (cyf)',
          code: 'T61R',
        },
        {
          label: 'Protected Cell Company',
          code: 'TT2H',
        },
        {
          label: 'European Economic Interest Grouping (EEIG)',
          code: 'U6R9',
        },
        {
          label: 'Community Benefit Society (BenCom)',
          code: 'UTY8',
        },
        {
          label: 'Investment Company with Variable Capital',
          code: 'VV0W',
        },
        {
          label: 'United Kingdom Economic Interest Grouping (UKEIG)',
          code: 'VYF8',
        },
        {
          label: 'Credit Union',
          code: 'WBQU',
        },
        {
          label: 'Partneriaeth Atebolrwydd Cyfyngedig (PAC)',
          code: 'WJ0A',
        },
        {
          label: 'Registered Society',
          code: 'XLZV',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'Z0EY',
        },
        {
          label: 'Partneriaeth Atebolrwydd Cyfyngedig (PAC)',
          code: 'Z0EY',
        },
        {
          label: 'Old Public Company (Ltd)',
          code: 'ZQ6S',
        },
        {
          label: 'Co-operative Society (Co-op)',
          code: 'ZZGG',
        },
      ],
    },
  ],
  GG: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Limited Company (Ltd)',
          code: 'BVOE',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'UO3D',
        },
        {
          label: 'Company limited by guarantee',
          code: '1Q2B',
        },
        {
          label: 'Private company',
          code: 'NDC3',
        },
        {
          label: 'Public company',
          code: 'UY66',
        },
        {
          label: 'Incorporated cell (ICC)',
          code: '2AUN',
        },
        {
          label: 'Protected cell company (PCC)',
          code: '4C0J',
        },
        {
          label: 'Limited partnership without separate legal personality',
          code: '75XK',
        },
        {
          label: 'Foundation (Fdn)',
          code: '9XFK',
        },
        {
          label: 'Company limited by shares (Ltd)',
          code: 'E6J3',
        },
        {
          label: 'Limited partnership with separate legal personality',
          code: 'MCQP',
        },
        {
          label: 'Mixed liability company (ML)',
          code: 'NGR5',
        },
        {
          label: 'Company limited by guarantee (LBG)',
          code: 'OT3Y',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'Q49K',
        },
        {
          label: 'Incorporated cell company (ICC)',
          code: 'QJBA',
        },
        {
          label: 'Unlimited liability company',
          code: 'YVZD',
        },
      ],
    },
  ],
  GH: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sole Proprietorship',
          code: '4HD1',
        },
        {
          label: 'Private Limited By Share',
          code: '56NA',
        },
        {
          label: 'Private Unlimited By Share',
          code: 'I0X7',
        },
        {
          label: 'Private Limited By Guarantee',
          code: 'J5RC',
        },
        {
          label: 'Public Unlimited Company',
          code: 'PH5T',
        },
        {
          label: 'External Company',
          code: 'T69Z',
        },
        {
          label: 'Public Limited Company',
          code: 'VBJW',
        },
        {
          label: 'Public Limited By Guarantee',
          code: 'W9ZD',
        },
        {
          label: 'Partnership',
          code: 'WP5I',
        },
        {
          label: 'Subsidiary Business Name',
          code: 'XWPT',
        },
      ],
    },
  ],
  GI: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'European Economic Interest Groupings (EEIG)',
          code: '7U4R',
        },
        {
          label: 'Foundations',
          code: 'CB98',
        },
        {
          label: 'Limited Liability Partnerships (LLP)',
          code: 'GCCT',
        },
        {
          label: 'Societe Europeas (SE)',
          code: 'M4TI',
        },
        {
          label: 'Private Limited by Guarantee (Ltd)',
          code: 'QFLH',
        },
        {
          label: 'Private Trust Company (PTC)',
          code: 'TKPE',
        },
        {
          label: 'Public Limited Company  (PLC)',
          code: 'UUYB',
        },
        {
          label: 'Private Unlimited Company',
          code: 'VJRL',
        },
        {
          label: 'Private Limited Company (Ltd)',
          code: 'WBKH',
        },
        {
          label: 'Limited Partnerships',
          code: 'Y8CL',
        },
      ],
    },
  ],
  GR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Naftiki eteria (SC)',
          code: '3RHO',
        },
        {
          label: 'Eterórithmi Etería (EE)',
          code: '54AL',
        },
        {
          label: 'Εvropaiki Εtaireia (SE)',
          code: 'CQ5X',
        },
        {
          label: 'Etería Periorisménis Euthínis (EPE)',
          code: 'H6OR',
        },
        {
          label: 'Astiki (SP)',
          code: 'HKVP',
        },
        {
          label: 'Sinetairismos (COOP)',
          code: 'J3VJ',
        },
        {
          label: "Lipa' (Other)",
          code: 'JYKN',
        },
        {
          label: 'Nomika Prosopa Dimosiou Dikeou (PL)',
          code: 'L6IY',
        },
        {
          label: 'Afanis Eteria (SLP)',
          code: 'L8D6',
        },
        {
          label: 'Kinopraxia (JV)',
          code: 'MCWR',
        },
        {
          label: 'Idiotiki kefaleouhiki Eteria (IKE)',
          code: 'QCA0',
        },
        {
          label: 'Ευρωπαϊκός Όμιλος Οικονομικού Σκοπού (ΕΟΟΣ)',
          code: 'QNWW',
        },
        {
          label: 'Somatia - Idrimata (CLF)',
          code: 'QSYQ',
        },
        {
          label: 'Omórithmi Etería (OE)',
          code: 'TIZ2',
        },
        {
          label: 'Εterorrythmi kata metoches etaireia',
          code: 'UCU5',
        },
        {
          label: 'Simplioktisia (JSO)',
          code: 'VKSR',
        },
        {
          label: 'Anónimi Etería (SA)',
          code: 'W2NK',
        },
      ],
    },
  ],
  GT: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sociedad en Comandita por Acciones (Cía, SCA)',
          code: 'F0IC',
        },
        {
          label: 'Sociedad Colectiva',
          code: 'GY0L',
        },
        {
          label: 'Sociedad de Responsabilidad Limitada (Cía Ltda)',
          code: 'RK88',
        },
        {
          label: 'Sociedad en Comandita Simple (Cía S en C)',
          code: 'W9T7',
        },
        {
          label: 'Sociedad Anónima (SA)',
          code: 'XH5R',
        },
      ],
    },
  ],
  GU: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Limited liability company',
          code: '5I7B',
        },
        {
          label: 'Sole proprietorship',
          code: 'GNYB',
        },
        {
          label: 'Corporation',
          code: 'KJ1Y',
        },
        {
          label: 'Commercial Bank',
          code: 'TMK1',
        },
        {
          label: 'Partnership',
          code: 'U5RF',
        },
        {
          label: 'Limited liability partnership',
          code: 'YB1Q',
        },
        {
          label: 'Savings and Loan Association',
          code: 'YNEH',
        },
      ],
    },
  ],
  HK: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Private company limited by shares (Ltd)',
          code: '254M',
        },
        {
          label: 'Trust',
          code: '2QMJ',
        },
        {
          label: 'Registered trustee',
          code: '85VD',
        },
        {
          label: 'Entity established by Ordinance',
          code: 'E1SE',
        },
        {
          label: 'Public company limited by shares (Ltd)',
          code: 'EPG7',
        },
        {
          label: 'Non-Hong Kong company',
          code: 'KSUS',
        },
        {
          label: 'Public unlimited company with a share capital (Ultd)',
          code: 'N3VO',
        },
        {
          label: 'Company limited by guarantee (Ltd)',
          code: 'S2E3',
        },
        {
          label: 'Private unlimited company with a share capital (Ultd)',
          code: 'UDG6',
        },
        {
          label: 'You xian ze ren he huo (LP)',
          code: 'XPE5',
        },
        {
          label: 'Limited partnership (LP)',
          code: 'XPE5',
        },
      ],
    },
  ],
  HN: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sociedades de Capital Variable (de C V)',
          code: '12F9',
        },
        {
          label: 'Sociedad en Comandita por Acciones (S en C)',
          code: '6ETM',
        },
        {
          label: 'Comerciante Individual',
          code: 'E7JZ',
        },
        {
          label: 'Sociedad Anónima (SA)',
          code: 'JUDZ',
        },
        {
          label: 'Sociedad Colectiva',
          code: 'O2M6',
        },
        {
          label: 'Sociedad en Comandita Simple (S en C)',
          code: 'RF41',
        },
        {
          label: 'Sociedad de Responsabilidad Limitada (S De R L)',
          code: 'XE7O',
        },
      ],
    },
  ],
  HR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Kreditna unija',
          code: '24EG',
        },
        {
          label: 'Udruga',
          code: '274G',
        },
        {
          label: 'Fond',
          code: '28RA',
        },
        {
          label: 'Gospodarsko interesno udruženje (GIU)',
          code: '2FED',
        },
        {
          label: 'Europsko gospodarsko interesno udruženje (EGIU)',
          code: '3OW3',
        },
        {
          label: 'Pravna osoba Katoličke Crkve',
          code: '6Q6D',
        },
        {
          label: 'Agencije I druge samostalne pravne osobe s javnim ovlastima',
          code: '6RD2',
        },
        {
          label: 'Europska zadruga (SCE)',
          code: '8VRU',
        },
        {
          label: 'Trgovac pojedinac (tp)',
          code: '9D9U',
        },
        {
          label: 'Fundacija',
          code: '9PZC',
        },
        {
          label: 'Europsko društvo (SE)',
          code: 'E9CM',
        },
        {
          label: 'Stečajna masa',
          code: 'EYKU',
        },
        {
          label: 'Zadruge',
          code: 'G5RH',
        },
        {
          label: 'Savez zadruga',
          code: 'IVVD',
        },
        {
          label: 'Javno trgovačko društvo (jtd)',
          code: 'KP34',
        },
        {
          label: 'Dioničko društvo (dd)',
          code: 'LUMA',
        },
        {
          label: 'Komanditno društvo (kd)',
          code: 'MF7P',
        },
        {
          label: 'Podružnica',
          code: 'NRTG',
        },
        {
          label: 'Društvo s ograničenom odgovornošću (jdoo)',
          code: 'OQWO',
        },
        {
          label: 'Vjerska zajednica',
          code: 'SPJ7',
        },
        {
          label: 'Tijelo državne uprave',
          code: 'TSA0',
        },
        {
          label: 'Jedinica lokalne ili područne',
          code: 'XQLW',
        },
        {
          label: 'Zajednica ustanova',
          code: 'YA9S',
        },
        {
          label: 'Ustanova',
          code: 'ZFQS',
        },
        {
          label: 'Zaklada',
          code: 'ZMY8',
        },
      ],
    },
  ],
  HU: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Egyéni cég',
          code: '2A44',
        },
        {
          label: 'Munkavállalói Résztulajdonosi Program szervezet',
          code: '2LB5',
        },
        {
          label: 'Ügyvédi Iroda',
          code: '4C5L',
        },
        {
          label: 'Önkormányzat',
          code: '4QRE',
        },
        {
          label: 'Egyéb köztestület',
          code: '4WV7',
        },
        {
          label: 'Egyéb egyesület',
          code: '876R',
        },
        {
          label: 'Nyilvánosan működő részvénytársaság (Nyrt)',
          code: '8UEG',
        },
        {
          label: 'Európai gazdasági egyesülés (EGE)',
          code: '8UMK',
        },
        {
          label: 'Befektetési alap',
          code: '8VH3',
        },
        {
          label: 'Magánnyugdíjpénztár',
          code: '995K',
        },
        {
          label: 'Egyéni vállalkozás',
          code: 'BJ8Q',
        },
        {
          label: 'Zártkörűen működő részvénytársaság (Zrt)',
          code: 'BKUX',
        },
        {
          label: 'Egyéb alapítvány',
          code: 'BMYJ',
        },
        {
          label: 'Oktatási intézmények',
          code: 'BSK1',
        },
        {
          label: 'Kölcsönös biztosító egyesület',
          code: 'DN6F',
        },
        {
          label: 'Egyéb szövetkezet',
          code: 'DPY1',
        },
        {
          label: 'Részvénytársaság (Rt)',
          code: 'EO9F',
        },
        {
          label: 'Társasház',
          code: 'ESTU',
        },
        {
          label: 'Közhasznú társaság (Kht)',
          code: 'HTJD',
        },
        {
          label: 'Közkereseti társaság (Kkt)',
          code: 'J6MO',
        },
        {
          label: 'Egyházi szervezetek',
          code: 'LNY0',
        },
        {
          label: 'Önkéntes kölcsönös biztosítópénztár',
          code: 'M1DW',
        },
        {
          label: 'Külföldi székhelyű vállalkozás kereskedelmi képviselete',
          code: 'NZAI',
        },
        {
          label: 'Egyéni ügyvéd',
          code: 'OII5',
        },
        {
          label: 'Korlátolt felelősségű társaság (Kft)',
          code: 'P9F2',
        },
        {
          label: 'Költségvetési szerv',
          code: 'QYV5',
        },
        {
          label: 'Betéti társaság (Bt)',
          code: 'S3DA',
        },
        {
          label: 'Egyéb, máshova nem sorolt jogi személyiségű vállalkozás',
          code: 'TQ3O',
        },
        {
          label: 'Szövetség (kbe)',
          code: 'TSVO',
        },
        {
          label: 'Külföldi székhelyű vállalkozás fióktelepe',
          code: 'UD8K',
        },
        {
          label: 'Erdőbirtokossági Társulat',
          code: 'V3LT',
        },
        {
          label: 'Takarék- és hitelszövetkezet (hsz)',
          code: 'X0SX',
        },
        {
          label: 'Európai részvénytársaság (SE)',
          code: 'XW5U',
        },
        {
          label: 'Országos betétbiztosítási alap (OBA)',
          code: 'Y64R',
        },
        {
          label: 'Közjegyzői iroda',
          code: 'ZQAQ',
        },
      ],
    },
  ],
  ID: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Koperasi',
          code: '72I6',
        },
        {
          label: 'Commanditaire Vennootschap (CV)',
          code: 'B8VT',
        },
        {
          label: 'Firma (Fa)',
          code: 'BJT3',
        },
        {
          label: 'Yayasan',
          code: 'HELT',
        },
        {
          label: 'Perseroan Terbatas Penanaman Modal Asing (PT PMA)',
          code: 'JAJS',
        },
        {
          label: 'Perseroan Terbatas (PT PMDN)',
          code: 'UD1V',
        },
        {
          label: 'Persekutuan Perdata',
          code: 'V05A',
        },
        {
          label: 'Usaha Dagang',
          code: 'XFHP',
        },
      ],
    },
  ],
  IE: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Irish Collective Asset-management Vehicle (ICAV)',
          code: '2GV9',
        },
        {
          label: 'European Economic Interest Grouping (EEIG)',
          code: '363J',
        },
        {
          label: 'Friendly Society',
          code: '54SK',
        },
        {
          label: 'Designated Activity Company Limited by Guarantee (DAC)',
          code: '5AX8',
        },
        {
          label: 'Credit Union',
          code: '9BPE',
        },
        {
          label: 'Company Limited by Guarantee (CLG)',
          code: 'C58S',
        },
        {
          label: 'General Partnership',
          code: 'DWS3',
        },
        {
          label:
            'Public Unlimited Company that has no share capital (PULC / uc)',
          code: 'FF1D',
        },
        {
          label: 'Limited Partnership',
          code: 'HNJK',
        },
        {
          label: 'Societas Europaea (SE)',
          code: 'JXDX',
        },
        {
          label: 'Private Unlimited Company (ULC / uc)',
          code: 'KMFX',
        },
        {
          label: 'Designated Activity Company (DAC)',
          code: 'LGWG',
        },
        {
          label: 'Limited company (Ltd)',
          code: 'LMIM',
        },
        {
          label: 'Investment Limited Partnership (ilp)',
          code: 'LZIC',
        },
        {
          label: 'Private Company Limited by Shares (Ltd)',
          code: 'MNQ7',
        },
        {
          label: 'Teoranta (teo)',
          code: 'MNQ7',
        },
        {
          label: 'Public Unlimited Company (PUC / uc)',
          code: 'URQH',
        },
        {
          label: 'Public Limited Company (PLC)',
          code: 'VYAX',
        },
        {
          label: 'Industrial and Provident Society (I&PS)',
          code: 'ZJS8',
        },
      ],
    },
  ],
  IM: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Public Limited by Guarantee and not having a Share Capital',
          code: '1G26',
        },
        {
          label: 'Unlimited with Shares',
          code: '5ABK',
        },
        {
          label: 'Limited Liability Company',
          code: 'CDXK',
        },
        {
          label: 'Limited Partnership - without Legal Personality',
          code: 'EMLA',
        },
        {
          label: 'Industrial and Building Society',
          code: 'FI4M',
        },
        {
          label: 'Private Limited by Guarantee and not having a Share Capital',
          code: 'FMPL',
        },
        {
          label: 'Public Unlimited and having a Share Capital',
          code: 'G4V6',
        },
        {
          label: 'Foundation',
          code: 'JHC4',
        },
        {
          label: 'Limited by Shares and by Guarantee',
          code: 'M44Y',
        },
        {
          label: 'Public Limited by Guarantee and having a Share Capital',
          code: 'OESH',
        },
        {
          label: 'Private Limited by shares',
          code: 'QZTT',
        },
        {
          label: 'Private Unlimited and having a Share Capital',
          code: 'SAI8',
        },
        {
          label: 'Limited by Guarantee',
          code: 'SP9F',
        },
        {
          label: 'Limited Partnership - with Legal Personality',
          code: 'TE79',
        },
        {
          label: 'Limited by Shares',
          code: 'TQ3Z',
        },
        {
          label: 'Unlimited without Shares',
          code: 'TV5P',
        },
        {
          label: 'Private Limited by Guarantee and having a Share Capital',
          code: 'VC3E',
        },
        {
          label: 'Public Limited by Shares',
          code: 'ZGPY',
        },
      ],
    },
  ],
  IN: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Stand Alone Primary Dealer',
          code: '180P',
        },
        {
          label: 'Cooperative Society',
          code: '2DVA',
        },
        {
          label: 'Special Purpose Vehicle - Company',
          code: '2Y95',
        },
        {
          label: 'Unlimited Company',
          code: '36YD',
        },
        {
          label: 'Public Financial Institutions',
          code: '3VSP',
        },
        {
          label: 'Sole Proprietorship',
          code: '4QIE',
        },
        {
          label: 'Government Organization',
          code: '70GJ',
        },
        {
          label: 'Special Purpose Vehicle - Trust',
          code: '8ZLF',
        },
        {
          label: 'Non profit company',
          code: '9ME5',
        },
        {
          label: 'Partnership Firm',
          code: 'A0PS',
        },
        {
          label: 'Public Limited Company (Ltd)',
          code: 'DDKQ',
        },
        {
          label: 'Company Limited by Guarantee',
          code: 'DW2W',
        },
        {
          label: 'Alternative Investment Fund',
          code: 'EX8U',
        },
        {
          label: 'Housing Finance Company',
          code: 'FC7L',
        },
        {
          label: 'Banking Company - Scheduled Urban Cooperative Bank',
          code: 'FPZK',
        },
        {
          label: 'Alternate Investment Fund - Sub scheme',
          code: 'HBQK',
        },
        {
          label: 'One Person Company',
          code: 'I47Z',
        },
        {
          label: 'Provident fund',
          code: 'JKJX',
        },
        {
          label: 'Special Purpose Vehicle-Coop',
          code: 'K361',
        },
        {
          label: 'Special Purpose Vehicle - Partnership Firm',
          code: 'K4OX',
        },
        {
          label: 'Insurance Company',
          code: 'KJ0B',
        },
        {
          label: 'Banking Company - Others',
          code: 'NBCI',
        },
        {
          label: 'Non-Banking Finance Company',
          code: 'NFPC',
        },
        {
          label: 'Pension Funds',
          code: 'OYDA',
        },
        {
          label: 'Multistate Cooperative Society',
          code: 'QCSO',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'U5S6',
        },
        {
          label: 'Mutual Fund',
          code: 'U8NM',
        },
        {
          label: 'Mutual Fund-Sub Scheme',
          code: 'VX5J',
        },
        {
          label: 'Pension Funds-Sub Scheme',
          code: 'W0G7',
        },
        {
          label: 'Trust',
          code: 'W9FI',
        },
        {
          label: 'Government Company',
          code: 'X4IT',
        },
        {
          label: 'Banking Company - Nationalised Bank',
          code: 'XTZG',
        },
        {
          label: 'Private Limited Company (PVT LTD)',
          code: 'YSP9',
        },
      ],
    },
  ],
  IS: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Samlagshlutafélag (slhf)',
          code: '2OJP',
        },
        {
          label: 'Samlagsfélag (slf)',
          code: '8T79',
        },
        {
          label: 'Evrópufélag (SE)',
          code: 'CMHQ',
        },
        {
          label: 'Hlutafélag (hf)',
          code: 'E4KB',
        },
        {
          label: 'Einkahlutafélag (ehf)',
          code: 'M8SS',
        },
      ],
    },
  ],
  IT: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: "Societa' Costituita In Base A Leggi Di Altro Stato",
          code: '135L',
        },
        {
          label: 'Ente Ecclesiastico Civilmente Riconosciuto',
          code: '1RI9',
        },
        {
          label: 'Associazione',
          code: '1TON',
        },
        {
          label: "Consorzio con attivita' Esterna",
          code: '2J7Z',
        },
        {
          label: 'Ente Sociale',
          code: '2SZI',
        },
        {
          label: "Societa' Semplice (SS)",
          code: '2XXH',
        },
        {
          label: 'Ente Diritto Pubblico',
          code: '5ODO',
        },
        {
          label: 'Gruppo Europeo Di Interesse Economico (GEIE)',
          code: '634M',
        },
        {
          label: 'Fondazione Impresa',
          code: '70X0',
        },
        {
          label: 'Fondazione',
          code: '75WC',
        },
        {
          label: "Societa' Consortile Cooperativa a Responsabilita' Limitata",
          code: '8AXV',
        },
        {
          label: "Societa' A Responsabilita' Limitata Semplificata (SRL)",
          code: '8GU1',
        },
        {
          label: "Societa' Cooperativa Europea (SCE)",
          code: '9KGS',
        },
        {
          label: 'Istituto Religioso',
          code: 'ADAZ',
        },
        {
          label: 'Ente Morale',
          code: 'ARJF',
        },
        {
          label: "Societa' a Responsabilita' Limitata con Unico Socio",
          code: 'B5TO',
        },
        {
          label: "Societa' Consortile A Responsabilita' Limitata (SCRL)",
          code: 'BL52',
        },
        {
          label: "Societa' Cooperativa Consortile (Coop)",
          code: 'C1IC',
        },
        {
          label: "Societa' in Accomandita Semplice",
          code: 'CGSO',
        },
        {
          label: "Societa' Cooperativa a Responsabilita' Limitata",
          code: 'CTNS',
        },
        {
          label: 'Consorzio Fidi',
          code: 'DCRK',
        },
        {
          label: "Societa' in Accomandita per Azioni",
          code: 'DLS4',
        },
        {
          label: 'Azienda Autonoma Statale',
          code: 'DSSD',
        },
        {
          label: 'Soggetto Estero',
          code: 'H7D4',
        },
        {
          label: 'Consorzio',
          code: 'HN75',
        },
        {
          label: 'Ente Ecclesiastico',
          code: 'IMHA',
        },
        {
          label: "Contratto Di Rete Dotato Di Soggettivita' Giuridica",
          code: 'K021',
        },
        {
          label: 'Ente',
          code: 'K26L',
        },
        {
          label: 'Azienda Speciale Di Cui Al Dlgs 267/2000',
          code: 'KCHO',
        },
        {
          label: 'Ente Impresa (consorzio)',
          code: 'KCSG',
        },
        {
          label: 'Azienda Speciale',
          code: 'KXZT',
        },
        {
          label: 'Azienda Municipale',
          code: 'L1ZJ',
        },
        {
          label: 'Impresa Individuale',
          code: 'LHBB',
        },
        {
          label: "Societa' Consortile Per Azioni",
          code: 'LZZD',
        },
        {
          label: "Societa' Consortile Cooperativa",
          code: 'NDEW',
        },
        {
          label: 'Fondo Pensione',
          code: 'OQ8C',
        },
        {
          label: "Societa' A Responsabilita' Limitata (SRL)",
          code: 'OV32',
        },
        {
          label: "Societa' Per Azioni (SPA)",
          code: 'P418',
        },
        {
          label: 'Cooperativa Sociale',
          code: 'PHMS',
        },
        {
          label: "Societa' Cooperativa (soc coop)",
          code: 'QRZJ',
        },
        {
          label: 'Altre Forme',
          code: 'RWZD',
        },
        {
          label: "Consorzio senza Attivita' Esterna",
          code: 'SBQN',
        },
        {
          label: 'Azienda Speciale Rea',
          code: 'SSP9',
        },
        {
          label: "Societa' In Nome Collettivo (SNC)",
          code: 'T2X1',
        },
        {
          label: "Societa' Di Mutuo Soccorso",
          code: 'VA1V',
        },
        {
          label: 'Consorzio Municipale',
          code: 'VDRB',
        },
        {
          label: "Societa' Europea (SE)",
          code: 'VUPE',
        },
        {
          label: "Societa' non prevista dalla Legislazione Italiana",
          code: 'W2PE',
        },
        {
          label: 'Mutua Assicurazione',
          code: 'W9SC',
        },
        {
          label: 'Associazione Impresa',
          code: 'WPED',
        },
        {
          label: "Societa' Consortile in Accomandita Semplice",
          code: 'WRU2',
        },
        {
          label: "Societa' per Azioni con Socio Unico",
          code: 'X32V',
        },
        {
          label: 'Consorzio Di Cui Alla Dlgs 267/2000',
          code: 'X62Z',
        },
        {
          label: 'Ente Pubblico Economico',
          code: 'ZG6S',
        },
        {
          label: "Piccola Societa' Cooperativa a Responsabilita' Limitata",
          code: 'ZU9S',
        },
        {
          label: "Societa' Tra Professionisti",
          code: 'ZZOG',
        },
      ],
    },
  ],
  JE: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Separate Limited Partnerships (SLP)',
          code: '21F4',
        },
        {
          label: 'Incorporated Limited Partnerships (ILP)',
          code: '33IP',
        },
        {
          label: 'Foundation',
          code: '40A7',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '7RZH',
        },
        {
          label: 'Public Limited Company (PLC)',
          code: 'JOX1',
        },
        {
          label: 'Private Limited by Guarantee (Ltd)',
          code: 'LO9A',
        },
        {
          label: 'Private Limited Company (ARL)',
          code: 'SQXV',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'UBWU',
        },
      ],
    },
  ],
  JM: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Collective investment scheme company',
          code: '7ZU4',
        },
        {
          label: 'Unlimited company',
          code: 'EBRQ',
        },
        {
          label: 'Company limited by shares',
          code: 'HCBE',
        },
        {
          label: 'Company limited by guarantee',
          code: 'N97C',
        },
        {
          label: 'Partnership',
          code: 'PFY0',
        },
        {
          label: 'Non profit company',
          code: 'TLCB',
        },
        {
          label: 'Sole trader',
          code: 'WVQN',
        },
      ],
    },
  ],
  JO: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sharikat raas almal almughamir',
          code: '2R82',
        },
        {
          label: 'Alsharika zat almasuwliat almahduda',
          code: '6B39',
        },
        {
          label: 'Sharikat altawsia albasita',
          code: '6KSC',
        },
        {
          label: 'Sharikat altadamun',
          code: '9NBN',
        },
        {
          label: 'Alsharika almusahama aleama',
          code: 'BL60',
        },
        {
          label: 'Alsharika almusahama alkhasa',
          code: 'FZAC',
        },
        {
          label: "Sharikat altawsia bial'ashum",
          code: 'IXQK',
        },
        {
          label: 'Alsharika alty la tahdof litahqiq alribh',
          code: 'SAST',
        },
      ],
    },
  ],
  JP: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Chihokokyoudantai',
          code: '2NRQ',
        },
        {
          label: 'Gousigaisya',
          code: '5MVV',
        },
        {
          label: 'Goudougaisya',
          code: '7QQ0',
        },
        {
          label: 'Yugengaisya',
          code: 'DYQK',
        },
        {
          label: 'Goumeigaisya',
          code: 'IUVI',
        },
        {
          label: 'Gaikokugaisyatou',
          code: 'MXMH',
        },
        {
          label: 'Sonotaseturitutoukihouzin',
          code: 'N3JU',
        },
        {
          label: 'Kuninokikan',
          code: 'R4LR',
        },
        {
          label: 'Kabusikigaisya',
          code: 'T417',
        },
        {
          label: 'Sonota',
          code: 'VQLD',
        },
      ],
    },
  ],
  KE: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [],
    },
  ],
  KH: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Private Limited Company',
          code: '15BP',
        },
        {
          label: 'Kromhoun ekachn ttuolkhosatrauv meanokamrit',
          code: '15BP',
        },
        {
          label: 'Public Limited Company',
          code: 'BQ0P',
        },
        {
          label: 'Kromhoun mhachn ttuolkhosatrauv meanokamrit',
          code: 'BQ0P',
        },
        {
          label: 'Limited Partnership',
          code: 'D3UY',
        },
        {
          label: 'Kromhoun sahakammosetthi meanokamrit',
          code: 'D3UY',
        },
        {
          label: 'Sole Proprietorship',
          code: 'KD84',
        },
        {
          label: 'Sahakreasa​ ekbokkol',
          code: 'KD84',
        },
        {
          label: 'General Partnership',
          code: 'Z4YC',
        },
        {
          label: 'Kromhoun sahakammosetthi toutow',
          code: 'Z4YC',
        },
        {
          label: 'Foreign Company',
          code: 'ZCFU',
        },
        {
          label: 'Kromhoun bartes',
          code: 'ZCFU',
        },
      ],
    },
  ],
  KN: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Foundation',
          code: '29OB',
        },
        {
          label: 'Limited partnership',
          code: 'AHRM',
        },
        {
          label: 'Public company',
          code: 'I93O',
        },
        {
          label: 'Exempt limited partnership',
          code: 'KYOI',
        },
        {
          label: 'Private company',
          code: 'WFYL',
        },
        {
          label: 'Exempt private company',
          code: 'ZJZB',
        },
      ],
    },
    {
      subdivision_label: 'Nevis',
      subdivision_code: 'KN-N',
      legal_forms: [
        {
          label: 'Limited liability company (LC)',
          code: '2F0A',
        },
        {
          label: 'Multiform foundation',
          code: '4EP1',
        },
        {
          label: 'Public company',
          code: '7Z3M',
        },
        {
          label: 'Limited partnership',
          code: 'BXZH',
        },
        {
          label: 'Foundation',
          code: 'EF4Y',
        },
        {
          label: 'Private company',
          code: 'H64R',
        },
        {
          label: 'International Business Company (IBC)',
          code: 'HW77',
        },
        {
          label: 'International exempt trust',
          code: 'I03S',
        },
        {
          label: 'Exempt private company',
          code: 'I8G4',
        },
        {
          label: 'Exempt limited partnership',
          code: 'OTE3',
        },
      ],
    },
  ],
  KR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'HoeSaHyeong fund',
          code: '12KO',
        },
        {
          label: 'JuSikHoeSa (Ju)',
          code: '5RCH',
        },
        {
          label: 'JoHabHyeong fund',
          code: 'AHJG',
        },
        {
          label: 'JaeDanBeobIn',
          code: 'AKF5',
        },
        {
          label: 'HabMyeongHoeSa',
          code: 'AXEQ',
        },
        {
          label: 'HabJaHoeSa',
          code: 'BW92',
        },
        {
          label: 'ByeonAcBoHeom',
          code: 'EO0G',
        },
        {
          label: 'GaeInSaEopJa',
          code: 'GF7G',
        },
        {
          label: 'YuHanHoeSa (Yu)',
          code: 'J3U5',
        },
        {
          label: 'GukGa',
          code: 'QU16',
        },
        {
          label: 'YuHanChaekImHoeSa',
          code: 'UDJG',
        },
        {
          label: 'SinTakHyeong fund',
          code: 'UGVB',
        },
        {
          label: 'BiYeongRi SaDanBeobIn',
          code: 'VTH4',
        },
        {
          label: 'GongGongDanChe',
          code: 'WUJC',
        },
      ],
    },
  ],
  KY: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Company limited by guarantee (Ltd)',
          code: '4XP8',
        },
        {
          label: 'Exempted limited partnership (ELP)',
          code: '6XB7',
        },
        {
          label: 'Unlimited company',
          code: '8HR7',
        },
        {
          label: 'Special economic zone company (SEZC)',
          code: 'JDX6',
        },
        {
          label: 'Foundation company',
          code: 'K575',
        },
        {
          label: 'Segregated portfolio company (SPC)',
          code: 'MP7S',
        },
        {
          label: 'Limited liability company (LLC)',
          code: 'MPUG',
        },
        {
          label: 'Company limited by shares (Ltd)',
          code: 'OSBR',
        },
        {
          label: 'Association',
          code: 'SNUK',
        },
        {
          label: 'Exempted limited duration company (LDC)',
          code: 'T5UM',
        },
        {
          label: 'Limited partnership (LP)',
          code: 'XAQA',
        },
      ],
    },
  ],
  LI: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Treuunternehmen (Trust reg)',
          code: '1DGT',
        },
        {
          label: 'Europäische Genossenschaft (SCE)',
          code: '1SOY',
        },
        {
          label: 'Verein (eV)',
          code: '32HC',
        },
        {
          label: 'Treuhänderschaft',
          code: '53QF',
        },
        {
          label: 'Aktiengesellschaft (AG)',
          code: '7RRP',
        },
        {
          label: 'Kommanditgesellschaft (KG)',
          code: 'ANSR',
        },
        {
          label: 'Stiftung',
          code: 'BSZ8',
        },
        {
          label: 'Genossenschaft (eG)',
          code: 'IF49',
        },
        {
          label: 'Europäische Wirtschaftliche Interessenvereinigung (EWIV)',
          code: 'K98U',
        },
        {
          label: 'Kollektivgesellschaft',
          code: 'NDID',
        },
        {
          label: 'Anstalt',
          code: 'TMU1',
        },
        {
          label: 'Kollektivtreuhänderschaft',
          code: 'TV8Y',
        },
        {
          label: 'Europäische Gesellschaft (SE)',
          code: 'WAK8',
        },
        {
          label: 'Gesellschaft mit beschränkter Haftung (GesmbH)',
          code: 'Y8LH',
        },
      ],
    },
  ],
  LS: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Non-profit Company',
          code: 'CD8F',
        },
        {
          label: 'Public Company',
          code: 'K6G7',
        },
        {
          label: 'External Company',
          code: 'S02Y',
        },
        {
          label: 'Private Company',
          code: 'S779',
        },
      ],
    },
  ],
  LT: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Europos bendrovės (EB)',
          code: '13T9',
        },
        {
          label: 'Valstybės įmonės (VI)',
          code: '4ZF3',
        },
        {
          label: 'Europos kooperatinės bendrovės (EKB)',
          code: '5BY2',
        },
        {
          label: 'Advokatų profesinės bendrijos (APB)',
          code: '5CIE',
        },
        {
          label: 'Šeimynos (S)',
          code: '5TA8',
        },
        {
          label: 'Profesinės sąjungos ir jų susivienijimai (PS)',
          code: '6JDN',
        },
        {
          label: 'Akcinės bendrovės (AB)',
          code: '81KA',
        },
        {
          label: 'Religines bendruomenes, bendrijos ir centrai (RB)',
          code: 'A2GC',
        },
        {
          label: 'Individualios įmonės (II)',
          code: 'A338',
        },
        {
          label: 'Europos ekonominių interesų grupės (EEIG)',
          code: 'BGPJ',
        },
        {
          label: 'Uždarosios akcinės bendrovės (UAB)',
          code: 'BPLS',
        },
        {
          label: 'Tikrosios ūkinės bendrijos (TUB)',
          code: 'DLCX',
        },
        {
          label: 'Lietuvos prekybos, pramonės ir amatų rūmų asociacija (LPRA)',
          code: 'E4HU',
        },
        {
          label: 'Tradicinės religinės bendruomenės ar bendrijos (TR)',
          code: 'F2TQ',
        },
        {
          label: 'Asociacijos (A)',
          code: 'F2ZF',
        },
        {
          label: 'Labdaros ir paramos fondai (LF)',
          code: 'HGMH',
        },
        {
          label: 'Bendrijos (BN)',
          code: 'HJ1Y',
        },
        {
          label: 'Biudžetinės įstaigos (BI)',
          code: 'K4GR',
        },
        {
          label: 'Viešosios įstaigos (VS)',
          code: 'KWBA',
        },
        {
          label: 'Kooperatinės bendrovės (KO)',
          code: 'LUGM',
        },
        {
          label: 'Komanditinės ūkinės bendrijos (KB)',
          code: 'LUOA',
        },
        {
          label: 'Privačių detektyvų bendrijos (PDB)',
          code: 'NVPY',
        },
        {
          label: 'Prekybos, pramonės ir amatų rūmai (PER)',
          code: 'Q4A3',
        },
        {
          label: 'Nuolatinės arbitražo institucijos (NA)',
          code: 'Q8UI',
        },
        {
          label: 'Sodininkų bendrijos (SD)',
          code: 'QG9Y',
        },
        {
          label: 'Europos teritorinio bendradarbiavimo grupės (ETBG)',
          code: 'QRXD',
        },
        {
          label: 'Bendras valdymo ir pranešimų centras (CRC)',
          code: 'SFK9',
        },
        {
          label: 'Mažosios bendrijos (MB)',
          code: 'SFYA',
        },
        {
          label: 'Žemės ūkio bendrovės (ZUB)',
          code: 'UHNL',
        },
        {
          label: 'Centrinis bankas (CB)',
          code: 'WQRG',
        },
        {
          label: 'Politinės partijos (PP)',
          code: 'X8J8',
        },
        {
          label: 'Savivaldybių įmonės (SI)',
          code: 'YRXK',
        },
      ],
    },
  ],
  LU: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Entreprise individuelle (EI)',
          code: '2IGL',
        },
        {
          label: 'Association sans but lucratif (ASBL)',
          code: '2JEI',
        },
        {
          label: "Association d'épargne pension (ASSEP)",
          code: '2S2U',
        },
        {
          label: 'Association momentanée (AM)',
          code: '5DWL',
        },
        {
          label: 'Société anonyme (SA )',
          code: '5GGB',
        },
        {
          label: 'Société en commandite simple (SECS)',
          code: '63P9',
        },
        {
          label: 'Association agricole (AA)',
          code: '68J6',
        },
        {
          label: 'Mutuelle',
          code: '7SIZ',
        },
        {
          label: 'Société en commandite par actions (SECA)',
          code: '81G5',
        },
        {
          label: 'Société d’épargne-pension à capital variable (SEPCAV)',
          code: '9C91',
        },
        {
          label: 'Fond commun de placement',
          code: 'AIR5',
        },
        {
          label: 'Société en nom collectif (SENC)',
          code: 'ATQY',
        },
        {
          label: "Société d'investissement à capital fixe (SICAF)",
          code: 'BEAN',
        },
        {
          label: "Association d'assurances mutuelles (AAM)",
          code: 'BKAB',
        },
        {
          label: 'Société à responsabilité limitée (SARL )',
          code: 'DVXS',
        },
        {
          label: 'Société à responsabilité limitée simplifiée (Sà rl-S)',
          code: 'EUT4',
        },
        {
          label: 'Societas Cooperativa Europaea (SCE)',
          code: 'FG84',
        },
        {
          label: 'Société par actions simplifiée (SAS)',
          code: 'HHR4',
        },
        {
          label:
            "Autres personnes morales dont l'immatriculation est prévue par l'article 1 de la loi modifiée du 19 décembre 2002 (OTH)",
          code: 'JIWD',
        },
        {
          label: "Groupement d'intérêt économique (GIE)",
          code: 'LCR0',
        },
        {
          label: 'Société civile (SCI)',
          code: 'SQ1A',
        },
        {
          label: 'Société coopérative organisée sous forme de société anonyme',
          code: 'STBC',
        },
        {
          label: 'Société en commandite spéciale (SECSP)',
          code: 'U8KA',
        },
        {
          label: "Société d'investissement à capital variable (SICAV)",
          code: 'UDY2',
        },
        {
          label: 'Fondation (FON)',
          code: 'V19Y',
        },
        {
          label: 'Société coopérative (SC)',
          code: 'V5OS',
        },
        {
          label: 'Groupement européen d’intérêt économique (GEIE)',
          code: 'V7QY',
        },
        {
          label: 'Société européenne (SE)',
          code: 'WCEP',
        },
        {
          label: 'Etablissement public (EP)',
          code: 'ZFFA',
        },
      ],
    },
  ],
  LV: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Komandītsabiedrība (KS)',
          code: '1IPK',
        },
        {
          label: 'Pilnsabiedrība (PS)',
          code: '3LCY',
        },
        {
          label: 'Eiropas Ekonomisko interešu grupa (EEIG)',
          code: '5ULL',
        },
        {
          label: 'Eiropas komercsabiedrība (SE)',
          code: 'AZTM',
        },
        {
          label: 'Individuālais komersants (IK)',
          code: 'BS22',
        },
        {
          label: 'Sabiedrība ar ierobežotu atbildību (SIA)',
          code: 'ES2D',
        },
        {
          label: 'Akciju sabiedrība (AS)',
          code: 'VJBO',
        },
      ],
    },
  ],
  MC: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Société en Commandite Simple (SCS)',
          code: '528Z',
        },
        {
          label: 'Société Anonyme Monégasque (SAM)',
          code: 'CWTQ',
        },
        {
          label: 'Société à Responsabilité Limitée (SARL)',
          code: 'DA06',
        },
        {
          label: 'Société Civile Particulière (SCP)',
          code: 'GWZF',
        },
        {
          label: 'Société en Nom Collectif (SNC)',
          code: 'TU6A',
        },
        {
          label: 'Entreprise en Nom Personnel',
          code: 'X7W6',
        },
      ],
    },
  ],
  MH: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Corporation',
          code: 'DSII',
        },
        {
          label: 'Limited Liability Company',
          code: 'EZL8',
        },
        {
          label: 'Limited Partnership',
          code: 'S69C',
        },
        {
          label: 'Partnership',
          code: 'UW1C',
        },
      ],
    },
  ],
  MK: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Komanditno drustvo so akcii (KDA)',
          code: '149O',
        },
        {
          label: 'Akcionersko drustvo (AD)',
          code: 'DTIU',
        },
        {
          label: 'Javno trgovsko pretprijatie (JTD)',
          code: 'HEXO',
        },
        {
          label: 'Stopanska interesna zaednica (SIZ)',
          code: 'JCK1',
        },
        {
          label: 'Drustvo so ogranicena odgvornost (DOO)',
          code: 'KHAB',
        },
        {
          label:
            'Podruznica na stransko trgovsko drustvo, odnosno na stranski trgovec poedinec',
          code: 'KXDY',
        },
        {
          label: 'Trgovec poedinec (TP)',
          code: 'VPFY',
        },
        {
          label: 'Komanditno drustvo (KD)',
          code: 'XH7Z',
        },
      ],
    },
  ],
  MT: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Foundation',
          code: '3U3Y',
        },
        {
          label: 'Government entity',
          code: '9QHJ',
        },
        {
          label:
            'Partnership en commendite or limited partnership (limited partnership)',
          code: 'BVJ0',
        },
        {
          label: 'Soċjetà in akkomandita jew soċjetà limitata (LP)',
          code: 'BVJ0',
        },
        {
          label: 'Public Limited Liability Company (PLC)',
          code: 'C061',
        },
        {
          label: 'Kumpanija pubblika (PLC)',
          code: 'C061',
        },
        {
          label: 'European Company (SE)',
          code: 'CR7N',
        },
        {
          label: 'Societas Europaea (SE)',
          code: 'CR7N',
        },
        {
          label: 'Sole trader',
          code: 'DJ2D',
        },
        {
          label: 'Cooperative',
          code: 'F5X7',
        },
        {
          label: 'Association',
          code: 'J4S1',
        },
        {
          label: 'Partnership en nom collectif',
          code: 'JOZN',
        },
        {
          label: 'Soċjetà f’isem kollettiv',
          code: 'JOZN',
        },
        {
          label: 'Private Limited Liability Company (Ltd)',
          code: 'V89C',
        },
        {
          label: 'Kumpanija privata (Ltd)',
          code: 'V89C',
        },
        {
          label: 'European Economic Interest Grouping (EEIG)',
          code: 'YB0U',
        },
      ],
    },
  ],
  MU: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Business',
          code: '4AJY',
        },
        {
          label: 'Public Companies Limited By Guarantee (PLC)',
          code: '6M6Z',
        },
        {
          label: 'Private Companies Limited By Guarantee (PLC)',
          code: 'AOS4',
        },
        {
          label: 'Ministries And Parastal Bodies',
          code: 'B0LD',
        },
        {
          label: 'Cooperatives (COOP)',
          code: 'BVPF',
        },
        {
          label: 'Private Companies (PLC)',
          code: 'G7H0',
        },
        {
          label: 'Limited Partnerships (LP)',
          code: 'J76S',
        },
        {
          label: 'Foundations (F)',
          code: 'JWWT',
        },
        {
          label: 'Société (STE)',
          code: 'O786',
        },
        {
          label: 'Trade Unions (TU)',
          code: 'RJYT',
        },
        {
          label: 'Limited Liability Partnerships (LLP)',
          code: 'RTVE',
        },
        {
          label: 'Others',
          code: 'S2J1',
        },
        {
          label: 'Public Companies (PLC)',
          code: 'XXCZ',
        },
        {
          label: 'Associations',
          code: 'ZTUI',
        },
      ],
    },
  ],
  MX: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label:
            'Sociedad anónima de capital variable, sociedad de información crediticia',
          code: '23XJ',
        },
        {
          label: 'Sociedad anónima de capital variable, institución de seguros',
          code: '2LNV',
        },
        {
          label: 'Comunidad',
          code: '2NAI',
        },
        {
          label: 'Partido político',
          code: '31N9',
        },
        {
          label: 'Sociedad de responsabilidad limitada (SRL)',
          code: '449I',
        },
        {
          label: 'Sociedad anónima bursátil de capital variable, casa de bolsa',
          code: '5K70',
        },
        {
          label:
            'Sociedad por acciones simplificada de capital variable (SAS de CV)',
          code: '6EYT',
        },
        {
          label: 'Sociedad de producción rural de responsabilidad ilimitada',
          code: '776G',
        },
        {
          label: 'Sociedad anónima de capital variable, sociedad controladora',
          code: '784B',
        },
        {
          label: 'Sociedad civil (SC)',
          code: '7H63',
        },
        {
          label: 'Unión ganadera regional especializada',
          code: '7L83',
        },
        {
          label: 'Sociedad en comandita simple de capital variable (SC de C V)',
          code: '7PKK',
        },
        {
          label: 'Unión ganadera regional general',
          code: '8AGD',
        },
        {
          label:
            'Sociedad cooperativa de productores de bienes y/o servicios de capital variable',
          code: '8M3D',
        },
        {
          label:
            'Sociedad anónima de capital variable, valuadora de acciones de fondos de inversión',
          code: '8MY7',
        },
        {
          label:
            'Sociedad cooperativa de responsabilidad limitada de capital variable (SC DE RL DE CV)',
          code: '8QZA',
        },
        {
          label: 'Sociedad cooperativa de ahorro y préstamo (SC de AP)',
          code: '8RRB',
        },
        {
          label: 'Sociedad cooperativa',
          code: '96OC',
        },
        {
          label: 'Sociedad en comandita por acciones (SC por A)',
          code: 'A2A8',
        },
        {
          label: 'Federación de sindicatos',
          code: 'ANXC',
        },
        {
          label: 'Unión de ejidos',
          code: 'AOKU',
        },
        {
          label: 'Sociedad anónima de capital variable, unión de crédito',
          code: 'B3WQ',
        },
        {
          label: 'Sociedad anónima (SA)',
          code: 'B8CE',
        },
        {
          label: 'Sociedad anónima, casa de cambio',
          code: 'BFIV',
        },
        {
          label: 'Sociedad en nombre colectivo',
          code: 'BOF1',
        },
        {
          label: 'Asociación rural de interés colectivo',
          code: 'C4Z4',
        },
        {
          label: 'Sociedad anónima, centro cambiario',
          code: 'CAF9',
        },
        {
          label: 'Organismo de integración financiera rural',
          code: 'COVJ',
        },
        {
          label: 'Asociación ganadera local general',
          code: 'CU5R',
        },
        {
          label:
            'Sociedad cooperativa limitada de capital variable (SCL de CV)',
          code: 'CU68',
        },
        {
          label: 'Sociedad anónima de capital variable, financiera comunitaria',
          code: 'DBU3',
        },
        {
          label: 'Sociedad anónima financiera comunitaria',
          code: 'DHY2',
        },
        {
          label:
            'Sociedad anónima, sociedad financiera de objeto múltiple, entidad no regulada (SA, SOFOM ENR)',
          code: 'DNF1',
        },
        {
          label:
            'Sociedad cooperativa de consumidores de bienes y/o servicios de capital variable',
          code: 'DU8H',
        },
        {
          label: 'Sociedad de producción rural de responsabilidad suplementada',
          code: 'E0YZ',
        },
        {
          label:
            'Sociedad anónima de capital variable, de inversión especializada de fondos para el retiro (SA de CV Siefore)',
          code: 'EHWD',
        },
        {
          label: 'Fondos de Aseguramiento Agropecuario y Rural',
          code: 'F3L5',
        },
        {
          label: 'Unión de sociedades de producción rural',
          code: 'FVTS',
        },
        {
          label:
            'Sociedad anónima de capital variable, operadora de fondos de inversión',
          code: 'G3JV',
        },
        {
          label: 'Sociedad cooperativa de consumidores de bienes y/o servicios',
          code: 'G522',
        },
        {
          label:
            'Sociedad anónima de capital variable, contraparte central de valores',
          code: 'G73Q',
        },
        {
          label:
            'Sociedad anónima de capital variable, administradora de fondos para el retiro (SA de CV Afore)',
          code: 'GNNT',
        },
        {
          label: 'Sociedad anónima bursátil (SAB)',
          code: 'GW1T',
        },
        {
          label:
            'Sociedad anónima de capital variable, institución para el depósito de valores',
          code: 'ICXT',
        },
        {
          label:
            'Sociedad anónima de capital variable, institución de financiamiento colectivo',
          code: 'IWNQ',
        },
        {
          label: 'Sociedad anónima de capital variable (SA de CV)',
          code: 'JGV1',
        },
        {
          label: 'Sociedad cooperativa de capital variable',
          code: 'JN6Y',
        },
        {
          label: 'Sindicato',
          code: 'JZ1I',
        },
        {
          label: 'Sociedad anónima de capital variable, financiera popular',
          code: 'LBPW',
        },
        {
          label: 'Sociedad por acciones simplificada (SAS)',
          code: 'LJTR',
        },
        {
          label:
            'Sociedad propietaria de tierras agrícolas, ganaderas o forestales',
          code: 'LOB9',
        },
        {
          label:
            'Sociedad anónima, almacén general de depósito, organización auxiliar del crédito',
          code: 'LVRX',
        },
        {
          label: 'Sociedad anónima de capital variable, centro cambiario',
          code: 'M44Q',
        },
        {
          label: 'Ejido',
          code: 'MB35',
        },
        {
          label: 'Sociedad anónima, bolsa de valores',
          code: 'N0JF',
        },
        {
          label:
            'Sociedad anónima de capital variable, institución de fondos de pago electrónico',
          code: 'N124',
        },
        {
          label: 'Asociación religiosa',
          code: 'NA09',
        },
        {
          label: 'Sociedad anónima de capital variable, casa de cambio',
          code: 'O15R',
        },
        {
          label: 'Sociedad anónima institución de fianzas',
          code: 'P7VS',
        },
        {
          label: 'Sociedad anónima institución de banca múltiple',
          code: 'P8D7',
        },
        {
          label: 'Confederación de sindicatos',
          code: 'PG49',
        },
        {
          label:
            'Sociedad anónima de capital variable, almacén general de depósito, organización auxiliar del crédito',
          code: 'Q7WY',
        },
        {
          label: 'Sociedad anónima de capital variable, casa de bolsa',
          code: 'QM7Z',
        },
        {
          label:
            'Sociedad anónima bursátil de capital variable, bolsa de valores',
          code: 'QZB4',
        },
        {
          label: 'Sociedad anónima de capital variable, bolsa de valores',
          code: 'R8PY',
        },
        {
          label:
            'Corporaciones de carácter público reconocidos por la legislación mexicana',
          code: 'RCJS',
        },
        {
          label: 'Sociedad anónima, sociedad de información crediticia',
          code: 'RKLI',
        },
        {
          label:
            'Sociedad nacional de crédito institución de banca de desarrollo',
          code: 'S6M8',
        },
        {
          label: 'Sociedad anónima institución de seguros',
          code: 'S8DM',
        },
        {
          label: 'Sociedad mutualista',
          code: 'SKO4',
        },
        {
          label:
            'Sociedad anónima bursátil de capital variable, sociedad controladora',
          code: 'TYW0',
        },
        {
          label: 'Sociedad anónima de capital variable, institución de fianzas',
          code: 'U77P',
        },
        {
          label: 'Instituciones de Seguros',
          code: 'U901',
        },
        {
          label: 'Estado (Edo)',
          code: 'VIOQ',
        },
        {
          label: 'Sociedad cooperativa de productores de bienes y/o servicios',
          code: 'VN3F',
        },
        {
          label: 'Empresa productiva del estado (EPE)',
          code: 'W9WS',
        },
        {
          label: 'Asociación ganadera local especializada',
          code: 'X9J8',
        },
        {
          label: 'Sociedad cooperativa limitada (SCL)',
          code: 'XDGC',
        },
        {
          label: 'Sociedad en comandita simple (SCS)',
          code: 'XEN5',
        },
        {
          label:
            'Sociedad de responsabilidad limitada de capital variable (SRL de CV)',
          code: 'XLVZ',
        },
        {
          label: 'Sociedad anónima financiera popular',
          code: 'XSZY',
        },
        {
          label: 'Fideicomiso público de fomento económico',
          code: 'YRI0',
        },
        {
          label: 'Sociedad en nombre colectivo de capital variable',
          code: 'ZAIS',
        },
        {
          label:
            'Sociedad en comandita por acciones de capital variable (SCA de CV)',
          code: 'ZXVG',
        },
      ],
    },
  ],
  MY: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Public Company (Ltd)',
          code: '7OYN',
        },
        {
          label: 'Berhad (Ltd)',
          code: '7OYN',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'SP51',
        },
        {
          label: 'Business',
          code: 'SV3F',
        },
        {
          label: 'Private Company (Pte Ltd)',
          code: 'XEOV',
        },
        {
          label: 'Sendirian Berhad (Pte Ltd)',
          code: 'XEOV',
        },
      ],
    },
    {
      subdivision_label: 'Labuan',
      subdivision_code: 'MY-15',
      legal_forms: [
        {
          label: 'Labuan Islamic Limited Partnership',
          code: '2SPI',
        },
        {
          label: 'Labuan Limited Partnership',
          code: '6HT0',
        },
        {
          label: 'Labuan Islamic Charitable Trust',
          code: '95AW',
        },
        {
          label: 'Labuan Company',
          code: 'JH78',
        },
        {
          label: 'Labuan Islamic Other Trust',
          code: 'LA66',
        },
        {
          label: 'Labuan Islamic Recognized Limited Liability Partnership',
          code: 'MEHL',
        },
        {
          label: 'Labuan Foreign Company',
          code: 'NO8C',
        },
        {
          label: 'Labuan Islamic Special Trust',
          code: 'NY3P',
        },
        {
          label: 'Labuan Islamic Limited Liability Partnership',
          code: 'OO14',
        },
        {
          label: 'Labuan Company',
          code: 'P0LR',
        },
        {
          label: 'Labuan Limited Liability Partnerships',
          code: 'P0TZ',
        },
        {
          label: 'Labuan Trust',
          code: 'PBQH',
        },
        {
          label: 'Labuan Islamic Foundation',
          code: 'PWX8',
        },
        {
          label: 'Labuan Protected Cell Company',
          code: 'QOO6',
        },
        {
          label: 'Labuan Pre-Incorporated Company',
          code: 'S7R4',
        },
        {
          label: 'Labuan Foundation',
          code: 'SP0A',
        },
        {
          label: 'Labuan Isalmic Trust',
          code: 'T5HS',
        },
        {
          label: 'Labuan Islamic Spendthrif',
          code: 'T635',
        },
        {
          label: 'Labuan Islamic Purpose Trust',
          code: 'TGWE',
        },
        {
          label: 'Labuan Recognized Limited Liability Partnership',
          code: 'TXCW',
        },
        {
          label: 'Labuan Company',
          code: 'ZWYK',
        },
      ],
    },
  ],
  NL: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Vereniging',
          code: '33MN',
        },
        {
          label: 'Eenmanszaak',
          code: '4QXM',
        },
        {
          label: 'Besloten vennootschap met beperkte aansprakelijkheid (bv)',
          code: '54M6',
        },
        {
          label: 'Europees Economisch Samenwerkingsverband (EESV)',
          code: '5WU6',
        },
        {
          label: 'Vennootschap onder firma (vof)',
          code: '62Y3',
        },
        {
          label: 'Maatschap',
          code: '9AAK',
        },
        {
          label: 'Publiekrechtelijke rechtspersoon',
          code: 'A0W7',
        },
        {
          label: 'Naamloze vennootschap (nv)',
          code: 'B5PM',
        },
        {
          label: 'Europese naamloze vennootschap (SE)',
          code: 'BBEB',
        },
        {
          label: 'Commanditaire vennootschap (cv)',
          code: 'CODH',
        },
        {
          label: 'Onderlinge waarborgmaatschappij (owm)',
          code: 'DEO1',
        },
        {
          label: 'Rechtspersoon in oprichting (RP io)',
          code: 'EZQW',
        },
        {
          label: 'Vereniging van eigenaars (VVE)',
          code: 'GNXT',
        },
        {
          label: 'Fonds voor gemene rekening',
          code: 'JHK5',
        },
        {
          label: 'Kerkgenootschap',
          code: 'L7HX',
        },
        {
          label: 'Overige Privaatrechtelijke Rechtspersoon',
          code: 'M1IZ',
        },
        {
          label: 'Coöperatie (coöp)',
          code: 'NFFH',
        },
        {
          label: 'Rederij',
          code: 'UNJ2',
        },
        {
          label: 'Stichting',
          code: 'V44D',
        },
        {
          label: 'Societas Cooperativa Europaea (SCE)',
          code: 'Y3ZB',
        },
      ],
    },
  ],
  NO: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Ansvarlig selskap (ANS)',
          code: '326Y',
        },
        {
          label: 'Stiftelse',
          code: '3C7U',
        },
        {
          label: 'Kommunalt foretak (KF)',
          code: '3L58',
        },
        {
          label: 'Norskregistrert utenlandsk foretak (NUF)',
          code: '4ZRR',
        },
        {
          label: 'Statsforetak (SF)',
          code: '50TD',
        },
        {
          label: 'Fylkeskommunalt foretak (FKF)',
          code: '5ZTZ',
        },
        {
          label: 'Gjensidig forsikringsselskap',
          code: '8S9H',
        },
        {
          label: 'Boligbyggelag (BBL)',
          code: '9DI1',
        },
        {
          label: 'Partrederi',
          code: 'AEV1',
        },
        {
          label: 'Pensjonskasse',
          code: 'BJ65',
        },
        {
          label: 'Ansvarlig selskap, delt ansvar (DA)',
          code: 'CF5L',
        },
        {
          label: 'Europeisk økonomisk foretaksgruppe (EØFG)',
          code: 'DRPL',
        },
        {
          label: 'Enkeltpersonforetak (ENK)',
          code: 'EXD7',
        },
        {
          label: 'Forening',
          code: 'FSBD',
        },
        {
          label: 'Annen juridisk person',
          code: 'GYY6',
        },
        {
          label: 'Allmennaksjeselskap (ASA)',
          code: 'IQGE',
        },
        {
          label: 'Samvirkeforetak (SA)',
          code: 'K5P8',
        },
        {
          label: 'Verdipapirfond',
          code: 'LJJW',
        },
        {
          label: 'Kommandittselskap (KS)',
          code: 'M9IQ',
        },
        {
          label: 'Borettslag (BRL)',
          code: 'O0EU',
        },
        {
          label: 'Europeisk samvirkeforetak (SCE)',
          code: 'O7LB',
        },
        {
          label: 'Konkursbo',
          code: 'PB3V',
        },
        {
          label: 'Selskap med begrenset ansvar (BA)',
          code: 'Q0Q1',
        },
        {
          label: 'Sparebank',
          code: 'R71C',
        },
        {
          label: 'Europeisk selskap (SE)',
          code: 'V06W',
        },
        {
          label: 'Aksjeselskap (AS)',
          code: 'YI42',
        },
        {
          label: 'Interkommunalt selskap (IKS)',
          code: 'YTMC',
        },
        {
          label: 'Eierseksjonssameie',
          code: 'ZQ0Q',
        },
      ],
    },
  ],
  NZ: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Unlimited Liability Company (Ultd)',
          code: '4LEA',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'F7KI',
        },
        {
          label: 'Limited Liability Company (Ltd)',
          code: 'P2R9',
        },
        {
          label: 'Cooperative Company (COOP)',
          code: 'Z6ZU',
        },
      ],
    },
  ],
  PA: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sociedad Anónima (SA)',
          code: '6TPA',
        },
        {
          label: 'Asociación',
          code: 'BSN7',
        },
        {
          label: 'Sociedad Civil (SC)',
          code: 'GDT9',
        },
        {
          label: 'Sociedad de Responsabilidad Limitada (SRL)',
          code: 'JS13',
        },
        {
          label: 'Sociedad en Comandita por acciones',
          code: 'PY5Y',
        },
        {
          label: 'Fundación de Interés Privado (FIP)',
          code: 'UNCO',
        },
        {
          label: 'Sociedad en Comandita simple',
          code: 'V5VG',
        },
        {
          label: 'Micro-empresa de Responsabilidad Limitada (MRL)',
          code: 'VIMA',
        },
      ],
    },
  ],
  PE: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sociedad en Comandita por Acciones (SCA)',
          code: '1MQ6',
        },
        {
          label: 'Sociedad por Acciones Cerrada Simplificada (SACS)',
          code: '37G2',
        },
        {
          label: 'Sociedad Colectiva (SC)',
          code: '3LHF',
        },
        {
          label: 'Empresas de Derecho Público',
          code: '4LCX',
        },
        {
          label: 'Sociedad Civil de Responsabilidad Limitada (SCRL)',
          code: '649C',
        },
        {
          label: 'Sociedad Comercial de Responsabilidad Limitada (SRL)',
          code: '73C0',
        },
        {
          label: 'Empresas Multicomunales',
          code: 'AABQ',
        },
        {
          label: 'Sociedad Anónima Cerrada (SAC)',
          code: 'CHX6',
        },
        {
          label: 'Empresa Individual de Responsabilidad Limitada (EIRL)',
          code: 'H8G2',
        },
        {
          label: 'Socieda Anónima (SA)',
          code: 'I695',
        },
        {
          label: 'Cooperativas',
          code: 'P53M',
        },
        {
          label: 'Socieda Anónima Abierta (SAA)',
          code: 'PKRY',
        },
        {
          label: 'Sociedad Civil (SC)',
          code: 'QQWG',
        },
        {
          label: 'Sociedad en Comandita Simple (SCS)',
          code: 'RFJT',
        },
        {
          label: 'Sociedad legal',
          code: 'YMBC',
        },
      ],
    },
  ],
  PG: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Local Company',
          code: '6UED',
        },
        {
          label: 'Foreign Enterprise',
          code: 'WHCV',
        },
        {
          label: 'Overseas Company',
          code: 'ZE1G',
        },
      ],
    },
  ],
  PH: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Nonstock corporation',
          code: '76ME',
        },
        {
          label: 'Educational corporation',
          code: 'IODM',
        },
        {
          label: 'One person corporation (OPC)',
          code: 'KJOW',
        },
        {
          label: 'Close corporation',
          code: 'SPX9',
        },
        {
          label: 'Stock corporation (Inc)',
          code: 'WHGH',
        },
        {
          label: 'Religious corporation',
          code: 'ZJEX',
        },
      ],
    },
  ],
  PK: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Private Limited Company multi member (Pvt Ltd)',
          code: '4XMS',
        },
        {
          label: 'Company Limited by Guarantee with share capital (Ltd)',
          code: '7IYW',
        },
        {
          label: 'Private Limited Company single member (SMC)',
          code: '88OX',
        },
        {
          label: 'Unlimited Company (Ultd)',
          code: 'LH0Q',
        },
        {
          label: 'Public Limited Company',
          code: 'MOI8',
        },
        {
          label: 'Company Limited by Guarantee without share capital (Ltd)',
          code: 'QR25',
        },
        {
          label: 'Public Limited Company (Ltd)',
          code: 'RKYF',
        },
        {
          label: 'Public Limited Company (Ltd)',
          code: 'VQV6',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'XBK3',
        },
      ],
    },
  ],
  PL: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Samodzielny publiczny zakład opieki zdrowotnej',
          code: '13ZV',
        },
        {
          label: 'Spółka partnerska (spp)',
          code: '3BJG',
        },
        {
          label: 'Cechy i izby rzemieślnicze, izby gospodarcze',
          code: '5F76',
        },
        {
          label:
            'Organy władzy, administracji rządowej, kontroli państwowej i ochrony prawa oraz sądy i trybunały',
          code: '60BG',
        },
        {
          label: 'Spółka jawna (Spj)',
          code: '629I',
        },
        {
          label: 'Główny oddział zagranicznego zakładu reasekuracji',
          code: '6LUM',
        },
        {
          label: 'Zrzeszenie',
          code: '6OYI',
        },
        {
          label: 'Europejskie Zgrupowanie Interesów Gospodarczych (EZIG)',
          code: '85PM',
        },
        {
          label: 'Spółdzielnia',
          code: '8TOF',
        },
        {
          label: 'Fundacja',
          code: '96XK',
        },
        {
          label: 'Samorządowe jednostki organizacyjne',
          code: 'AL9T',
        },
        {
          label: 'Prosta spółka akcyjna (PSA)',
          code: 'B21W',
        },
        {
          label: 'Spółka komandytowa (spk)',
          code: 'BSJT',
        },
        {
          label: 'Instytucje gospodarki budżetowej',
          code: 'CY1M',
        },
        {
          label: 'Towarzystwo reasekuracji wzajemnej',
          code: 'EU0T',
        },
        {
          label: 'Skarb Państwa',
          code: 'F21U',
        },
        {
          label: 'Kółka rolnicze',
          code: 'FANM',
        },
        {
          label: 'Spółka akcyjna (SA)',
          code: 'FJ0E',
        },
        {
          label: 'Organizacje społeczne i zawodowe',
          code: 'FQ5Y',
        },
        {
          label: 'Wspólnota mieszkaniowa',
          code: 'GZE5',
        },
        {
          label: 'Spółdzielcza kasa oszczędnościowo-kredytowa',
          code: 'H7OD',
        },
        {
          label: 'Partie polityczne',
          code: 'HOT8',
        },
        {
          label: 'Spółka europejska (SE)',
          code: 'J3A3',
        },
        {
          label: 'Państwowe jednostki organizacyjne',
          code: 'JCKO',
        },
        {
          label: 'Instytuty badawcze',
          code: 'KM66',
        },
        {
          label: 'Związek zawodowy',
          code: 'LT9U',
        },
        {
          label: 'Spółka z ograniczoną odpowiedzialnością (sp z oo)',
          code: 'O7XB',
        },
        {
          label: 'Spółka komandytowo-akcyjna (SKA)',
          code: 'OMX0',
        },
        {
          label: 'Główny oddział zagranicznego zakładu ubezpieczeń',
          code: 'P1L1',
        },
        {
          label: 'Otwarty fundusz emerytalny (OFE)',
          code: 'QUX1',
        },
        {
          label: 'Placówki systemu oświaty',
          code: 'QYL4',
        },
        {
          label: 'Fundusz inwestycyjny',
          code: 'RBHP',
        },
        {
          label: 'Oddział przedsiębiorcy zagranicznego',
          code: 'RUCO',
        },
        {
          label: 'Przedsiębiorstwo państwowe',
          code: 'SMIS',
        },
        {
          label: 'Kościoł i związki wyznaniowe',
          code: 'SP4S',
        },
        {
          label:
            'Fundusze inne niż inwestycyjne i inne niż otwarte fundusze emerytalne',
          code: 'SVA3',
        },
        {
          label: 'Wspólnoty samorządowe',
          code: 'T7PB',
        },
        {
          label: 'Towarzystwo ubezpieczeń wzajemnych (TUW)',
          code: 'WNX1',
        },
        {
          label: 'Związki inne niż zawodowe',
          code: 'WOK7',
        },
        {
          label: 'Uczelnia',
          code: 'WUJ2',
        },
        {
          label: 'Spółka cywilna (sc)',
          code: 'YLZL',
        },
        {
          label: 'Osoby fizyczne prowadzące działalność gospodarczą',
          code: 'ZVVM',
        },
        {
          label: 'Stowarzyszenie i związki stowarzyszeń',
          code: 'ZZKE',
        },
      ],
    },
  ],
  PR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Low Profit LLC',
          code: '1TG4',
        },
        {
          label: 'Limited Liability Company',
          code: '35RF',
        },
        {
          label: 'Consumer Cooperative',
          code: '87N3',
        },
        {
          label: 'Bank',
          code: '9EGO',
        },
        {
          label: 'Municipal Enterprise',
          code: 'D8XQ',
        },
        {
          label: 'Professional Corporation',
          code: 'DE7H',
        },
        {
          label: 'Special Employee Owned Corporation',
          code: 'EQQP',
        },
        {
          label: 'International Financial Entity',
          code: 'GCP3',
        },
        {
          label: 'International Banking Center Corporation',
          code: 'GL33',
        },
        {
          label: 'Workers Cooperative',
          code: 'GRH5',
        },
        {
          label: 'Savings & Credit Cooperative',
          code: 'I6FV',
        },
        {
          label: 'Trust',
          code: 'JJ1V',
        },
        {
          label: 'Public Benefit Corporation',
          code: 'ME4Y',
        },
        {
          label: 'Youth Cooperative',
          code: 'O2JM',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'O4QG',
        },
        {
          label: 'Commercial Bank',
          code: 'O8XV',
        },
        {
          label: 'International Banking Center LLC',
          code: 'RA5B',
        },
        {
          label: 'Close Corporation',
          code: 'T2JS',
        },
        {
          label: 'Special Corporation for Municipal Development',
          code: 'TEGV',
        },
        {
          label: 'Bank',
          code: 'TPFL',
        },
        {
          label: 'Insurance Company',
          code: 'VKSV',
        },
        {
          label: 'International Financial Entity',
          code: 'W6NI',
        },
        {
          label: 'Sponsored By Municipal Corporation',
          code: 'Y21X',
        },
        {
          label: 'Corporation',
          code: 'YS16',
        },
      ],
    },
  ],
  PT: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Cooperativa',
          code: '1HGD',
        },
        {
          label: 'Cooperativa de 2º Grau',
          code: '5KVH',
        },
        {
          label: 'Representação Permanente',
          code: '68PD',
        },
        {
          label: 'Entidade Empresarial Intermunicipal',
          code: '6IK8',
        },
        {
          label: 'Fundo',
          code: '6L6P',
        },
        {
          label: 'Agrupamento Europeu de Interesse Económico (AEIE)',
          code: '99JD',
        },
        {
          label: 'Entidade Pública Empresarial',
          code: 'A8CT',
        },
        {
          label: 'Associação',
          code: 'ALPT',
        },
        {
          label: 'Entidade Empresarial Municipal',
          code: 'D7OA',
        },
        {
          label: 'Sociedade Anónima',
          code: 'DFE5',
        },
        {
          label: 'Empresa Intermunicipal',
          code: 'IX01',
        },
        {
          label: 'Organismo da Administração Pública',
          code: 'KUUV',
        },
        {
          label: 'Sociedade Anónima Europeia (SE)',
          code: 'MFHR',
        },
        {
          label: 'Sociedade em Nome Coletivo',
          code: 'N66B',
        },
        {
          label: 'Entidade Empresarial Metropolitana',
          code: 'NIQY',
        },
        {
          label: 'Sociedade em Comandita',
          code: 'OXUC',
        },
        {
          label: 'Pessoa Coletiva de Direito Público',
          code: 'P5S3',
        },
        {
          label: 'Empresa Municipal',
          code: 'PIDC',
        },
        {
          label: 'Pessoa Coletiva Internacional',
          code: 'QFXD',
        },
        {
          label: 'Sociedade por Quotas',
          code: 'USOG',
        },
        {
          label: 'Societas Cooperativa Europaea (SCE)',
          code: 'V6YL',
        },
        {
          label: 'Sociedade Unipessoal por Quotas',
          code: 'VF4C',
        },
        {
          label: 'Sociedade Anónima Desportiva',
          code: 'W9W3',
        },
        {
          label: 'Empresa Metropolitana',
          code: 'XD16',
        },
        {
          label: 'Fundo',
          code: 'XMXM',
        },
        {
          label: 'Agrupamento Complementar de Empresas',
          code: 'YMLD',
        },
        {
          label: 'Fundação',
          code: 'Z0NE',
        },
        {
          label: 'Estabelecimento Individual de Responsabilidade Limitada',
          code: 'ZILA',
        },
        {
          label: 'Empresa Regional',
          code: 'ZSWE',
        },
      ],
    },
    {
      subdivision_label: 'Madeira',
      subdivision_code: 'PT-30',
      legal_forms: [
        {
          label: 'Trust',
          code: 'IKBN',
        },
        {
          label: 'Sucursal Financeira Exterior',
          code: 'Z7ZX',
        },
      ],
    },
  ],
  PY: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Empresa Unipersonal De Responsabilidad Limitada (EIRL)',
          code: '48X2',
        },
        {
          label: 'Sociedad En Comandita Simple (SC)',
          code: '4QBB',
        },
        {
          label: 'Empresa Unipersonal (EU)',
          code: '78SG',
        },
        {
          label: 'Sociedad Anonima (SA)',
          code: 'IJAO',
        },
        {
          label: 'Sociedad De Responsabilidad Limitada (SRL)',
          code: 'K08V',
        },
        {
          label: 'Sociedad En Comandita Por Acciones (SCA)',
          code: 'LNG8',
        },
      ],
    },
  ],
  QA: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sharika That Almasuliah Almahduda (ذمم)',
          code: 'ADV0',
        },
        {
          label: 'Sharikat Almahasah',
          code: 'B4L0',
        },
        {
          label: 'Sharikat Altawsia Albasita',
          code: 'KHBL',
        },
        {
          label: 'Sharikat Altadamun',
          code: 'OR18',
        },
        {
          label: 'Sharikat Almusahama Alaamah (شمع)',
          code: 'QZ84',
        },
        {
          label: "Sharikat Altawsia Blas'hoom",
          code: 'RN1W',
        },
        {
          label: 'Sharikat Almusahama Alkhasa (شمخ)',
          code: 'W50Q',
        },
      ],
    },
  ],
  RO: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Societate europeană (SE)',
          code: '1CDX',
        },
        {
          label: 'Cooperativă meșteșugărească',
          code: '37II',
        },
        {
          label: 'Grupul european de interes economic (GEIE)',
          code: '4V0A',
        },
        {
          label: 'Organizație non-guvernamentală (ONG)',
          code: '5NCK',
        },
        {
          label: 'Societate cooperativă',
          code: '7B9H',
        },
        {
          label:
            'Organizații cooperatiste de credit și casele centrale ale acestora',
          code: '7W9B',
        },
        {
          label: 'Reprezentanta',
          code: '85K5',
        },
        {
          label: 'Cooperativă de consum',
          code: '8FD2',
        },
        {
          label: 'Persoana fizica independentă (PFI)',
          code: 'AI01',
        },
        {
          label: 'Societate pe acţiuni (SA )',
          code: 'ANDT',
        },
        {
          label: 'Alte forme de proprietate',
          code: 'CTBD',
        },
        {
          label: 'Regie autonomă (RA)',
          code: 'CUGP',
        },
        {
          label: 'Intreprindere individuala (II)',
          code: 'DUGD',
        },
        {
          label: 'Grupul de interes economic (GIE)',
          code: 'EFBR',
        },
        {
          label: 'Societate agricolă',
          code: 'F0BK',
        },
        {
          label: 'Societate în nume colectiv (SNC)',
          code: 'F68H',
        },
        {
          label: 'Persoana fizica autorizata (PFA)',
          code: 'FJQ8',
        },
        {
          label: 'Cooperativă de credit',
          code: 'GT4V',
        },
        {
          label: 'Cooperativă agricolă',
          code: 'I15K',
        },
        {
          label: 'Sucursala',
          code: 'I9BB',
        },
        {
          label: 'Reprezentant fiscal',
          code: 'NFRJ',
        },
        {
          label: 'Societate în comandită pe acţiuni (SCA)',
          code: 'SWMK',
        },
        {
          label: 'Asociatie familiala (AF)',
          code: 'TH8A',
        },
        {
          label: 'Societate Cooperativă Europeană (SCE)',
          code: 'UWEE',
        },
        {
          label: 'Societate în comandită simplă (SCS)',
          code: 'V0FL',
        },
        {
          label: 'Societate cu răspundere limitată (SRL)',
          code: 'XHN1',
        },
      ],
    },
  ],
  RS: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Zadružni savez',
          code: '6UPM',
        },
        {
          label: 'Komanditno društvo (kd)',
          code: '73PZ',
        },
        {
          label: 'Društveno preduzeće (DP)',
          code: '88TX',
        },
        {
          label: 'Preduzetnik (PR)',
          code: '9O67',
        },
        {
          label: 'Javno preduzeće (JP)',
          code: 'DEF6',
        },
        {
          label: 'Zadruga',
          code: 'E7W8',
        },
        {
          label: 'Društvo sa ograničenom odgovornošću (doo)',
          code: 'KIU5',
        },
        {
          label: 'Akcionarsko društvo (ad)',
          code: 'YVPW',
        },
        {
          label: 'Ortačko društvo (od)',
          code: 'ZPOY',
        },
      ],
    },
  ],
  RU: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Obyedineniya',
          code: '15A2',
        },
        {
          label: "Obshchestva s ogranichennoy otvetstvennost'yu",
          code: '17R7',
        },
        {
          label: "Krest'yanskiye",
          code: '1MJ0',
        },
        {
          label: 'Avtonomnyye nekommercheskiye organizatsii',
          code: '1RJ2',
        },
        {
          label: "Blagotvoritel'nyye uchrezhdeniya",
          code: '1U7Y',
        },
        {
          label: 'Soyuzy',
          code: '24TU',
        },
        {
          label: 'Soyuzy',
          code: '2H36',
        },
        {
          label: "Munitsipal'nyye avtonomnyye uchrezhdeniya",
          code: '2OTW',
        },
        {
          label: "Tovarishchestva sobstvennikov zhil'ya",
          code: '2R46',
        },
        {
          label: "Federal'nyye kazennyye predpriyatiya",
          code: '37YG',
        },
        {
          label: "Sel'skokhozyaystvennyye proizvodstvennyye kooperativy",
          code: '3AMJ',
        },
        {
          label: 'Proizvodstvennyye kooperativy',
          code: '3EAX',
        },
        {
          label: 'Uchrezhdeniya',
          code: '3F98',
        },
        {
          label: 'Profsoyuznyye organizatsii',
          code: '3TOV',
        },
        {
          label:
            'Prochiye yuridicheskiye litsa, yavlyayushchiyesya kommercheskimi organizatsiyami',
          code: '3U8X',
        },
        {
          label: 'Uchrezhdeniya, sozdannyye subyektom Rossiyskoy Federatsii',
          code: '4PZX',
        },
        {
          label: 'Publichnyye aktsionernyye obshchestva',
          code: '4TYO',
        },
        {
          label: 'Assotsiatsii',
          code: '4VTZ',
        },
        {
          label: 'Advokaty, uchredivshiye advokatskiy kabinet',
          code: '5VMR',
        },
        {
          label:
            "Sel'skokhozyaystvennyye potrebitel'skiye zhivotnovodcheskiye kooperativy",
          code: '6H83',
        },
        {
          label: "Zhilishchnyye ili zhilishchno-stroitel'nyye kooperativy",
          code: '8B9A',
        },
        {
          label: 'Gosudarstvennyye akademii nauk',
          code: '8JWV',
        },
        {
          label:
            'Gosudarstvennyye byudzhetnyye uchrezhdeniya subyektov Rossiyskoy Federatsii',
          code: '8MQM',
        },
        {
          label: "Federal'nyye gosudarstvennyye byudzhetnyye uchrezhdeniya",
          code: '8NF7',
        },
        {
          label:
            "Sadovodcheskiye, ogorodnicheskiye ili dachnyye potrebitel'skiye kooperativy",
          code: '8NZE',
        },
        {
          label: 'Publichno-pravovyye kompanii',
          code: '8RIH',
        },
        {
          label:
            "Sovety munitsipal'nykh obrazovaniy subyektov Rossiyskoy Federatsii",
          code: '8XDD',
        },
        {
          label: "Central'nyj bank",
          code: '9M15',
        },
        {
          label:
            'Gosudarstvennyye unitarnyye predpriyatiya subyektov Rossiyskoy Federatsii',
          code: '9RQZ',
        },
        {
          label: 'Advokatskiye byuro',
          code: 'AS78',
        },
        {
          label:
            "Kazach'i obshchestva, vnesennyye v gosudarstvennyy reyestr kazach'ikh obshchestv v Rossiyskoy Federatsii",
          code: 'B5GH',
        },
        {
          label: "Kreditnyye potrebitel'skiye kooperativy",
          code: 'BLVV',
        },
        {
          label: "Garazhnyye i garazhno-stroitel'nyye kooperativy",
          code: 'BQ3A',
        },
        {
          label: "Organizatsionno-pravovyye formy dlya deyatel'nosti grazhdan",
          code: 'BZQL',
        },
        {
          label:
            'Organizatsionno-pravovyyу formy yuridicheskikh lits, yavlyayushchikhsya kommercheskimi korporativnymi organizatsiyami',
          code: 'C3RQ',
        },
        {
          label: 'Assotsiatsii',
          code: 'C7TI',
        },
        {
          label: 'Nekommercheskiye partnerstva',
          code: 'CKTA',
        },
        {
          label: 'Fondy prokata',
          code: 'CN7I',
        },
        {
          label: 'Ekologicheskiye fondy',
          code: 'D094',
        },
        {
          label: 'Obshchestvennyye organizatsii',
          code: 'D84J',
        },
        {
          label: 'Gosudarstvennyye kompanii',
          code: 'DO25',
        },
        {
          label: 'Kazennyye predpriyatiya subyektov Rossiyskoy Federatsii',
          code: 'DZSY',
        },
        {
          label: 'Gosudarstvennyye korporatsii',
          code: 'E0VI',
        },
        {
          label: 'Obshchestvennyye fondy',
          code: 'EH3M',
        },
        {
          label:
            "Organizatsionno-pravovyye formy dlya kommercheskoy deyatel'nosti grazhdan",
          code: 'EWQF',
        },
        {
          label: "Munitsipal'nyye byudzhetnyye uchrezhdeniya",
          code: 'FWGK',
        },
        {
          label: 'Proizvodstvennyye kooperativy',
          code: 'FZKX',
        },
        {
          label: "Territorial'nyye obshchestvennyye samoupravleniya",
          code: 'G2CW',
        },
        {
          label:
            "Sel'skokhozyaystvennyye potrebitel'skiye ogorodnicheskiye kooperativy",
          code: 'GHC5',
        },
        {
          label: 'Uchrezhdeniya, sozdannyye Rossiyskoy Federatsiyey',
          code: 'GQRJ',
        },
        {
          label:
            'Obshchiny korennykh malochislennykh narodov Rossiyskoy Federatsii',
          code: 'GWHK',
        },
        {
          label:
            'Strukturnyye podrazdeleniya obosoblennykh podrazdeleniy yuridicheskikh lits',
          code: 'GZF9',
        },
        {
          label: 'Tovarishchestva sobstvennikov nedvizhimosti',
          code: 'H2AF',
        },
        {
          label: 'Kreditnyye kooperativy vtorogo urovnya',
          code: 'H2P4',
        },
        {
          label: 'Advokatskiye palaty',
          code: 'H4NY',
        },
        {
          label: "Federal'nyye gosudarstvennyye avtonomnyye uchrezhdeniya",
          code: 'HKKL',
        },
        {
          label: 'Religioznyye organizatsii',
          code: 'HUET',
        },
        {
          label:
            "Sel'skokhozyaystvennyye potrebitel'skiye obsluzhivayushchiye kooperativy",
          code: 'HUR1',
        },
        {
          label:
            "Sel'skokhozyaystvennyye potrebitel'skiye pererabatyvayushchiye kooperativy",
          code: 'I9O1',
        },
        {
          label:
            'Organizatsionno-pravovyye formy yuridicheskikh lits, yavlyayushchikhsya nekommercheskimi unitarnymi organizatsiyami',
          code: 'I9V2',
        },
        {
          label:
            "Sel'skokhozyaystvennyye potrebitel'skiye snabzhencheskiye kooperativy",
          code: 'IBSS',
        },
        {
          label: "Sel'skokhozyaystvennyye arteli",
          code: 'IC46',
        },
        {
          label: 'Samoreguliruyemyye organizatsii',
          code: 'ILY0',
        },
        {
          label: 'Tovarishchestva na vere',
          code: 'J18N',
        },
        {
          label: 'Prostyye tovarishchestva',
          code: 'J7NW',
        },
        {
          label:
            'Organizatsionno-pravovyye formy organizatsiy, sozdannykh bez prav yuridicheskogo litsa',
          code: 'JPMQ',
        },
        {
          label: 'Nepublichnyye aktsionernyye obshchestva',
          code: 'JZBN',
        },
        {
          label: 'Khozyaystvennyye tovarishchestva',
          code: 'KLA2',
        },
        {
          label: "Glavy krest'yanskikh",
          code: 'KOYR',
        },
        {
          label: "Organy obshchestvennoy samodeyatel'nosti",
          code: 'KVSH',
        },
        {
          label: 'Fondy',
          code: 'KZC6',
        },
        {
          label:
            "Otdeleniya inostrannykh nekommercheskikh nepravitel'stvennykh organizatsiy",
          code: 'LLL8',
        },
        {
          label: "Blagotvoritel'nyye fondy",
          code: 'LYTH',
        },
        {
          label:
            'Gosudarstvennyye avtonomnyye uchrezhdeniya subyektov Rossiyskoy Federatsii',
          code: 'MG22',
        },
        {
          label: 'Otkrytoye aktsionernoye obshchestvo',
          code: 'MT3A',
        },
        {
          label: "Potrebitel'skiye obshchestva",
          code: 'MV1C',
        },
        {
          label: 'Obyedineniya fermerskikh khozyaystv',
          code: 'MVSF',
        },
        {
          label: 'Obshchestvennyye uchrezhdeniya',
          code: 'NRK9',
        },
        {
          label: 'Politicheskiye partii',
          code: 'NZ85',
        },
        {
          label: "Sel'skokhozyaystvennyye potrebitel'skiye sbytovyye",
          code: 'O1KO',
        },
        {
          label: "Federal'nyye gosudarstvennyye unitarnyye predpriyatiya",
          code: 'OCIS',
        },
        {
          label: "Predstavitel'stva yuridicheskikh lits",
          code: 'OPLQ',
        },
        {
          label:
            'Unitarnyye predpriyatiya, osnovannyye na prave operativnogo upravleniya',
          code: 'OVLN',
        },
        {
          label: "Individual'nyye predprinimateli",
          code: 'P9CF',
        },
        {
          label: 'Assotsiatsii',
          code: 'PA5X',
        },
        {
          label:
            "Sel'skokhozyaystvennyye potrebitel'skiye sadovodcheskiye kooperativy",
          code: 'PDYW',
        },
        {
          label: "Notarial'nyye palaty",
          code: 'PJRZ',
        },
        {
          label: "Mezhpravitel'stvennyye mezhdunarodnyye organizatsii",
          code: 'Q9FA',
        },
        {
          label: 'Khozyaystvennyye obshchestva',
          code: 'QCRH',
        },
        {
          label: "Potrebitel'skiye kooperativy",
          code: 'QFGM',
        },
        {
          label: "Uchrezhdeniya, sozdannyye munitsipal'nym obrazovaniyem",
          code: 'QKFU',
        },
        {
          label: 'Obshchestva vzaimnogo strakhovaniya',
          code: 'QMEH',
        },
        {
          label: 'Organizatsionno-pravovyye formy mezhdunarodnykh organizatsiy',
          code: 'QQS1',
        },
        {
          label: 'Notariusy, zanimayushchiyesya chastnoy praktikoy',
          code: 'QSXT',
        },
        {
          label:
            'Unitarnyye predpriyatiya, osnovannyye na prave khozyaystvennogo vedeniya',
          code: 'QUMQ',
        },
        {
          label: 'Aktsionernyye obshchestva',
          code: 'R71T',
        },
        {
          label:
            'Sadovodcheskiye, ogorodnicheskiye ili dachnyye nekommercheskiye tovarishchestva',
          code: 'RJUK',
        },
        {
          label: 'Obyedineniya rabotodateley',
          code: 'SDVV',
        },
        {
          label:
            "Organizatsionno-pravovyye formy dlya deyatel'nosti grazhdan, ne otnesennoy k predprinimatel'stvu",
          code: 'SIPP',
        },
        {
          label: "Soyuzy potrebitel'skikh obshchestv",
          code: 'TLDB',
        },
        {
          label: "Federal'nyye gosudarstvennyye kazennyye uchrezhdeniya",
          code: 'TMIG',
        },
        {
          label: 'Kollegii advokatov',
          code: 'UTBN',
        },
        {
          label: 'Rayonnyye sudy, gorodskiye sudy, mezhrayonnyye sudy',
          code: 'UY49',
        },
        {
          label: 'Rybolovetskiye arteli',
          code: 'VA0A',
        },
        {
          label: 'Obosoblennyye podrazdeleniya yuridicheskikh lits',
          code: 'VAB6',
        },
        {
          label:
            'Organizatsionno-pravovyye formy yuridicheskikh lits, yavlyayushchikhsya nekommercheskimi korporativnymi organizatsiyami',
          code: 'VE1P',
        },
        {
          label: 'Unitarnyye predpriyatiya',
          code: 'VEOU',
        },
        {
          label: "Munitsipal'nyye kazennyye uchrezhdeniya",
          code: 'VEX5',
        },
        {
          label: 'Filialy yuridicheskikh lits',
          code: 'VIGZ',
        },
        {
          label:
            'Organizatsionno-pravovyye formy yuridicheskikh lits, yavlyayushchikhsya kommercheskimi unitarnymi organizatsiyami',
          code: 'VOUU',
        },
        {
          label: "Zhilishchnyye nakopitel'nyye kooperativy",
          code: 'WJXS',
        },
        {
          label: 'Soyuzy',
          code: 'WYNO',
        },
        {
          label: "Nepravitel'stvennyye mezhdunarodnyye organizatsii",
          code: 'XBRI',
        },
        {
          label: "Munitsipal'nyye kazennyye predpriyatiya",
          code: 'XIGI',
        },
        {
          label: 'Kooperativnyye khozyaystva',
          code: 'XINV',
        },
        {
          label: 'Soyuzy',
          code: 'XJJR',
        },
        {
          label: "Kreditnyye potrebitel'skiye kooperativy grazhdan",
          code: 'XNKN',
        },
        {
          label: 'Chastnyye uchrezhdeniya',
          code: 'YA7H',
        },
        {
          label: 'Payevyye investitsionnyye fondy',
          code: 'YGHZ',
        },
        {
          label: 'Obshchestvennyye dvizheniya',
          code: 'YJ6A',
        },
        {
          label: 'Negosudarstvennyye pensionnyye fondy',
          code: 'Z2AQ',
        },
        {
          label: "Munitsipal'nyye unitarnyye predpriyatiya",
          code: 'Z3SH',
        },
        {
          label:
            'Sadovodcheskiye, ogorodnicheskiye ili dachnyye nekommercheskiye partnerstva',
          code: 'Z3Y0',
        },
        {
          label: 'Torgovo-promyshlennyye palaty',
          code: 'ZGYA',
        },
        {
          label: 'Khozyaystvennyye partnerstva',
          code: 'ZH3O',
        },
        {
          label: 'Polnyye tovarishchestva',
          code: 'ZKAF',
        },
        {
          label:
            'Gosudarstvennyye kazennyye uchrezhdeniya subyektov Rossiyskoy Federatsii',
          code: 'ZNHF',
        },
      ],
    },
  ],
  SA: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Sharikat musahama',
          code: '78VH',
        },
        {
          label: "Sharikat 'ashkhas",
          code: 'B3JS',
        },
        {
          label: "Sharikat tawsiat bial'ashum",
          code: 'DW9N',
        },
        {
          label: 'Sharikat dhat ras mal qabil liltaghyir',
          code: 'N10C',
        },
        {
          label: "Sharikat 'amwal",
          code: 'PT6M',
        },
        {
          label: 'Sharikat altadamun',
          code: 'T2NA',
        },
        {
          label: 'Sharakah dhat maswuwliat mahduda',
          code: 'TTE8',
        },
        {
          label: 'Sharakah tawsiat basita',
          code: 'WHCI',
        },
        {
          label: 'Sharikat muhasa',
          code: 'YVGA',
        },
        {
          label: "Sharikat 'ajnabia",
          code: 'Z9IE',
        },
      ],
    },
  ],
  SB: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Private Company',
          code: '8PIA',
        },
        {
          label: 'Community Company',
          code: 'A7VA',
        },
        {
          label: 'Overseas Company',
          code: 'YSBJ',
        },
        {
          label: 'Public Company',
          code: 'Z4ZP',
        },
      ],
    },
  ],
  SC: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Foundation',
          code: '3YUS',
        },
        {
          label: 'Company Limited by Shares',
          code: '6MS4',
        },
        {
          label: 'International Company Limited by Shares (IBC)',
          code: '7QEH',
        },
        {
          label: 'Special License Company (CSL)',
          code: '7XUS',
        },
        {
          label: 'International Trust',
          code: 'BB5Y',
        },
        {
          label: 'Company Limited by Guarantee',
          code: 'CDYY',
        },
        {
          label: 'Company Limited by Shares and Guarantee',
          code: 'E3YL',
        },
        {
          label: 'International Protected Cell Company (IBC)',
          code: 'G6M8',
        },
        {
          label: 'Public Company',
          code: 'H35I',
        },
        {
          label: 'Proprietary Company',
          code: 'H987',
        },
        {
          label: 'International Company Limited by Shares and Guarantee (IBC)',
          code: 'KGUS',
        },
        {
          label: 'International Company Limited by Guarantee (IBC)',
          code: 'KOFC',
        },
        {
          label: 'Unlimited Company',
          code: 'P3YJ',
        },
        {
          label: 'Protected Cell Company (PCC)',
          code: 'Q9VK',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'VA1X',
        },
        {
          label: 'International Limited Life Company (IBC)',
          code: 'WKFR',
        },
      ],
    },
  ],
  SE: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Ideell förening (I)',
          code: '1TN0',
        },
        {
          label: 'Trossamfund (TSF)',
          code: '2UAX',
        },
        {
          label: 'Medlemsbank (MB)',
          code: '381R',
        },
        {
          label: 'Europabolag (SE)',
          code: '54P7',
        },
        {
          label: 'Europakooperativ (SCE)',
          code: '9YIP',
        },
        {
          label: 'Sambruksförening (SF)',
          code: 'AZTO',
        },
        {
          label: 'Handelsbolag (HB)',
          code: 'BEAY',
        },
        {
          label: 'Bankaktiebolag (BAB)',
          code: 'BYQJ',
        },
        {
          label: 'Ekonomisk förening (Ek för)',
          code: 'C61P',
        },
        {
          label: 'Kommanditbolag (KB)',
          code: 'CX05',
        },
        {
          label: 'Sparbank (SB)',
          code: 'G04R',
        },
        {
          label: 'Försäkringsförening (FOF)',
          code: 'M0Y0',
        },
        {
          label: 'Ömsesidigt försäkringsbolag (OFB)',
          code: 'O1QI',
        },
        {
          label: 'Försäkringsaktiebolag (FAB)',
          code: 'OJ9I',
        },
        {
          label: 'Kooperativ hyresrättsförening (KHF)',
          code: 'PDQ0',
        },
        {
          label: 'Europeisk ekonomisk intressegruppering (EEIG)',
          code: 'RLJO',
        },
        {
          label: 'Europeisk gruppering för territoriellt samarbete (EGTS)',
          code: 'RYFP',
        },
        {
          label: 'Bostadsrättsförening (BRF)',
          code: 'SSOM',
        },
        {
          label: 'Bostadsförening',
          code: 'WZDB',
        },
        {
          label: 'Aktiebolag (AB)',
          code: 'XJHM',
        },
      ],
    },
  ],
  SG: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Limited Partnership',
          code: '17FJ',
        },
        {
          label: 'Business',
          code: '2PHU',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '5SGV',
        },
        {
          label: 'Company',
          code: 'KEMH',
        },
        {
          label: 'Company (Ltd)',
          code: 'LWXI',
        },
      ],
    },
  ],
  SI: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Javna agencija',
          code: '14AT',
        },
        {
          label: 'Delniška družba (dd)',
          code: '1OR3',
        },
        {
          label: 'Zadruga',
          code: '2CTG',
        },
        {
          label: 'Centralna banka',
          code: '34PA',
        },
        {
          label: 'Evropska delniška družba (SE)',
          code: '4A8T',
        },
        {
          label: 'Verska skupnost in podobne verske organizacije',
          code: '5LXY',
        },
        {
          label: 'Ustanova',
          code: '6MPY',
        },
        {
          label: 'Stanovanjska zadruga',
          code: '8ER6',
        },
        {
          label: 'Gospodarska zbornica',
          code: 'D64E',
        },
        {
          label: 'Zadruga v zasebni lasti',
          code: 'E01X',
        },
        {
          label: 'Društvo, zveza društev',
          code: 'HJCS',
        },
        {
          label: 'Javno podjetje',
          code: 'I7MN',
        },
        {
          label: 'Družba za vzajemno zavarovanje (dvz)',
          code: 'ICQT',
        },
        {
          label: 'Kmetijska zadruga',
          code: 'JQA2',
        },
        {
          label: 'Družba z omejeno odgovornostjo (doo)',
          code: 'KBKD',
        },
        {
          label: 'Skupnost lastnikov stanovanj',
          code: 'KH4O',
        },
        {
          label: 'Obrtna zadruga',
          code: 'LNYR',
        },
        {
          label: 'Sklad',
          code: 'M84A',
        },
        {
          label: 'Komanditna družba (kd)',
          code: 'OPDJ',
        },
        {
          label: 'Evropsko gospodarsko interesno združenje (EGIZ)',
          code: 'Q2EV',
        },
        {
          label: 'Tuja pravna oseba po ZZK-1',
          code: 'QUEA',
        },
        {
          label: 'Samostojni podjetnik posameznik (sp)',
          code: 'QW8S',
        },
        {
          label: 'Družbeno podjetje',
          code: 'QYQ5',
        },
        {
          label: 'Zadruga z omejeno odgovornostjo (zoo)',
          code: 'RAX7',
        },
        {
          label: 'Societas Cooperativa Europaea (SCE)',
          code: 'RG9Z',
        },
        {
          label: 'Civilna odvetniška družba',
          code: 'S1UI',
        },
        {
          label: 'Družba z neomejeno odgovornostjo (dno)',
          code: 'SD81',
        },
        {
          label: 'Zadruga brez odgovornosti (zbo)',
          code: 'TMVE',
        },
        {
          label: 'Gospodarsko interesno združenje (GIZ)',
          code: 'UDQJ',
        },
        {
          label: 'Javni sklad',
          code: 'UESF',
        },
        {
          label: 'Dobrodelna organizacija',
          code: 'UG0E',
        },
        {
          label: 'Komanditna delniška družba (kdd)',
          code: 'WZ78',
        },
        {
          label: 'Republika Slovenija',
          code: 'X94Z',
        },
      ],
    },
  ],
  SK: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Rozpočtové a príspevkové organizácie',
          code: '14NU',
        },
        {
          label: 'Špecializovaná organizačná jednotka ČSD',
          code: '189Q',
        },
        {
          label: 'Obecný podnik',
          code: '1CSW',
        },
        {
          label: 'Jednotka železničného správneho úradu',
          code: '1GLU',
        },
        {
          label: 'Rozpočtová organizácia',
          code: '1HEP',
        },
        {
          label: 'Zahraničosoba,právnická',
          code: '1K73',
        },
        {
          label: 'Zahraničná osoba',
          code: '1W8B',
        },
        {
          label: 'Akciová spoločnosť (Akc spol)',
          code: '2EEG',
        },
        {
          label: 'Štátny podnik v oddieli Pšn',
          code: '2KMA',
        },
        {
          label: 'Komoditná burza',
          code: '2XNE',
        },
        {
          label: 'Družstevný podnik',
          code: '3P3W',
        },
        {
          label: 'Príspevková organizácia',
          code: '43MZ',
        },
        {
          label: 'Pobočka sporiteľne',
          code: '46CD',
        },
        {
          label: 'Krajský úrad',
          code: '4CKX',
        },
        {
          label: 'Národný výbor',
          code: '4GX1',
        },
        {
          label: 'Zastuporginých štátov',
          code: '5AM3',
        },
        {
          label: 'Verejná obchodná spoločnosť (Ver obch spol)',
          code: '5J5Y',
        },
        {
          label: 'Stavovská organizácia - profesná komora',
          code: '5YBL',
        },
        {
          label:
            'Samostatne hospodáriaci roľník zapísaný v obchodnom registri (SHR roľník v OR)',
          code: '5Z8W',
        },
        {
          label:
            'Samostatne hospodáriaci roľník nezapísaný v obchodnom registri (SHR roľník)',
          code: '6R72',
        },
        {
          label: 'Stredná škola',
          code: '72R7',
        },
        {
          label: 'Samosprávny kraj',
          code: '73MQ',
        },
        {
          label: 'Fondy',
          code: '7EG2',
        },
        {
          label: 'Európske družstvo (SCE)',
          code: '7O89',
        },
        {
          label: 'Zastúpzahrprávosoby',
          code: '7QSE',
        },
        {
          label: 'Jedzboru ochrany žel',
          code: '8KHD',
        },
        {
          label: 'Družstevný podnik poľ',
          code: '8RE3',
        },
        {
          label: 'Orgjednpolstrany,hn',
          code: '8UE6',
        },
        {
          label: 'Sociál,zdravpoisťovňa',
          code: '90YN',
        },
        {
          label: 'Živ a SHR roľník v OR',
          code: '93WZ',
        },
        {
          label: 'FO-slobpovolanie',
          code: '95MS',
        },
        {
          label: 'Poľnohospodárdružstvo',
          code: '9L9V',
        },
        {
          label: 'Neinvestičný fond',
          code: '9NSV',
        },
        {
          label: 'Jednštátželtechinš',
          code: 'AGSR',
        },
        {
          label: 'Oblast závod poisťovne',
          code: 'BKEB',
        },
        {
          label: 'Európska spoločnosť (SE)',
          code: 'BUMI',
        },
        {
          label: 'Živnostník',
          code: 'C4PZ',
        },
        {
          label: 'Štáthosporgriadokr',
          code: 'CS8B',
        },
        {
          label: 'Jednoduchá spol na akcie',
          code: 'D18J',
        },
        {
          label: 'Základná škola',
          code: 'DAQC',
        },
        {
          label: 'Medzinár org a združ',
          code: 'E1VK',
        },
        {
          label: 'Bytové družstvo',
          code: 'E4CI',
        },
        {
          label: 'Zahraničosoba,fyzická',
          code: 'E9SH',
        },
        {
          label: 'Poisťovací spolok',
          code: 'ED84',
        },
        {
          label: 'Orgjednotka združenia',
          code: 'ER02',
        },
        {
          label: 'Spoločná záujorgdruž',
          code: 'F560',
        },
        {
          label: 'FO v RDIS',
          code: 'FDVU',
        },
        {
          label: 'Iné pracvysokej školy',
          code: 'FMUC',
        },
        {
          label: 'Účelová zahr-obchorg',
          code: 'FQRE',
        },
        {
          label: 'Poisťovna - a s',
          code: 'GL07',
        },
        {
          label: 'Cirkevná organizácia',
          code: 'GY5R',
        },
        {
          label: 'Vysoká škola',
          code: 'HZG7',
        },
        {
          label: 'Banka-štátpeňažústav',
          code: 'HZI2',
        },
        {
          label: 'Družstvo',
          code: 'I7AS',
        },
        {
          label: 'Doplnková dôchodpoisť',
          code: 'ISCZ',
        },
        {
          label: 'Výrobné družstvo',
          code: 'IW8Z',
        },
        {
          label: 'Podnik zahr osoby, org zložka',
          code: 'J8DW',
        },
        {
          label: 'Podnik alebo hospzar',
          code: 'JKC5',
        },
        {
          label: 'Kom spol',
          code: 'KB52',
        },
        {
          label: 'Združmedzinárobchodu',
          code: 'KVQU',
        },
        {
          label: 'Komora',
          code: 'KWFE',
        },
        {
          label: 'Poisťovňa - š p',
          code: 'KZ3R',
        },
        {
          label: 'Fakulta vysokej školy',
          code: 'L1M7',
        },
        {
          label: 'Obec mesto',
          code: 'L9WT',
        },
        {
          label: 'FO-slobpovolanie v OR',
          code: 'MSGT',
        },
        {
          label: 'Odštepný závod v OR',
          code: 'MY1S',
        },
        {
          label: 'Samostatne podnikajúca FO',
          code: 'N5MX',
        },
        {
          label: 'Orgzložka prísporg',
          code: 'N7I1',
        },
        {
          label: 'Pobočka štátpeňažúst',
          code: 'N9QW',
        },
        {
          label: 'Účelová org jedn ČSD',
          code: 'NC88',
        },
        {
          label: 'Spoločný podnik',
          code: 'NL31',
        },
        {
          label: 'Sporiteľna',
          code: 'NLI7',
        },
        {
          label: 'Zatiaľ neurčené',
          code: 'NO20',
        },
        {
          label: 'Nešpecifikovaná právna forma',
          code: 'O0Y2',
        },
        {
          label: 'Spoločný podnik',
          code: 'O7TC',
        },
        {
          label: 'Obecný úrad',
          code: 'OKAZ',
        },
        {
          label: 'Podnik alebo hospzar',
          code: 'P6XJ',
        },
        {
          label: 'Iná právnicka osoba',
          code: 'PX29',
        },
        {
          label: 'Spotrebné družstvo',
          code: 'QACV',
        },
        {
          label: 'Spol kom na akcie',
          code: 'QCJ9',
        },
        {
          label: 'Samost prevádzkareň OÚ',
          code: 'R9GT',
        },
        {
          label: 'Združenie',
          code: 'RF3D',
        },
        {
          label: 'Miestna jednotka bez právnej spôsobilosti',
          code: 'S5E1',
        },
        {
          label: 'Záujm združ právosôb',
          code: 'SIRY',
        },
        {
          label: 'Záujmové združenie',
          code: 'SMUS',
        },
        {
          label: 'Živnostník v OR',
          code: 'SPIE',
        },
        {
          label: 'Živa slpovolanie',
          code: 'SQ4I',
        },
        {
          label: 'Iné družstvo',
          code: 'SQFW',
        },
        {
          label: 'Národná banka Slovenska (NBS)',
          code: 'STUP',
        },
        {
          label: 'Záujmová organizácia družstiev',
          code: 'SUH3',
        },
        {
          label: 'Svojpomocné poľnohospodárske družstvo',
          code: 'T0KQ',
        },
        {
          label: 'Iná organizácia verejnej správy',
          code: 'TAV3',
        },
        {
          label:
            'Podnikateľ-fyzická osoba-nezapísv OR-podnikajúca súčasne ako samhosproľník',
          code: 'TG3X',
        },
        {
          label: 'Banka - akciová spoločnosť',
          code: 'U3QQ',
        },
        {
          label: 'Železnice SR',
          code: 'U5IM',
        },
        {
          label: 'Nadácia',
          code: 'U7QK',
        },
        {
          label: 'Oblastná organizačná jednotka ČSD',
          code: 'UCTO',
        },
        {
          label: 'Európske zoskupenie územnej spolupráce',
          code: 'VATN',
        },
        {
          label: 'Nezisková organizácia poskytujúca všeobecne prospešné služby',
          code: 'VRJP',
        },
        {
          label:
            'Zahraničné kultúrne, informačné stredisko, rozhlasová, tlačová a televízna agentúra',
          code: 'VSC1',
        },
        {
          label: 'Spoločnosť s ručením obmedzeným',
          code: 'VSZS',
        },
        {
          label:
            'Podnikateľ-fyzická osoba-zapísv OR-podnikajúca súčasne ako osoba so slobodným povolaním',
          code: 'WQCR',
        },
        {
          label: 'Politická strana, politické hnutie',
          code: 'XLXU',
        },
        {
          label: 'Štátny podnik',
          code: 'Y1X5',
        },
        {
          label: 'Podnik zahraničného obchodu (PZO)',
          code: 'Y45Q',
        },
        {
          label: 'Podnik so zahraničnou majetkovou účasťou',
          code: 'YB59',
        },
        {
          label: 'Verejnoprávna inštitúcia',
          code: 'YF99',
        },
        {
          label: 'Organizačná zložka podniku',
          code: 'YGN4',
        },
        {
          label: 'Nezisková organizácia',
          code: 'YRIU',
        },
        {
          label: 'Európske zoskupenie hospodárskych záujmov (EZHZ)',
          code: 'YVE8',
        },
        {
          label: 'Záujmové združenie fyzických osôb bez právnej spôsobilosti',
          code: 'YVWB',
        },
        {
          label: 'Spoločenstvá vlastníkov pozemkov, bytov a pod',
          code: 'ZG2I',
        },
      ],
    },
  ],
  SR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Stichting',
          code: '8MS6',
        },
        {
          label: 'Cooperatieve Vereniging',
          code: 'K4ZA',
        },
        {
          label: 'Filiaal of Bijkantoor',
          code: 'KJZ3',
        },
        {
          label: 'Commanditaire vennootschap (cv)',
          code: 'OBTO',
        },
        {
          label: 'Vereniging',
          code: 'QPC9',
        },
        {
          label: 'Commanditaire vennootschap (cv)',
          code: 'QWWY',
        },
        {
          label: 'Eenmanszaak',
          code: 'R6BI',
        },
        {
          label: 'Limited Company by Shares (nv)',
          code: 'TCVB',
        },
        {
          label: 'Vennootschap onder een firma (vof)',
          code: 'WBWC',
        },
      ],
    },
  ],
  SX: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Vereniging',
          code: '1CSZ',
        },
        {
          label: 'Besloten vennootschap (BV)',
          code: '2CC3',
        },
        {
          label: 'Commanditaire vennootschap op aandelen',
          code: '2UBX',
        },
        {
          label: 'Stichting particulier fonds (SPF)',
          code: '52AV',
        },
        {
          label: 'Eenmanszaak',
          code: '66T3',
        },
        {
          label: 'Openbare vennootschap',
          code: '8W32',
        },
        {
          label: 'Onderlinge waarborgmaatschappij (OWM)',
          code: 'B4OI',
        },
        {
          label: 'Aandeelhouder-bestuurde vennootschap (ABV)',
          code: 'FBX9',
        },
        {
          label: 'Stichting',
          code: 'FI9R',
        },
        {
          label: 'Coöperatie',
          code: 'NWT1',
        },
        {
          label: 'Naamloze vennootschap (NV)',
          code: 'SXX9',
        },
        {
          label: 'Commanditaire vennootschap (CV)',
          code: 'YXX0',
        },
      ],
    },
  ],
  TN: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Société unipersonnelle à responsabilité limitée (SUARL)',
          code: 'AF67',
        },
        {
          label: 'Entreprise individuelle',
          code: 'F502',
        },
        {
          label: 'Société à responsabilité limitée (SARL)',
          code: 'WSE1',
        },
        {
          label: 'Société anonyme (SA)',
          code: 'YVSL',
        },
      ],
    },
  ],
  TO: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Local Company',
          code: 'UZUP',
        },
        {
          label: 'Overseas Company',
          code: 'ZQEN',
        },
      ],
    },
  ],
  TR: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Fonlar',
          code: '5T3H',
        },
        {
          label: 'Kollektif şirket (KollŞti)',
          code: 'JMQU',
        },
        {
          label: 'Komandit şirket (KomŞti)',
          code: 'ONVA',
        },
        {
          label: 'Limited şirket (Ltd Şti)',
          code: 'R5UT',
        },
        {
          label: 'Anonim şirket (AŞ)',
          code: 'W2SQ',
        },
      ],
    },
  ],
  US: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Federal Savings Association (FSA)',
          code: '380O',
        },
        {
          label: 'Edge Act Corporation',
          code: '3MM4',
        },
        {
          label: 'National Bank (NA)',
          code: '62VJ',
        },
        {
          label: 'Mutual Savings and Loan Holding Company',
          code: 'Z7ER',
        },
      ],
    },
    {
      subdivision_label: 'Alabama',
      subdivision_code: 'US-AL',
      legal_forms: [
        {
          label: 'Registered Limited Liability Partnership (LLP)',
          code: 'CR3H',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'CWRI',
        },
        {
          label: 'Savings Bank',
          code: 'FIP8',
        },
        {
          label: 'For-Profit Corporation (Corp)',
          code: 'HFGV',
        },
        {
          label: 'Limited Partnership',
          code: 'HN8W',
        },
        {
          label: 'Savings and Loan Association',
          code: 'JPZW',
        },
        {
          label: 'Commercial Bank',
          code: 'QUNR',
        },
        {
          label: 'Non-Profit Corporation',
          code: 'V65U',
        },
        {
          label: 'Limited Liability Limited Partnership',
          code: 'WDT2',
        },
      ],
    },
    {
      subdivision_label: 'Alaska',
      subdivision_code: 'US-AK',
      legal_forms: [
        {
          label: 'Public Corporation',
          code: '7GTR',
        },
        {
          label: 'Commercial Bank',
          code: '7QKB',
        },
        {
          label: 'Religious Corporation',
          code: '9A4Q',
        },
        {
          label: 'Business Corporation (INC)',
          code: 'BRO8',
        },
        {
          label: 'Savings Bank',
          code: 'C0VE',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'D4YS',
        },
        {
          label: 'Sole Proprietorship',
          code: 'EQAQ',
        },
        {
          label: 'Non-Profit Corporation',
          code: 'K2BJ',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'M886',
        },
        {
          label: 'Professional Corporation (PC)',
          code: 'OVBT',
        },
        {
          label: 'Cooperative Corporation (COOP)',
          code: 'TRS2',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'VXDE',
        },
        {
          label: 'Partnership',
          code: 'WNKG',
        },
      ],
    },
    {
      subdivision_label: 'Arizona',
      subdivision_code: 'US-AZ',
      legal_forms: [
        {
          label: 'Commercial Bank',
          code: '1T9S',
        },
        {
          label: 'Professional Corporation',
          code: '5MNR',
        },
        {
          label: 'Corporation',
          code: '78H5',
        },
        {
          label: 'Limited Partnership',
          code: 'A9AW',
        },
        {
          label: 'Business Development Corporation',
          code: 'D8PB',
        },
        {
          label: 'Corporation',
          code: 'FGVH',
        },
        {
          label: 'Limited Liability Limited Partnership',
          code: 'IMDT',
        },
        {
          label: 'Corporation Sole',
          code: 'IVQQ',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'N69M',
        },
        {
          label: 'Limited Liability Company',
          code: 'O85W',
        },
        {
          label: 'Business Trust',
          code: 'P24O',
        },
        {
          label: 'Professional Limited Liability Company',
          code: 'QEEJ',
        },
        {
          label: 'Benefit Corporation',
          code: 'QUJ5',
        },
        {
          label: 'General Partnership',
          code: 'RSWJ',
        },
        {
          label: 'Electric Cooperative Nonprofit Membership Corporation',
          code: 'S68T',
        },
        {
          label: 'Cooperative Marketing Association',
          code: 'ST2Q',
        },
        {
          label:
            'Nonprofit Electric Generation and Transmission Cooperative Corporation',
          code: 'UGE3',
        },
        {
          label: 'Close Corporation',
          code: 'VKPN',
        },
      ],
    },
    {
      subdivision_label: 'Arkansas',
      subdivision_code: 'US-AR',
      legal_forms: [
        {
          label: 'Non-Profit Corporation',
          code: '1K9U',
        },
        {
          label: 'For-Profit Corporation (Inc)',
          code: 'EJX1',
        },
        {
          label: 'Savings Bank',
          code: 'GD6J',
        },
        {
          label: 'Commercial Bank',
          code: 'JR6U',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'KGZ8',
        },
        {
          label: 'Limited Liability Limited Partnership (LLLP)',
          code: 'L2DM',
        },
        {
          label: 'Limited Liability Company (Incorporated)',
          code: 'M4FO',
        },
        {
          label: 'Limited Partnership',
          code: 'MJJZ',
        },
      ],
    },
    {
      subdivision_label: 'California',
      subdivision_code: 'US-CA',
      legal_forms: [
        {
          label: 'Non-deposit Trust Company',
          code: '4JCS',
        },
        {
          label: 'Limited Partnership (LP)',
          code: '5HQ4',
        },
        {
          label: 'Industrial Bank',
          code: '5Y1L',
        },
        {
          label: 'For-Profit Corporation Close',
          code: '7CDL',
        },
        {
          label: 'Nonprofit Common Interest Development Association',
          code: 'BADE',
        },
        {
          label: 'Nonprofit Corporation Religious',
          code: 'CVXK',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'EI4J',
        },
        {
          label: 'Nonprofit Corporation Mutual Benefit',
          code: 'G1P6',
        },
        {
          label: 'For-Profit Corporation General Stock',
          code: 'H1UM',
        },
        {
          label: 'Nonprofit Corporation Public Benefit',
          code: 'K7YU',
        },
        {
          label: 'Commercial Bank',
          code: 'KQXA',
        },
        {
          label: 'Savings and Loan Association',
          code: 'LYRX',
        },
        {
          label: 'Savings Bank',
          code: 'N295',
        },
        {
          label: 'For-Profit Corporation Professional (PC)',
          code: 'PZR6',
        },
        {
          label: 'General Partnership',
          code: 'SQ7B',
        },
      ],
    },
    {
      subdivision_label: 'Colorado',
      subdivision_code: 'US-CO',
      legal_forms: [
        {
          label: 'Savings Bank',
          code: '1JXS',
        },
        {
          label: 'Limited Cooperative Association (LCA)',
          code: '81WV',
        },
        {
          label: 'Limited Partnership Association',
          code: 'BC32',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'HLCG',
        },
        {
          label: 'For-Profit Public Benefit Corporation (Inc)',
          code: 'I3Z9',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'L10T',
        },
        {
          label: 'Savings and Loan Association',
          code: 'LDHG',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'M64D',
        },
        {
          label: 'For-Profit Corporation (Inc)',
          code: 'OWR6',
        },
        {
          label: 'Non-Profit Corporation',
          code: 'PDLV',
        },
        {
          label: 'Commercial Bank',
          code: 'TOBE',
        },
        {
          label: 'Limited Liability Limited Partnership (LLLP)',
          code: 'WE9D',
        },
        {
          label: 'Cooperative Association',
          code: 'ZCHO',
        },
      ],
    },
    {
      subdivision_label: 'Connecticut',
      subdivision_code: 'US-CT',
      legal_forms: [
        {
          label: 'Nonstock Corporation',
          code: '7W53',
        },
        {
          label: 'Stock Corporation (Co)',
          code: 'JKOT',
        },
        {
          label: 'Religious Society or Religious Corporation',
          code: 'L7HH',
        },
        {
          label: 'Savings Bank',
          code: 'LIEA',
        },
        {
          label: 'Limited Partnership',
          code: 'LKQ2',
        },
        {
          label: 'Statutory Trust',
          code: 'SHCN',
        },
        {
          label: 'Savings and Loan Association',
          code: 'T5X4',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'T80N',
        },
        {
          label: 'Commercial Bank',
          code: 'WT0G',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'Y182',
        },
      ],
    },
    {
      subdivision_label: 'Delaware',
      subdivision_code: 'US-DE',
      legal_forms: [
        {
          label: 'Unincorporated Nonprofit Association',
          code: '12N6',
        },
        {
          label: 'Limited Liability Partnership',
          code: '1HXP',
        },
        {
          label: 'Statutory Trust',
          code: '4FSX',
        },
        {
          label: 'Commercial Bank',
          code: '9ASJ',
        },
        {
          label: 'Limited Liability Company',
          code: 'HZEH',
        },
        {
          label: 'Savings Bank',
          code: 'JU79',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'MIPY',
        },
        {
          label: 'Partnership',
          code: 'QF4W',
        },
        {
          label: 'Limited Partnership',
          code: 'T91T',
        },
        {
          label: 'Limited Liability Limited Partnership',
          code: 'TGMR',
        },
        {
          label: 'Corporation',
          code: 'XTIQ',
        },
      ],
    },
    {
      subdivision_label: 'District of Columbia',
      subdivision_code: 'US-DC',
      legal_forms: [
        {
          label: 'Limited Partnership (LLLP)',
          code: '74LJ',
        },
        {
          label: 'Commercial Bank',
          code: '9AQ8',
        },
        {
          label: 'Business Corporation (Inc)',
          code: 'CNQ3',
        },
        {
          label: 'Limited Cooperative Association (LCA)',
          code: 'IPGV',
        },
        {
          label: 'General Cooperative Association (Coop)',
          code: 'O35K',
        },
        {
          label: 'Statutory Trust',
          code: 'SQOE',
        },
        {
          label: 'Limited Liability Partnership (RLLP)',
          code: 'TJ6V',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'VNIU',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'ZJPG',
        },
      ],
    },
    {
      subdivision_label: 'Florida',
      subdivision_code: 'US-FL',
      legal_forms: [
        {
          label: 'Commercial Bank',
          code: '3LXM',
        },
        {
          label: 'Non-Profit Corporation (Inc.)',
          code: '3N55',
        },
        {
          label: 'Limited Partnership ( LP)',
          code: '5DS0',
        },
        {
          label: 'Non-deposit Trust Company',
          code: '72CA',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: '8N21',
        },
        {
          label: 'Limited Liability Limited Partnership ( LLLP)',
          code: 'D155',
        },
        {
          label: 'Savings Bank',
          code: 'E4LW',
        },
        {
          label: 'Profit Corporation (Inc)',
          code: 'TRI2',
        },
      ],
    },
    {
      subdivision_label: 'Georgia',
      subdivision_code: 'US-GA',
      legal_forms: [
        {
          label: 'Limited Liability Company (LLC)',
          code: '6IIM',
        },
        {
          label: 'Limited Partnership',
          code: 'AD0H',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'ALW3',
        },
        {
          label: 'Professional Corporation',
          code: 'ASC7',
        },
        {
          label: 'Savings and Loan Association',
          code: 'EY94',
        },
        {
          label: 'Commercial Bank',
          code: 'GR2X',
        },
        {
          label: 'Savings Bank',
          code: 'H5G0',
        },
        {
          label: 'Profit Corporation (inc)',
          code: 'MFYJ',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'S7VR',
        },
      ],
    },
    {
      subdivision_label: 'Hawaii',
      subdivision_code: 'US-HI',
      legal_forms: [
        {
          label: 'Limited Liability Limited Partnership (LLLP)',
          code: '6MB6',
        },
        {
          label: 'Service Mark',
          code: '982Z',
        },
        {
          label: 'Savings Bank',
          code: '9G8O',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'AQBO',
        },
        {
          label: 'General Partnership',
          code: 'B38Q',
        },
        {
          label: 'Nonprofit Corporation (Ltd)',
          code: 'DX2N',
        },
        {
          label: 'Cooperative (Co-op)',
          code: 'FTNW',
        },
        {
          label: 'Commercial Bank',
          code: 'IWRC',
        },
        {
          label: 'Corporation (Ltd)',
          code: 'L2QV',
        },
        {
          label: 'Trade Name',
          code: 'L5BO',
        },
        {
          label: 'Limited Liablity Partnership (LLP)',
          code: 'M2KR',
        },
        {
          label: 'Industrial Bank',
          code: 'QJ1G',
        },
        {
          label: 'Professional Corporation (Ltd)',
          code: 'S0BB',
        },
        {
          label: 'Sustainable Business Corporation (Ltd)',
          code: 'SBF3',
        },
        {
          label: 'Corporation Sole (Ltd)',
          code: 'TTTV',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'VPBH',
        },
        {
          label: 'Publicity Rights Name Registration',
          code: 'WMTY',
        },
        {
          label: 'Commercial Registered Agent (CRA)',
          code: 'YOUG',
        },
        {
          label: 'Trademark',
          code: 'ZLIW',
        },
      ],
    },
    {
      subdivision_label: 'Idaho',
      subdivision_code: 'US-ID',
      legal_forms: [
        {
          label: 'Limited Partnership (LP)',
          code: '530K',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'EVE6',
        },
        {
          label: 'Commercial Bank',
          code: 'H1J5',
        },
        {
          label: 'Savings and Loan Association',
          code: 'JXUC',
        },
        {
          label: 'Limited Liability Partnership (RLLP)',
          code: 'QLWR',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'RU6X',
        },
        {
          label: 'General Business Corporation (inc)',
          code: 'U9HL',
        },
        {
          label: 'General Partnership',
          code: 'UK9P',
        },
      ],
    },
    {
      subdivision_label: 'Illinois',
      subdivision_code: 'US-IL',
      legal_forms: [
        {
          label: 'Limited Partnership (LP)',
          code: '1WZP',
        },
        {
          label: 'Savings and Loan Association',
          code: '22SC',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: '8RLE',
        },
        {
          label: 'Business Corporation (inc)',
          code: 'AZUK',
        },
        {
          label: 'Savings Bank',
          code: 'COKS',
        },
        {
          label: 'Professional Services Corporation (Prof Corp)',
          code: 'F5VL',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'HSPI',
        },
        {
          label: 'Commercial Bank',
          code: 'QY7A',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'VUXH',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'YTY3',
        },
      ],
    },
    {
      subdivision_label: 'Indiana',
      subdivision_code: 'US-IN',
      legal_forms: [
        {
          label: 'Professional Corporation',
          code: '78PB',
        },
        {
          label: 'Savings and Loan Association',
          code: '8Z76',
        },
        {
          label: 'Financial Institution',
          code: '95W7',
        },
        {
          label: 'Master Limited Liability Company (LLC)',
          code: '9FD9',
        },
        {
          label: 'Industrial Bank',
          code: 'C639',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'D92T',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'DQBZ',
        },
        {
          label: 'Business Trust',
          code: 'EW9A',
        },
        {
          label: 'Agricultural Cooperative (Inc)',
          code: 'EZ08',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'G4YY',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'M1FY',
        },
        {
          label: 'Insurance Corporation',
          code: 'N0YQ',
        },
        {
          label: 'Commercial Bank',
          code: 'P91Q',
        },
        {
          label: 'Savings Bank',
          code: 'QR5I',
        },
        {
          label: 'For-Profit Corporation (Inc)',
          code: 'R0BI',
        },
        {
          label: 'Professional Benefit Corporation',
          code: 'V5G5',
        },
        {
          label: 'Nonprofit Corporation (Inc)',
          code: 'V89I',
        },
        {
          label: 'Series (LLC)',
          code: 'VCQZ',
        },
        {
          label: 'Benefit Corporation (Inc)',
          code: 'X4EB',
        },
      ],
    },
    {
      subdivision_label: 'Iowa',
      subdivision_code: 'US-IA',
      legal_forms: [
        {
          label: 'Cooperative Association',
          code: '32AX',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: '7XPF',
        },
        {
          label: 'Savings and Loan Association',
          code: '8VLM',
        },
        {
          label: 'Profit Corporation (inc)',
          code: 'DU35',
        },
        {
          label: 'Savings Bank',
          code: 'GDH0',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'HPKC',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'HUSW',
        },
        {
          label: 'Professional Corporation',
          code: 'L22N',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'T7D4',
        },
        {
          label: 'Professional Limited Liability Company',
          code: 'VVPD',
        },
        {
          label: 'Commercial Bank',
          code: 'VWY9',
        },
      ],
    },
    {
      subdivision_label: 'Kansas',
      subdivision_code: 'US-KS',
      legal_forms: [
        {
          label: 'Public Benefit Corporation (PBC)',
          code: '35QN',
        },
        {
          label: 'General Partnership',
          code: '7U8S',
        },
        {
          label: 'Sole Proprietorship',
          code: 'AREY',
        },
        {
          label: 'Professional Association (PA)',
          code: 'CANT',
        },
        {
          label: 'Savings and Loan Association',
          code: 'F9I4',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'FBN0',
        },
        {
          label: 'Not-For-Profit Corporation',
          code: 'FEC4',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'FGT5',
        },
        {
          label: 'Corporation (corp)',
          code: 'GCU5',
        },
        {
          label: 'Statutory Public Benefit Limited Liability Company (SPBLLC)',
          code: 'JIF7',
        },
        {
          label: 'Savings Bank',
          code: 'VBVM',
        },
        {
          label: 'Business Trust',
          code: 'WC8Q',
        },
        {
          label: 'Commercial Bank',
          code: 'X4TB',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'XQEC',
        },
      ],
    },
    {
      subdivision_label: 'Kentucky',
      subdivision_code: 'US-KY',
      legal_forms: [
        {
          label: 'Limited Liability Limited Partership (LLLP)',
          code: '3MMY',
        },
        {
          label: 'Corporation',
          code: '5RRE',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: '6GEC',
        },
        {
          label: 'Statutory Trust (LTD)',
          code: '85YQ',
        },
        {
          label: 'Limited Cooperative Assocation (LTD CO-OP)',
          code: 'A9VQ',
        },
        {
          label: 'Corporation (LTD)',
          code: 'DAVB',
        },
        {
          label: 'General Partnership',
          code: 'EBP8',
        },
        {
          label: 'Limited Liability Company (LTD CO)',
          code: 'F2QV',
        },
        {
          label: 'Savings Bank',
          code: 'HH12',
        },
        {
          label: 'Unincorporated Non-Profit Assocation (Ltd)',
          code: 'LU9O',
        },
        {
          label: 'Savings and Loan Association',
          code: 'MBKN',
        },
        {
          label: 'Limited Liability Company (LTD CO)',
          code: 'MY61',
        },
        {
          label: 'Commercial Bank',
          code: 'NJ57',
        },
        {
          label: 'Public Benefit Corporation (PBC)',
          code: 'O5PH',
        },
        {
          label: 'Business Trust (LTD)',
          code: 'OFGO',
        },
        {
          label: 'Corporation (LTD)',
          code: 'QWPR',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'T362',
        },
        {
          label: 'Limited Partnership (LTD)',
          code: 'VE28',
        },
      ],
    },
    {
      subdivision_label: 'Louisiana',
      subdivision_code: 'US-LA',
      legal_forms: [
        {
          label: 'Domestic Benefit Corporation',
          code: '1V0I',
        },
        {
          label: 'Foreign Non-Profit Limited Liability Company (LLC)',
          code: '6DVA',
        },
        {
          label: 'Domestic Non-Profit Corporation',
          code: '6EDK',
        },
        {
          label: 'Savings Bank',
          code: '6R7O',
        },
        {
          label: 'Foreign Partnership',
          code: '8PUO',
        },
        {
          label: 'Foreign Limited Liability Company (LLC)',
          code: '9L6B',
        },
        {
          label: 'Domestic Public Benefit Corporation',
          code: 'C1F4',
        },
        {
          label: 'Domestic Limited Liability Company (LLC)',
          code: 'CD9O',
        },
        {
          label: 'Foreign Real Estate Investment Trust',
          code: 'EO9A',
        },
        {
          label: 'Foreign Corporation (Inc)',
          code: 'HQYG',
        },
        {
          label: 'Home Service Contract Provider (Inc)',
          code: 'LTKC',
        },
        {
          label: 'Domestic Unincorporated Association',
          code: 'MQU4',
        },
        {
          label: 'Cable & Video Franchise',
          code: 'N71W',
        },
        {
          label: 'Domestic Corporation (Inc)',
          code: 'NI44',
        },
        {
          label: 'Domestic Real Estate Investment Trust',
          code: 'NV9X',
        },
        {
          label: 'Domestic Partnership',
          code: 'O6QP',
        },
        {
          label: 'Domestic Low-Profit Limited Liability Company (L3C)',
          code: 'ONJ1',
        },
        {
          label: 'Foreign Benefit Corporation',
          code: 'Q8RV',
        },
        {
          label: 'Foreign Non-Profit Corporation',
          code: 'SJKV',
        },
        {
          label: 'Domestic Registered Limited Liability Partnership (LLP)',
          code: 'TDBW',
        },
        {
          label: 'Commercial Bank',
          code: 'TZDZ',
        },
        {
          label: 'Co-op (Inc)',
          code: 'WP6R',
        },
        {
          label: 'Savings and Loan Association',
          code: 'XFUO',
        },
        {
          label: 'Collection Agency',
          code: 'Z016',
        },
        {
          label: 'Foreign Public Benefit',
          code: 'ZQZW',
        },
      ],
    },
    {
      subdivision_label: 'Maine',
      subdivision_code: 'US-ME',
      legal_forms: [
        {
          label: 'Nonprofit Corporation',
          code: '2XXG',
        },
        {
          label: 'Limited Liability Company',
          code: '53DC',
        },
        {
          label: 'Savings and Loan Association',
          code: '5KRB',
        },
        {
          label: 'Savings Bank',
          code: 'AT9F',
        },
        {
          label: 'Limited Partnership',
          code: 'IIZ4',
        },
        {
          label: 'Cooperative',
          code: 'JR3Z',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'M9A1',
        },
        {
          label: 'Commercial Bank',
          code: 'P1VP',
        },
        {
          label: 'Business Corporation',
          code: 'TTB3',
        },
      ],
    },
    {
      subdivision_label: 'Maryland',
      subdivision_code: 'US-MD',
      legal_forms: [
        {
          label: 'Limited Partnership (LP)',
          code: 'BSLM',
        },
        {
          label: 'Commercial Bank',
          code: 'EW8U',
        },
        {
          label: 'Savings Bank',
          code: 'GMFE',
        },
        {
          label: 'Stock Corporation (Corp)',
          code: 'HLR4',
        },
        {
          label: 'Savings and Loan Association',
          code: 'J5NM',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'JJ9M',
        },
        {
          label: 'Close Corporation (Corp)',
          code: 'LKD5',
        },
        {
          label: 'Religious Corporation',
          code: 'OCVJ',
        },
        {
          label: 'General Partnership',
          code: 'R2PI',
        },
        {
          label: 'Sole Proprietorship',
          code: 'XHT1',
        },
        {
          label: 'Nonstock Corporation',
          code: 'ZVS9',
        },
      ],
    },
    {
      subdivision_label: 'Massachusetts',
      subdivision_code: 'US-MA',
      legal_forms: [
        {
          label: 'Housing Cooperative Corporation (Inc)',
          code: '1XME',
        },
        {
          label: 'Cooperative Corporation (Inc)',
          code: '672E',
        },
        {
          label: 'Professional Corporation (co)',
          code: '6I75',
        },
        {
          label: 'Cooperative Bank',
          code: '7G8G',
        },
        {
          label: 'Savings and Loan Association',
          code: 'BE3O',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'BYFU',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'CAGH',
        },
        {
          label: 'Savings Bank',
          code: 'G76T',
        },
        {
          label: 'Limited Partnership (Limited Partnership)',
          code: 'GOGQ',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'KU3O',
        },
        {
          label: 'Employee Cooperative Corporation (Inc)',
          code: 'NETO',
        },
        {
          label: 'Domestic Benefit Corporation (Co)',
          code: 'QX9N',
        },
        {
          label: 'Non-Profit Corporation (Inc)',
          code: 'R7QO',
        },
        {
          label: 'Commercial Bank',
          code: 'YP04',
        },
        {
          label: 'Voluntary Association and Business Trusts',
          code: 'Z73Z',
        },
        {
          label: 'Domestic Business Corporation (Co)',
          code: 'ZJTK',
        },
      ],
    },
    {
      subdivision_label: 'Michigan',
      subdivision_code: 'US-MI',
      legal_forms: [
        {
          label: 'Corporation',
          code: '47LQ',
        },
        {
          label: 'Limited Partnership (Limited Partnership)',
          code: '58N0',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '6E4G',
        },
        {
          label: 'Commercial Bank',
          code: '6RZK',
        },
        {
          label: 'Sole proprietorship',
          code: '88XU',
        },
        {
          label: 'Professional Corporation (PC)',
          code: 'HQJV',
        },
        {
          label: 'Professional Limited Liability Company (PLLC)',
          code: 'IN4H',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'LJH5',
        },
        {
          label: 'For profit and not-for profit co-operatives (coop)',
          code: 'MI0H',
        },
        {
          label: 'General Partnership',
          code: 'NK3V',
        },
        {
          label: 'L3C (L3C)',
          code: 'NR96',
        },
        {
          label: 'Savings Bank',
          code: 'TPO4',
        },
        {
          label: 'Corporation (Corp)',
          code: 'U7HC',
        },
        {
          label: 'Ecclesiastical Corporation',
          code: 'UJZB',
        },
        {
          label: 'Railroad',
          code: 'XTOF',
        },
      ],
    },
    {
      subdivision_label: 'Minnesota',
      subdivision_code: 'US-MN',
      legal_forms: [
        {
          label: 'Religious corporations',
          code: '1ASH',
        },
        {
          label: 'Public Benefit Corporations',
          code: '1ONH',
        },
        {
          label: 'Professional Firms',
          code: '37F5',
        },
        {
          label: 'Limited Partnership',
          code: '43LY',
        },
        {
          label: 'Commercial Bank',
          code: '6VCW',
        },
        {
          label: 'Limited Liability Partnership',
          code: '6ZAR',
        },
        {
          label: 'Business Trusts',
          code: '8OK2',
        },
        {
          label: 'Financial Corporations',
          code: '9HKD',
        },
        {
          label: 'Non-Profit Corporation',
          code: '9I4Y',
        },
        {
          label: 'Limited Liability Company',
          code: 'BQRL',
        },
        {
          label: 'General Partnership',
          code: 'DEMP',
        },
        {
          label: 'Limited Liability Limited Partnership',
          code: 'IYZI',
        },
        {
          label: 'Cooperative',
          code: 'LDBS',
        },
        {
          label: 'Insurance Corporations',
          code: 'OXAZ',
        },
        {
          label: 'Business Corporation',
          code: 'S2K8',
        },
        {
          label: 'Industrial Bank',
          code: 'S968',
        },
        {
          label: 'Savings and Loan Association',
          code: 'SGT8',
        },
        {
          label: 'Cemetery associations',
          code: 'T4OL',
        },
        {
          label: 'Non-Profit Limited Liability Company',
          code: 'TBN1',
        },
        {
          label: 'Savings Bank',
          code: 'TS1K',
        },
        {
          label: 'Cooperative',
          code: 'UJ49',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'VSDJ',
        },
      ],
    },
    {
      subdivision_label: 'Mississippi',
      subdivision_code: 'US-MS',
      legal_forms: [
        {
          label: 'Commercial Bank',
          code: '7WQO',
        },
        {
          label: 'General Partnership',
          code: '8YBQ',
        },
        {
          label: 'Savings Bank',
          code: 'BG8P',
        },
        {
          label: 'Savings and Loan Association',
          code: 'HDOD',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'NDBR',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'O4NK',
        },
        {
          label: 'Limited Liability Partnership (RLLP)',
          code: 'U7GR',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'WPCN',
        },
        {
          label: 'Business Corporation (inc)',
          code: 'XST3',
        },
      ],
    },
    {
      subdivision_label: 'Missouri',
      subdivision_code: 'US-MO',
      legal_forms: [
        {
          label: 'Family Trust Company',
          code: '1ADA',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '21OE',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: '7F5B',
        },
        {
          label: 'Savings and Loan Association',
          code: '9BH2',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'EMLK',
        },
        {
          label: 'Commercial Bank',
          code: 'J1JO',
        },
        {
          label: 'For-Profit Corporation',
          code: 'KC7Z',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'QSHG',
        },
        {
          label: 'Not For Profit Corporation',
          code: 'R8SH',
        },
        {
          label: 'Cooperative Association (CA)',
          code: 'SU5T',
        },
        {
          label: 'Savings Bank',
          code: 'U7OR',
        },
      ],
    },
    {
      subdivision_label: 'Montana',
      subdivision_code: 'US-MT',
      legal_forms: [
        {
          label: 'Professional Series Limited Liability Company',
          code: '1S9L',
        },
        {
          label: 'Agricultural Marketing Association',
          code: '1XOG',
        },
        {
          label: 'Limited Liability Limited Partnership',
          code: '30PQ',
        },
        {
          label: 'Trust Company',
          code: '30TX',
        },
        {
          label: 'Mutual Benefit Corporation',
          code: '40SO',
        },
        {
          label: 'Builidng and Loan Association',
          code: '5AE9',
        },
        {
          label: 'Close Corporation',
          code: '5MRP',
        },
        {
          label: 'Congressional Chartered Corporation',
          code: '7OS8',
        },
        {
          label: 'Agricultural Association',
          code: '93CA',
        },
        {
          label: 'Limited Liability Partnership',
          code: '9OSW',
        },
        {
          label: 'Corporation',
          code: '9STD',
        },
        {
          label: 'Professional Corporation',
          code: 'A770',
        },
        {
          label: 'Limited Liability Company',
          code: 'AEK0',
        },
        {
          label: 'Cooperative Association',
          code: 'B2F4',
        },
        {
          label: 'Credit Union',
          code: 'HEMZ',
        },
        {
          label: 'Rural Cooperative Association',
          code: 'JGPP',
        },
        {
          label: 'Investment Company',
          code: 'KPH8',
        },
        {
          label: 'Professional Limited Liability Limited Partnership',
          code: 'L1PM',
        },
        {
          label: 'Limited Partnership',
          code: 'LPLY',
        },
        {
          label: 'Savings Bank',
          code: 'LXI4',
        },
        {
          label: 'Professional Limited Liability Company',
          code: 'MY93',
        },
        {
          label: 'Benefit Corporation',
          code: 'OOX5',
        },
        {
          label: 'Corporate Credit Union',
          code: 'PNF3',
        },
        {
          label: 'Savings and Loan Association',
          code: 'Q6K2',
        },
        {
          label: 'Business Trust',
          code: 'QB0A',
        },
        {
          label: 'Public Benefit Corporation',
          code: 'SCX8',
        },
        {
          label: 'Commercial Bank',
          code: 'SW88',
        },
        {
          label: 'Professional Close Corporation',
          code: 'T91C',
        },
        {
          label: 'Religious Corporation',
          code: 'WRF9',
        },
        {
          label: 'Series Limited Liability Company',
          code: 'XVC6',
        },
      ],
    },
    {
      subdivision_label: 'Nebraska',
      subdivision_code: 'US-NE',
      legal_forms: [
        {
          label: 'Sanitary & Improvement District',
          code: '3GTV',
        },
        {
          label: 'Domestic Limited Cooperative Association',
          code: '4TMV',
        },
        {
          label: 'Savings and Loan Association',
          code: '4WTP',
        },
        {
          label: 'Foreign Limited Liability Company',
          code: '5OET',
        },
        {
          label: 'Non Profit Domesticated Foreign Corporation',
          code: '639V',
        },
        {
          label: 'Foreign Corp Professional Corporation',
          code: '6ICB',
        },
        {
          label: 'Non Stock Corporation',
          code: '7BQ5',
        },
        {
          label: 'Domestic Limited Liability Company',
          code: '8APW',
        },
        {
          label: 'Foreign Corporation',
          code: '8HNT',
        },
        {
          label: 'Domestic Professional Limited Liability Company',
          code: 'B843',
        },
        {
          label: 'Foreign Professional Limited Liability Company',
          code: 'BJ2V',
        },
        {
          label: 'Domesticated Foreign Corporation Professional Corporation',
          code: 'CXIO',
        },
        {
          label: 'Joint Public Agency',
          code: 'D2MO',
        },
        {
          label: 'Domestic Limited Partnership',
          code: 'DFPP',
        },
        {
          label: 'Domestic Corporation Professional Corporation',
          code: 'ECLT',
        },
        {
          label: 'Commercial Bank',
          code: 'GEXT',
        },
        {
          label: 'Non Taxable Corporation',
          code: 'HV3B',
        },
        {
          label: 'Non Profit Foreign Limited Cooperative Association',
          code: 'IOWN',
        },
        {
          label: 'Domestic General Partnership',
          code: 'KFPS',
        },
        {
          label: 'Nebraska Benefit Corporation',
          code: 'LQZC',
        },
        {
          label: 'Foreign Limited Liability Partnership',
          code: 'MPFG',
        },
        {
          label: 'Domesticated Foreign Corporation',
          code: 'NGZJ',
        },
        {
          label: 'Non Profit Foreign Corporation',
          code: 'O256',
        },
        {
          label: 'Savings Bank',
          code: 'Q17J',
        },
        {
          label: 'Registered Agent Designation',
          code: 'R22H',
        },
        {
          label: 'Foreign General Apartnership',
          code: 'R3J6',
        },
        {
          label: 'Non Profit',
          code: 'R7JP',
        },
        {
          label: 'Domestic Corporation',
          code: 'RH6N',
        },
        {
          label: 'Insurance Company',
          code: 'T172',
        },
        {
          label: 'Non Profit Dom Limited Cooperative Association',
          code: 'V4RX',
        },
        {
          label: 'Fraternal Society',
          code: 'VYEO',
        },
        {
          label: 'Agricultural Association',
          code: 'XBKP',
        },
        {
          label: 'Bank',
          code: 'XUVE',
        },
        {
          label: 'Hospital Authority',
          code: 'Y7BG',
        },
        {
          label: 'Domestic Limited Liability Partnership',
          code: 'YQDQ',
        },
        {
          label: 'Foreign Limited Partnership',
          code: 'YWK0',
        },
        {
          label: 'Foreign Limited Cooperative Association',
          code: 'ZEZ2',
        },
      ],
    },
    {
      subdivision_label: 'Nevada',
      subdivision_code: 'US-NV',
      legal_forms: [
        {
          label: 'Limited Partnership (LP)',
          code: '62L3',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '7K6U',
        },
        {
          label: 'Profit Corporation (Ltd)',
          code: '8WM4',
        },
        {
          label: 'Commercial Bank',
          code: 'HCTP',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'IY8C',
        },
        {
          label: 'Savings Bank',
          code: 'K3J6',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'MM8M',
        },
        {
          label: 'Business Trust (BT)',
          code: 'SUEQ',
        },
        {
          label: 'Industrial Bank',
          code: 'Y9F2',
        },
      ],
    },
    {
      subdivision_label: 'New Hampshire',
      subdivision_code: 'US-NH',
      legal_forms: [
        {
          label: 'Savings Bank',
          code: '2JB4',
        },
        {
          label: 'Commercial Bank',
          code: '3LNE',
        },
        {
          label: 'Foundation',
          code: '520I',
        },
        {
          label: 'Business Corporation',
          code: '6M6O',
        },
        {
          label: 'Benefit Corporation',
          code: '9M2Q',
        },
        {
          label: 'Limited Partnership',
          code: 'G66U',
        },
        {
          label: 'Limited Liability Company',
          code: 'OJ9K',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'P3LZ',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'RCNI',
        },
        {
          label: 'Investment Trust',
          code: 'S97G',
        },
        {
          label: 'Cooperative Bank',
          code: 'W1DV',
        },
      ],
    },
    {
      subdivision_label: 'New Jersey',
      subdivision_code: 'US-NJ',
      legal_forms: [
        {
          label: 'Limited Partnership (LP)',
          code: '7HY7',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'FFBM',
        },
        {
          label: 'Commercial Bank',
          code: 'G42I',
        },
        {
          label: 'Savings and Loan Association',
          code: 'N508',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'P7RH',
        },
        {
          label: 'Nonprofit Corporation (inc)',
          code: 'T4M6',
        },
        {
          label: 'Savings Bank',
          code: 'VWC5',
        },
        {
          label: 'Business Corporation',
          code: 'XSNP',
        },
      ],
    },
    {
      subdivision_label: 'New Mexico',
      subdivision_code: 'US-NM',
      legal_forms: [
        {
          label: 'Limited Liability Company',
          code: '4YOA',
        },
        {
          label: 'Cooperative Association',
          code: '7GMS',
        },
        {
          label: 'Commercial Bank',
          code: 'F69V',
        },
        {
          label: 'Savings Bank',
          code: 'POG3',
        },
        {
          label: 'Profit Corporation (inc)',
          code: 'VG3S',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'W0U4',
        },
        {
          label: 'Savings and Loan Association',
          code: 'Y1ZD',
        },
      ],
    },
    {
      subdivision_label: 'New York',
      subdivision_code: 'US-NY',
      legal_forms: [
        {
          label: 'Commercial Bank',
          code: '4VH5',
        },
        {
          label: 'Not-for-profit Corporation',
          code: '51RC',
        },
        {
          label: 'Limited Partnership',
          code: 'BO6L',
        },
        {
          label: 'Savings and Loan Association',
          code: 'EPCY',
        },
        {
          label: 'Savings Bank',
          code: 'M0ER',
        },
        {
          label: 'Business Corporation',
          code: 'PJ10',
        },
        {
          label: 'Limited Liability Company',
          code: 'SDX0',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'XIZI',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'YVSB',
        },
      ],
    },
    {
      subdivision_label: 'North Carolina',
      subdivision_code: 'US-NC',
      legal_forms: [
        {
          label: 'Business Corporation (inc)',
          code: '1QMT',
        },
        {
          label: 'Nonprofit Corporation (inc)',
          code: '358I',
        },
        {
          label: 'Professional Limited Liability Company (PLLC)',
          code: '3DXJ',
        },
        {
          label: 'County Agricultural Fair (inc)',
          code: '4LKE',
        },
        {
          label: 'Commercial Bank',
          code: '5AAO',
        },
        {
          label: 'Insurance Company (inc)',
          code: '5JAL',
        },
        {
          label: 'Railroad (inc)',
          code: '6NI8',
        },
        {
          label: 'Savings Bank',
          code: '8N0C',
        },
        {
          label: 'Professional Corporation (inc)',
          code: '8QMN',
        },
        {
          label: 'Limited partnership (Ltd)',
          code: 'ARON',
        },
        {
          label: 'Cooperative Association (inc)',
          code: 'CJ58',
        },
        {
          label: 'Electric Membership Cooperative (inc)',
          code: 'F99D',
        },
        {
          label: 'Savings & Loan Association (inc)',
          code: 'IHLF',
        },
        {
          label: 'Nonprofit Community Trust (inc)',
          code: 'JF6D',
        },
        {
          label: 'Mutual Association (inc)',
          code: 'Q4F9',
        },
        {
          label: 'L3C (L3C)',
          code: 'Q6EM',
        },
        {
          label: 'Public Transportation Authority (inc)',
          code: 'SIST',
        },
        {
          label: 'Captive Insurance Company (inc)',
          code: 'U94O',
        },
        {
          label: 'Bank (inc)',
          code: 'UFIY',
        },
        {
          label: 'State Savings Bank (inc)',
          code: 'UKX4',
        },
        {
          label: 'Savings and Loan Association',
          code: 'UVH6',
        },
        {
          label: 'Municipal (inc)',
          code: 'WY1B',
        },
        {
          label: 'Limited liability partnership (ltd)',
          code: 'X0MP',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'X1EL',
        },
        {
          label: 'Mutual Burial Association (inc)',
          code: 'Y5L0',
        },
        {
          label: 'Registered Limited Liability Limited Partnership (LLLP)',
          code: 'YJZ3',
        },
      ],
    },
    {
      subdivision_label: 'North Dakota',
      subdivision_code: 'US-ND',
      legal_forms: [
        {
          label: 'Cooperative Association (co-op)',
          code: '2WJZ',
        },
        {
          label: 'Professional Limited Liabity Partnership (LLP)',
          code: '4U9V',
        },
        {
          label: 'Professional Limited Liability Company (LLC)',
          code: '64HG',
        },
        {
          label: 'General Partnership (GP)',
          code: '6JCE',
        },
        {
          label: 'Savings Bank',
          code: 'AZYL',
        },
        {
          label: 'Mutual Aid Cooperative (co-op)',
          code: 'CPK1',
        },
        {
          label: 'Nonprofit Limited Liability Company (LLC)',
          code: 'DLEW',
        },
        {
          label: 'Cooperative Grazing Associations (co-op)',
          code: 'EO24',
        },
        {
          label: 'Publicly Traded Corporation (inc)',
          code: 'ER7C',
        },
        {
          label: 'Farm Limited Laibility Company (LLC)',
          code: 'FK53',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'GHB2',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'J1V0',
        },
        {
          label: 'Farm Corporation (inc)',
          code: 'LF4E',
        },
        {
          label: 'Limited Liability Limited Partnership (LLLP)',
          code: 'MTDW',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'NHGN',
        },
        {
          label: 'Real Estate Investment Trusts (REIT)',
          code: 'NJA5',
        },
        {
          label: 'Professional Corporation (inc)',
          code: 'OGHV',
        },
        {
          label: 'Commercial Bank',
          code: 'OVCL',
        },
        {
          label: 'Corporation (inc)',
          code: 'S745',
        },
        {
          label: 'Nonprofit Corporation (inc)',
          code: 'U87X',
        },
        {
          label: 'Community Development Corporation (inc)',
          code: 'WIU6',
        },
        {
          label: 'Electric Cooperative Corporations (co-op)',
          code: 'YOB3',
        },
        {
          label: 'Development Corporation (inc)',
          code: 'ZULC',
        },
      ],
    },
    {
      subdivision_label: 'Ohio',
      subdivision_code: 'US-OH',
      legal_forms: [
        {
          label: 'Savings Bank',
          code: '2EPF',
        },
        {
          label: 'Savings and Loan Association',
          code: '2R1N',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '52U0',
        },
        {
          label: 'Corporation',
          code: '7VK5',
        },
        {
          label: 'General Partnership',
          code: '95OK',
        },
        {
          label: 'Professional Association (inc)',
          code: 'AEJF',
        },
        {
          label: 'Corporation (inc)',
          code: 'BTQ1',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'EZNQ',
        },
        {
          label: 'Cooperative (co-op)',
          code: 'FZMT',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'JBQI',
        },
        {
          label: 'Commercial Bank',
          code: 'N566',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'ZD39',
        },
      ],
    },
    {
      subdivision_label: 'Oklahoma',
      subdivision_code: 'US-OK',
      legal_forms: [
        {
          label: 'Savings and Loan Association',
          code: '30D6',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'B8XC',
        },
        {
          label: 'Profit Corporation (inc)',
          code: 'BGH4',
        },
        {
          label: 'Commercial Bank',
          code: 'EDOR',
        },
        {
          label: 'Savings Bank',
          code: 'GIPE',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'OJDX',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'PQXK',
        },
        {
          label: 'Not-for-profit Corporation (inc)',
          code: 'VJXH',
        },
      ],
    },
    {
      subdivision_label: 'Oregon',
      subdivision_code: 'US-OR',
      legal_forms: [
        {
          label: 'Assumed Business Name (DBA)',
          code: '4BEM',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'GEE0',
        },
        {
          label: 'Savings and Loan Association',
          code: 'LGVF',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'QK2J',
        },
        {
          label: 'District Improvement Company',
          code: 'R18M',
        },
        {
          label: 'Business Trust (trust)',
          code: 'T2DF',
        },
        {
          label: 'Business Corporation',
          code: 'T5ZE',
        },
        {
          label: 'Commercial Bank',
          code: 'U1EG',
        },
        {
          label: 'Cooperative Corporation (co-op)',
          code: 'UQWQ',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'UZ83',
        },
        {
          label: 'Professional Corporation (PC)',
          code: 'V10Q',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'WQLU',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'Z9C5',
        },
      ],
    },
    {
      subdivision_label: 'Pennsylvania',
      subdivision_code: 'US-PA',
      legal_forms: [
        {
          label: 'Non-deposit Trust Company',
          code: '23Q4',
        },
        {
          label: 'Business Corporation (inc)',
          code: '3JTE',
        },
        {
          label: 'Savings Bank',
          code: '3L7Q',
        },
        {
          label: 'Savings and Loan Association',
          code: '51AN',
        },
        {
          label: 'Limited Liability Company',
          code: '9C19',
        },
        {
          label: 'Nonprofit Corporation',
          code: '9EJ6',
        },
        {
          label: 'Limited Liability Partnership',
          code: 'B8KO',
        },
        {
          label: 'Limited Partnership',
          code: 'HSEV',
        },
        {
          label: 'Commercial Bank',
          code: 'R9OY',
        },
      ],
    },
    {
      subdivision_label: 'Rhode island',
      subdivision_code: 'US-RI',
      legal_forms: [
        {
          label: 'Savings Bank',
          code: '4NMI',
        },
        {
          label: 'Savings and Loan Association',
          code: 'C2O4',
        },
        {
          label: 'Commercial Bank',
          code: 'HA7M',
        },
      ],
    },
    {
      subdivision_label: 'Rhode Island',
      subdivision_code: 'US-RI',
      legal_forms: [
        {
          label: 'Benefit Professional Corporation',
          code: '5E0K',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '7RLC',
        },
        {
          label: 'Non-profit Corporation',
          code: 'AVLE',
        },
        {
          label: 'Limited Liability Company (llc)',
          code: 'DRSE',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'FW66',
        },
        {
          label: 'L3C Low Profit LLC',
          code: 'MG8V',
        },
        {
          label: 'Workers Cooperative (inc)',
          code: 'N263',
        },
        {
          label: 'Benefit Corporation',
          code: 'QMI2',
        },
        {
          label: 'Professional Service Corporation (inc)',
          code: 'RZ5R',
        },
        {
          label: 'Business Corporation (inc)',
          code: 'Z54A',
        },
        {
          label: 'Cooperative (inc)',
          code: 'ZHZP',
        },
      ],
    },
    {
      subdivision_label: 'South Carolina',
      subdivision_code: 'US-SC',
      legal_forms: [
        {
          label: 'Nonprofit Corporation',
          code: '11GD',
        },
        {
          label: 'Savings Bank',
          code: '5DBJ',
        },
        {
          label: 'Business Corporation',
          code: '7QV2',
        },
        {
          label: 'Commercial Bank',
          code: 'D2EI',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'DBGD',
        },
        {
          label: 'Statutory Close Corporation (inc)',
          code: 'G0HE',
        },
        {
          label: 'Limited Partnership',
          code: 'G6VI',
        },
        {
          label: 'Professional Corporation (PC)',
          code: 'N10D',
        },
        {
          label: 'Benefit Corporation (inc)',
          code: 'PNSZ',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'WTWK',
        },
        {
          label: 'Savings and Loan Association',
          code: 'YS05',
        },
      ],
    },
    {
      subdivision_label: 'South Dakota',
      subdivision_code: 'US-SD',
      legal_forms: [
        {
          label: 'Limited Partnership (LP)',
          code: '15JS',
        },
        {
          label: 'Commercial Bank',
          code: '4LZW',
        },
        {
          label: 'Non-deposit Trust Company',
          code: '6TGS',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'C276',
        },
        {
          label: 'Business Corporation (inc)',
          code: 'JZWN',
        },
        {
          label: 'Savings Bank',
          code: 'PXNI',
        },
        {
          label: 'Limited Liability Limited Partnership (LLLP)',
          code: 'UX5E',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'Z9CH',
        },
      ],
    },
    {
      subdivision_label: 'Tennessee',
      subdivision_code: 'US-TN',
      legal_forms: [
        {
          label: 'General Partnership',
          code: '7TJ1',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '8MBD',
        },
        {
          label: 'Savings and Loan Association',
          code: 'C8HJ',
        },
        {
          label: 'Savings Bank',
          code: 'DE0G',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'DQUB',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'I2XB',
        },
        {
          label: 'Commercial Bank',
          code: 'ND93',
        },
        {
          label: 'For-Profit Corporation (inc)',
          code: 'QJ9F',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'RD1T',
        },
      ],
    },
    {
      subdivision_label: 'Texas',
      subdivision_code: 'US-TX',
      legal_forms: [
        {
          label: 'Professional Corporation',
          code: '9AAS',
        },
        {
          label: 'For-Profit Corporation',
          code: 'C5K7',
        },
        {
          label: 'Limited Partnership',
          code: 'FE1L',
        },
        {
          label: 'Commercial Bank',
          code: 'HTV5',
        },
        {
          label: 'Professional Association',
          code: 'MXWB',
        },
        {
          label: 'Savings and Loan Association',
          code: 'N5OS',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'OGSS',
        },
        {
          label: 'Savings Bank',
          code: 'TCLA',
        },
        {
          label: 'Limited Liability Company',
          code: 'WYG5',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'YY5A',
        },
      ],
    },
    {
      subdivision_label: 'Utah',
      subdivision_code: 'US-UT',
      legal_forms: [
        {
          label: 'General Partnership',
          code: '1CZS',
        },
        {
          label: 'Profit Corporation',
          code: '2I4P',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: '3ZXC',
        },
        {
          label: 'Commercial Bank',
          code: '65B6',
        },
        {
          label: 'Savings Bank',
          code: '6OGF',
        },
        {
          label: 'Non-deposit Trust Company',
          code: '7AJT',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '7H0X',
        },
        {
          label: 'Business Trust',
          code: 'B12O',
        },
        {
          label: 'Benefit LLC (LLC)',
          code: 'GIN3',
        },
        {
          label: 'Professional Corporation (PC)',
          code: 'IDFN',
        },
        {
          label: 'Non-profit Corporation',
          code: 'NOBH',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'Q1N4',
        },
        {
          label: 'Industrial Bank',
          code: 'RC44',
        },
        {
          label: 'Benefit Corporation',
          code: 'T0XH',
        },
        {
          label: 'Low Profit LLC (l3c)',
          code: 'UEKV',
        },
        {
          label: 'Uniform Limited Cooperative Association',
          code: 'YQLO',
        },
      ],
    },
    {
      subdivision_label: 'Vermont',
      subdivision_code: 'US-VT',
      legal_forms: [
        {
          label: 'Limited Liability Partnership (LLP)',
          code: '8XN0',
        },
        {
          label: 'Savings Bank',
          code: 'AMV2',
        },
        {
          label: 'Savings and Loan Association',
          code: 'FXHR',
        },
        {
          label: 'Professional Corporation',
          code: 'GZMZ',
        },
        {
          label: 'Commercial Bank',
          code: 'JWTD',
        },
        {
          label: 'Professional Limited Liability Company (PLC)',
          code: 'K4MF',
        },
        {
          label: 'Cooperative (Corp)',
          code: 'LPEE',
        },
        {
          label: 'Profit Corporation',
          code: 'NYUD',
        },
        {
          label: 'Limited Liability Company (Ltd)',
          code: 'OE6T',
        },
        {
          label: 'Non-profit Corporation',
          code: 'PNZI',
        },
        {
          label: 'Low Profit LLC (L3C)',
          code: 'R8O9',
        },
        {
          label: 'Mutual Benefit Enterprise (MBE)',
          code: 'TCC0',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'UF6Y',
        },
      ],
    },
    {
      subdivision_label: 'Virginia',
      subdivision_code: 'US-VA',
      legal_forms: [
        {
          label: 'Partnership',
          code: '456O',
        },
        {
          label: 'Limited Liability Company',
          code: '4WN7',
        },
        {
          label: 'Registered Limited Liability Partnership',
          code: '8KA4',
        },
        {
          label: 'Business Trust',
          code: 'BOEX',
        },
        {
          label: 'Limited Partnership',
          code: 'DQMV',
        },
        {
          label: 'Stock Corporation',
          code: 'HOV4',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'OF3Q',
        },
        {
          label: 'Nonstock Corporation',
          code: 'PLMC',
        },
        {
          label: 'Commercial Bank',
          code: 'Q28E',
        },
        {
          label: 'Savings Bank',
          code: 'S4U2',
        },
        {
          label: 'Savings and Loan Association',
          code: 'VVJ3',
        },
      ],
    },
    {
      subdivision_label: 'Washington',
      subdivision_code: 'US-WA',
      legal_forms: [
        {
          label: 'Limited Liability Company (LLC)',
          code: 'F8DD',
        },
        {
          label: 'Savings Bank',
          code: 'FQLY',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'LBJ1',
        },
        {
          label: 'Profit Corporation (inc)',
          code: 'NHYA',
        },
        {
          label: 'Commercial Bank',
          code: 'PM3Y',
        },
        {
          label: 'Non-profit Corporation',
          code: 'RR8G',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'RRXD',
        },
        {
          label: 'Savings and Loan Association',
          code: 'VUUU',
        },
      ],
    },
    {
      subdivision_label: 'West Virginia',
      subdivision_code: 'US-WV',
      legal_forms: [
        {
          label: 'Savings Bank',
          code: '724Q',
        },
        {
          label: 'Voluntary Association',
          code: 'DURX',
        },
        {
          label: 'Corporation',
          code: 'IJHI',
        },
        {
          label: 'Commercial Bank',
          code: 'O1EZ',
        },
        {
          label: 'Business Trust',
          code: 'O9MN',
        },
        {
          label: 'Unincorporated Non-Profit Association',
          code: 'OJBU',
        },
        {
          label: 'Corporation',
          code: 'PXGA',
        },
        {
          label: 'Cooperative Association (Co-op)',
          code: 'QDZK',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'R27J',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'RC5L',
        },
        {
          label: 'Savings and Loan Association',
          code: 'SSN4',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'SUMS',
        },
      ],
    },
    {
      subdivision_label: 'Wisconsin',
      subdivision_code: 'US-WI',
      legal_forms: [
        {
          label: 'Business Corporation (inc)',
          code: '6S32',
        },
        {
          label: 'Non-deposit Trust Company',
          code: 'B5YE',
        },
        {
          label: 'Cooperative Association',
          code: 'C0CR',
        },
        {
          label: 'Corporation Nonstock Nonprofit',
          code: 'EVBW',
        },
        {
          label: 'Commercial Bank',
          code: 'JNYX',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'M5RM',
        },
        {
          label: 'Savings Bank',
          code: 'QSJ1',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'WNV6',
        },
        {
          label: 'Savings and Loan Association',
          code: 'Y7PW',
        },
        {
          label: 'Limited Liability Partnership (LLP)',
          code: 'Z92A',
        },
        {
          label: 'Common Law Trust',
          code: 'ZXZ7',
        },
      ],
    },
    {
      subdivision_label: 'Wyoming',
      subdivision_code: 'US-WY',
      legal_forms: [
        {
          label: 'Statutory Foundation (SF)',
          code: '1YA4',
        },
        {
          label: 'Registered Limited Liability Partnership (RLLP)',
          code: '71ZI',
        },
        {
          label: 'Profit Corporation',
          code: '9GXA',
        },
        {
          label: 'Savings Bank',
          code: 'HXKE',
        },
        {
          label: 'Limited Liability Company (LLC)',
          code: 'NB58',
        },
        {
          label: 'Nonprofit Corporation',
          code: 'PUJR',
        },
        {
          label: 'Limited Partnership (LP)',
          code: 'QR4Y',
        },
        {
          label: 'Statutory Trust',
          code: 'RDQZ',
        },
        {
          label: 'Commercial Bank',
          code: 'S6TP',
        },
      ],
    },
  ],
  VC: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Association',
          code: '10ST',
        },
        {
          label: 'Public company',
          code: '1PWF',
        },
        {
          label: 'International Limited Duration Company (LDC)',
          code: '3QSG',
        },
        {
          label: 'International Company limited by shares (Ltd)',
          code: '5EKE',
        },
        {
          label:
            'International Unlimited company not authorised to issue shares (Unltd)',
          code: 'ABII',
        },
        {
          label: 'Company (Corp)',
          code: 'FG2L',
        },
        {
          label: 'Partnership',
          code: 'I9K6',
        },
        {
          label:
            'International Cmpany limited by guarantee not authorised to issue shares (Corp)',
          code: 'LCVA',
        },
        {
          label: 'Sole proprietorship',
          code: 'QYZD',
        },
        {
          label:
            'International Company limited by guarantee authorised to issue shares (Corp)',
          code: 'REVF',
        },
        {
          label:
            'International Unlimited company authorised to issue shares (Unltd)',
          code: 'RY5B',
        },
        {
          label: 'Non-profit company (corp)',
          code: 'S1OK',
        },
        {
          label: 'Statutory company',
          code: 'U5AR',
        },
        {
          label: 'Co-operative society',
          code: 'XY6R',
        },
        {
          label: 'International Segregated cell company (SCC Ltd)',
          code: 'YN9Y',
        },
      ],
    },
  ],
  VG: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Company limited by shares (Corp)',
          code: '6EH6',
        },
        {
          label:
            'Company limited by guarantee that is not authorised to issue shares (Corp)',
          code: 'BST2',
        },
        {
          label: 'Restricted purposes company (SPV Ltd)',
          code: 'GLCI',
        },
        {
          label:
            'Unlimited company that is not authorised to issue shares (Unltd)',
          code: 'JS65',
        },
        {
          label:
            'Restricted purposes company segregated portfolio company (SPV SPC)',
          code: 'N28C',
        },
        {
          label: 'Unlimited company that is authorised to issue shares (Unltd)',
          code: 'Q62B',
        },
        {
          label: 'Segregated portfolio company (SPC)',
          code: 'YOP9',
        },
        {
          label:
            'Company limited by guarantee that is authorised to issue shares (Corp)',
          code: 'ZHED',
        },
      ],
    },
  ],
  VI: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Commercial Bank',
          code: '6QCE',
        },
        {
          label: 'Foreign Trade & Labor Corporation',
          code: 'A30N',
        },
        {
          label: 'Foreign Limited Partnership',
          code: 'AN8Z',
        },
        {
          label: 'Domestic Limited Liability Company',
          code: 'CHWX',
        },
        {
          label: 'Foreign Non-Profit Corporation',
          code: 'DMNZ',
        },
        {
          label: 'Domestic Limited Partnership',
          code: 'MGUM',
        },
        {
          label: 'Domestic Trade & Labor Corporation',
          code: 'MH3L',
        },
        {
          label: 'Domestic Profit Corporation',
          code: 'NNLM',
        },
        {
          label: 'Domestic Non-Profit Corporation',
          code: 'PKZ2',
        },
        {
          label: 'Foreign Limited Liability Partnership',
          code: 'Q367',
        },
        {
          label: 'Foreign Limited Liability Company',
          code: 'SOX5',
        },
        {
          label: 'Domestic Limited Liability Partnership',
          code: 'TA9Z',
        },
        {
          label: 'Foreign Profit Corporation',
          code: 'UZ9W',
        },
      ],
    },
  ],
  VU: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Overseas Company',
          code: 'A35I',
        },
        {
          label: 'Local Company',
          code: 'JTJE',
        },
        {
          label: 'Foundation',
          code: 'WMJ9',
        },
      ],
    },
  ],
  WS: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Charitable trust',
          code: '1RPE',
        },
        {
          label: 'Public limited company',
          code: '7WL9',
        },
        {
          label: 'Credit Union',
          code: 'BCM3',
        },
        {
          label: 'Private limited company',
          code: 'M6YY',
        },
        {
          label: 'Overseas company',
          code: 'QWFD',
        },
        {
          label: 'Incorporated society',
          code: 'SXY3',
        },
        {
          label: 'Co-operative society',
          code: 'TOMA',
        },
      ],
    },
  ],
  XK: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label:
            'Ndërmarrjet shoqërore nën administrimin e Agjencisë Kosovare të Privatizimit (NSH)',
          code: '3YUD',
        },
        {
          label: 'Zyra e Përfaqësisë së Shoqërisë së Huaj Tregtare në Kosovë',
          code: 'B56A',
        },
        {
          label: 'Kooperativa Bujqësore (KB)',
          code: 'CRX3',
        },
        {
          label: 'Shoqëri me Përgjegjësi të Kufizuar (SHPK)',
          code: 'D37H',
        },
        {
          label: 'Dega e Shoqërisë së Huaj Tregtare në Kosovë (Kompani e Huaj)',
          code: 'OZDY',
        },
        {
          label: 'Shoqëri aksionare (SHA)',
          code: 'Q22D',
        },
        {
          label: 'Biznes Individual (BI)',
          code: 'QZEN',
        },
        {
          label: 'Ortakëri e Përgjithshme (OP)',
          code: 'T2X0',
        },
        {
          label:
            'Ndërmarrjet publike sipas ligjit në fuqi për ndërmarrjet publike (NP)',
          code: 'W5H8',
        },
        {
          label: 'Ortakëri e Kufizuar (OK)',
          code: 'W7KV',
        },
      ],
    },
  ],
  ZA: [
    {
      subdivision_label: null,
      subdivision_code: null,
      legal_forms: [
        {
          label: 'Close Corporation (CC)',
          code: '4YUU',
        },
        {
          label: 'Co-operatives',
          code: 'CXWJ',
        },
        {
          label: 'Private Company (Pty Ltd)',
          code: 'GQVQ',
        },
        {
          label: 'Non-Profit Company (NPC)',
          code: 'J7L0',
        },
        {
          label: 'Co-operatives',
          code: 'MZT6',
        },
        {
          label: 'State Owned Company (SOC)',
          code: 'R155',
        },
        {
          label: 'External Company',
          code: 'R59V',
        },
        {
          label: 'Personal Liability Company (Inc)',
          code: 'U89P',
        },
        {
          label: 'Public Company (Ltd)',
          code: 'XE4Z',
        },
        {
          label: 'Co-operatives',
          code: 'ZE81',
        },
      ],
    },
  ],
};
